export const m = {
  home: '首页',
  blog: '博客',
  aboutUs: '关于我们',
  product: '优配产品',
  contactUs: '联系我们',
  button: '登录',
  nameNotEmpty: '姓名不能为空',
  emailNotEmpty: '电子邮件不能为空',
  errorEmailAddress: '请输入正确的邮箱地址',
  phoneNotEmpty: '手机号不能为空',
  umMarketPlace1: 'Marketplace',
  umMarketPlace2: '',
  umMarketPlace3: 'UM Marketplace',
  containerLeasing: '集装箱租赁服务',
  containerContent1: '优配作为货柜租赁的方案专家，我们不仅提供海量的集装箱资源,',
  containerContent2: ' 还将通过智能匹配技术快速找到最佳的货柜选项供您选择。',
  containerContent3: '登录我们的智能操作平台，您可以一键查询可供租用的货柜信息，并在几秒钟内发送租用请求。不仅如此，后续所有的货柜单据传送都可直接在线上完成。',
  containerContent4: ' 为您提供高效无缝式的租赁服务，省时省力省心！',
  mbContainerContent1: '优配作为货柜租赁的方案专家，我们不仅提供海量的集装箱资源,UM Marketplace还将通过智能匹配技术快速找到最佳的货柜选项供您选择。',
  mbContainerContent2: '登录我们的智能操作平台，您可以一键查询可供租用的货柜信息，并在几秒钟内发送租用请求。不仅如此，后续所有的货柜单据传送都可直接在线上完成。',
  mbContainerContent3: 'UM Marketplace为您提供高效无缝式的租赁服务，省时省力省心！',
  getDemo: '获取演示',
  shareContainer: '共享集装箱平台',
  newRequirment: '热门箱源',
  viewAll: '查看所有',
  nullData: '暂无数据',
  register: '注册',
  // 注册
  memberRegistration: '会员注册',
  leasingPass: '普通会员',
  vipLeasingPass: '优享会员',
  visitor: '游客预览',
  visitor_01: '可以搜索集装箱租赁信息；',
  visitor_02: '注册后浏览匹配结果；',
  visitor_03: '不可匹配交易。',
  free: '免费',
  leasingPass_01: '无手续费',
  leasingPass_02: '提供1个用户账号',
  leasingPass_03: '无集装箱匹配租赁限额',
  leasingPass_04: '马上赠送UMIVERSE大礼品盒',
  leasingPass_05: '享受用箱返点优惠',
  leasingPass_06: '用柜即享UMCC碳积分奖励',
  leasingPass_07: '有机会获得年度ESG大奖、成为UM减排大使',
  leasingPass_button: '268 USD/1888 RMB/月',
  free_button: '免费',
  leasingPass_tip: '可选择季付／半年付／年付',
  upgrade_member_title: '升级为优享会员',
  upgrade_member_text: '返利优先享，低碳优先行',
  upgrade_member_quarter_text: '季度会员·3个月',
  upgrade_member_halfQuarter_text: '半年度会员·6个月',
  upgrade_member_year_text: '年度会员·12个月',
  upgrade_member_unit: '约',
  upgrade_member_month: '月',
  upgrade_member_button: '立即升级',
  renew_member_button: '立即续费',
  upgrade_member_free_text: '免费会员',
  upgrade_member_premium_text: '优享会员',
  companyAndBilling: '公司信息',
  companyName: '公司名称',
  legalRepresentative: '法定代表人',
  companyAddress: '公司住址',
  companyBusinessLicense: '公司营业执照',
  imageUpload: '图片上传',
  uniformCreditCode: '统一信用代码 / 商业登记证书编号',
  companyBankAccount: '公司交易账户',
  bankNameOfAccount: '开户行',
  yourContactDetails: '使用者信息',
  userName: '用户姓名',
  position: '公司职位',
  IDNumber: '身份证号',
  IDCardFrontSide: '身份证正面（名片 / 授权书）',
  IDCardBackSide: '身份证反面（名片 / 授权书）',
  contactEmail: '联系邮箱',
  companyContactEmail: '联系邮箱',
  registeredMember: '注册会员',
  agreeColle: '本人同意收集以上信息',
  agreeprotocol: '阅读并同意《用户协议》《隐私政策》《免责声明》《优享会员入会协议》',
  termsOfUse: '《用户协议》',
  privacyPolicy: '《隐私政策》',
  disclaimer: '《免责声明》',
  companyNameNotNull: '公司名称不能为空',
  legalRepresentativeNotNull: '法定代表人不能为空',
  companyAddressNotNull: '公司住址不能为空',
  uniformCreditCodeNotNull: '统一信用代码 / 商业登记证书编号不能为空',
  companyBankAccountNotNull: '公司账户不能为空',
  bankNameOfAccountNotNull: '开户行不能为空',
  userNameNotNull: '用户姓名不能为空',
  positionNotNull: '公司职位不能为空',
  IDNumberNotNull: '身份证号不能为空',
  contactEmailNotNull: '联系邮箱不能为空',
  companyContactEmailNotNull: '联系邮箱不能为空',
  agreeColleFirst: '先勾选后再提交注册',
  registeredMemberSuccess: '会员申请提交成功',
  city: '所在城市',
  country: '所在国家',
  cityNotNull: '所在城市不能为空',
  countryNotNull: '所在国家不能为空',
  companyBusinessLicenseNotNull: '公司营业执照不能为空',
  IDCardFrontSideNotNull: '身份证正面（名片 / 授权书）不能为空',
  IDCardBackSideilNotNull: '身份证反面（名片 / 授权书）不能为空',
  result_1: '尊敬的用户:',
  result_2: '您好!UM已收到您的会员注册信息，COE团队将会发送线下支付事宜以及会员入会协议至您的邮箱，请注意查收。',
  result_3: '如有疑问,可发送问题至COE团队，',
  result_4: '联系方式：',
  result_5: '我们将尽快为您解答。',
  result_6: '谨上',
  result_7: 'UM COE 团队',
  result_8: '恭喜！您已成为Marketplace的会员，点击链接',
  result_9: '即可登录您的会员账号并享受会员服务。',
  result_10: '非常感谢您成为本平台的会员!',
  benefits_title: '巨额补贴 强势来袭',
  benefits_content_subject1: '每月特惠，租箱补贴至高可抵扣全额运费+返利',
  benefits_content1: '不一样的冷藏箱，不一样的运输体验。既能享受40GP同等装载空间，又能节省运费和减少碳排放。让你的货物运输更安全、更环保、更经济。',
  benefits_content_subject2: 'SOC通享全球',
  benefits_content2: '多种箱型任你选，欧洲、土耳其、美国等多个地区任你去，我们提供45HCPW到欧洲、土耳其；53HC到美国的独家报价，限时限量，登录',
  benefits_content3: '即可优先享受！',
  benefits_content4: '*实际用箱补贴根据不同目的港而不同。',
  goSearch:'更多详情请点击',
  mb_benefits_title:'巨额补贴 强势来袭',
  mb_benefits_content_subject1: '每月特惠，租箱补贴至高可抵扣全额运费+返利',
  mb_benefits_content1: '不一样的冷藏箱，不一样的运输体验。既能享受40GP同等装载空间，又能节省运费和减少碳排放。让你的货物运输更安全、更环保、更经济。',
  mb_benefits_content_subject2: 'SOC通享全球',
  mb_benefits_content2: '多种箱型任你选，欧洲、土耳其、美国等多个地区任你去，我们提供45HCPW到欧洲、土耳其；53HC到美国的独家报价，限时限量，登录',
  mb_benefits_content3: '即可优先享受！',
  mb_benefits_content4: '*实际用箱补贴根据不同目的港而不同。',
  buyContainers: '我要买箱',
  sellContainers: '我要卖箱',
  order: '一键下单',
  CarbonCreditStore: '碳积分商城',
  spot_title: '优选海运价格查询',
  capacity_unit: '立方',
  spot_price_label: '全包价',
  size_type_label: '箱型',
  pol_label: '起运港',
  pod_label: '目的港',
  spot_search: '查询',
  spot_order: '一键订舱',
  spot_desc1: '想了解更多详细信息?  ',
  spot_desc2: '联系优配专员',
  spot_prod_title: '产品介绍',
  spot_prod_content: '如想了解更详细的集装箱航线信息，点击“下载”即可获取产品手册',
  spot_download:'下载',
  tradingTitle: '一站式交易平台 物流信息可视化',
  freeRegister: '免费注册',
  register_premium: '优享注册',
  freeLogin: '登 录',
  trading_purchase_label: '买卖箱',
  trading_space_label: '舱位通',
  trading_lease_label: '租赁箱',
  trading_management_label: '管好箱',
  trading_purchase_info: '全球新箱、次新、二手箱资源 ',
  trading_space_info: '订购优质海运产品',
  trading_lease_info: '各种类型单程柜，全球流向',
  trading_management_info: '在CRM箱管系统进行集装箱管理 ',
  Lease_service:'Marketplace 平台',
  mb_footer_1: '©2020-2023 优配共享集装箱科技（珠海）有限公司',
  mb_footer_2: '网站备案号: 粤ICP备2023078801号-1',

  footer_title1:'首页',
  footer_title2:'关于我们',
  footer_title3:'优配产品',

  footer_list_info1: '热门箱源',
  footer_list_info2: 'Marketplace 平台',
  footer_list_info3: '集装箱租赁服务',
  footer_list_info4: '巨额补贴',

  footer_list_info5: '愿景与使命',
  footer_list_info6: '释放CO2减排潜能',
  footer_list_info7: '集装箱与货物无缝沟通与协作',
  footer_list_info8: '用户评价',

  copyright: '©2020-2023 优配共享集装箱科技（珠海）有限公司 | 网站备案号: 粤ICP备2023078801号-1',

  companyContactNameNotNull:'公司联系人姓名不能为空',
  companyContactPhoneNotNull:'公司联系人电话不能为空',
  companyContactEmailsNotNull:'公司联系人邮箱不能为空',
  emailVerificationCodeNotNull:'邮箱验证码不能为空',
  passwordNotNull:'登录密码不能为空',
  confirmedPassword :'请确认登录密码',

  tradingcancelResisterMember: '取 消',
  tradignRegisterMember: '注 册',



}
export const a = {
  abouttitle: '愿景与使命',
  missioncontent1: '优配，把原来不相见的集装箱和货物的数据，应用大数据分析及人工智能，于平台中实现可观、可视。',
  missioncontent2: '优化集装箱运输物流链，双向快捷匹配，提高流通效率，匹配物流路线，减少资源浪费，共同保护地球环境。',
  reduceco2title: '释放CO2减排潜能',
  reduceco2desc1_1: '1,284,798辆汽车',
  reduceco2desc2_1: '年排放量',
  reduceco2desc1_2: '600万吨/年',
  reduceco2desc2_2: '年排放量',
  reduceco2desc1_3: '647,880户家庭',
  reduceco2desc2_3: '年排放量',
  containergoodstitle: '集装箱与货物无缝沟通与协作',
  containergoodsdesccitle1: '货物匹配',
  containergoodsdesc1: '优配为货物拥有者(货主)提供最优质的物流对接，基于您的需求，配置最适合需求的集装箱。以45尺宽柜为例，装载能力比45尺高柜更具优势，优配将为您节省高达30%的成本。',
  containergoodsdesccitle2: '集装箱匹配',
  containergoodsdesc2: '优配团队为集装箱拥有者(箱东)匹配运往全球的货物资源，无论是单程集装箱,或是重定向集装箱,优配将是您最优的伙伴，实现节省物流成本的同时，并加速集装箱的运转周期。',
  containergoodsdesccitle3: '物联网追踪',
  containergoodsdesc3: '平台数据与物联网实时连接,无论您是集装箱拥有者,或是货物所有者,优配将实时追踪平台物流数据,为您掌控运往全球各地的货柜与货物，保障安全送达。',
  fromcustomerstitle: '用户评价',
  customersname1: '集装箱拥有者',
  customersarea1: '欧洲',
  customersmessageinfo1: '我们和优配合作前，单程空箱的物流运输一直是我们的困扰。中国的集装箱产量占全球总产量的90%以上，并且我们相信中国质量，所以我们为欧洲客户采购的集装箱集中在中国的大湾区，却不得不支付高昂的空箱运输费，我们认为这是一种浪费。优配的出现，解决了我们的一个大问题，为我们节省了超过50%的费用，包括物流，管理等传统开支，并且加速了我们配送集装箱的效率，极大地缩短了流转周期。他们的服务，等于为我们创造了新的利润。',
  customersname2: '出口制造商',
  customersarea2: '中国广东',
  customersmessageinfo2: '优配的出现，为我们提供了更好的物流运输服务的同时，也会根据我们企业的出口业务量需求，为我们调度容量更大的货柜，在单个货柜物流成本不变的前提下，可以为我们节省高达30%的整体运输成本。对于制造业来说，每一分钱的节约都是喜闻乐见的。',
  customersmessageinfo3: '除此以外，优配的平台化服务，也帮助我们传统企业体验的数据化转型所带来的前景，帮助我们优化了物流的流程，很好的提高了工作效率。'
}
export const h = {
  companyname: 'UNIT·MATCH',
  containersInfo: 'UNIT-MATCH提供了一个集成平台来支持数字集装箱航运业转型。实现集装箱运输数据的可视化并通过数字化转型实现行业的净零排放目标，UM的一站式集装箱匹配平台带来集装箱和货物使用先进技术将数据从信息仓中取出。',
  homenavtitle: '共享集装箱平台',
  signbutton: '优享会员租赁市场',
  pickuplocations: '出发港',
  dropofflocations: '到达港',
  containerType: '集装箱类型',
  quantity: '数量',
  searchbutton: '搜索',
  formtitle: '联系演示',
  name: '姓名',
  companyName: '公司名称',
  email: '电子邮件',
  phonenumber: '手机号',
  submitbutton: '提交',
  container: '集装箱',
  supplierofcontainers: '集装箱供应商',
  detailsbutton: '查看详细信息',
  updateDate: '更新时间:'
}
export const c = {
  contacttitle: '联系我们',
  addresstitle: '办公地址',
  addresstitle1: '总部',
  addresstinfo1: '中国广东省珠海市高新区唐家湾香山路88号2栋703室',
  addresstitle2: '研发中心',
  addresstinfo2: '中国广东省广州市黄埔区开创大道2819号办公楼722室',
  addresstitle3: '市场中心',
  addresstinfo3: '中国广东省深圳市福田区华富街道皇岗路5001号深业上城CEEC loft小镇LC403',
  addresstitle4: '商务中心',
  addresstinfo4: '新加坡兴业通道2号VISION EXCHANGE大厦16层21号房',
  name: '姓名',
  email: '邮箱',
  tel: '电话',
  message: '内容',
  button: '咨询'
}
export const t = {
  logout_tip_title: '提示',
  logout_tip_content: '确认退出吗？',
  logout_tip_op_cancel: '取消',
  logout_tip_op_confirm: '确认',

  settings_companyInfo:'公司信息',
  settings_password:'密码设置',
  settings_order:'订单管理',
  settings_delegation:'我的委托',
  settings_inquiry:'我的询价',
  settings_factoryOrder:'箱厂订单',
  settings_depot:'我的堆场',
  settings_demand:'我的需求',
  settings_logOut: '退出登录',

  mobile_settings_companyInfo:'公司信息',
  mobile_settings_password:'密码设置',
  mobile_settings_order:'订单管理',
  mobile_settings_delegation:'我的委托',
  mobile_settings_inquiry:'我的询价',
  mobile_settings_factoryOrder:'箱厂订单',
  mobile_settings_depot:'我的堆场',
  mobile_settings_demand:'我的需求',
  mobile_settings_member:'我的会员',

  register_title: '用户注册',
  register_companyName: '公司名称',
  register_companyContact: '公司联系人姓名',
  register_companyContactPhone: '公司联系人电话',
  register_companyContactCountryCode: '国家区号',
  register_companyContactEmail: '公司联系人邮箱',
  register_password: '设置登录密码',
  register_passwordConfirm: '确认登录密码',
  // register_passwordHint: '(登录账号为注册邮箱)',
  register_companyBusinessLicense: '公司营业执照',
  register_companyAddres: '公司网址',
  register_emailVerificationCode: '邮箱验证码',
  register_getEmailVerificationCode: '获取验证码',
  register_emailEmailVerificationCodeResend: '秒重发',
  register_resourceNotification: '您希望得到以下一个或多个板块最新资源推送',
  register_fromStaff: '推荐人员',
  register_resource_buying: '买卖箱',
  register_resource_lease: '租赁箱',
  register_resource_spot: '舱位通 ',
  register_imgUpload: '图片上传',
  register_confirmRegister: '注册',
  register_confirmCancel: '取消',
  register_currentEmail_registered: '当前邮件已注册',
  register_to_login: '去登录',
  register_consentCollectionInfo: '本人同意收集以上信息',
  register_consentRead: '同意并阅读',
  register_agreementFile: '《用户协议》',
  register_privacyFile: '《隐私政策》',
  register_disclaimerFile: '《免责声明》',
  register_vip_protocolFile: '《优享会员入会协议》',
  register_enterCompanyName: '请输入公司名称',
  register_enterCompanyContact: '请输入公司联系人姓名',
  register_enterCompanyContactPhone: '请输入公司联系人电话',
  register_enterCompanyContactEmail: '请输入公司联系人邮箱',
  register_enterPassword: '请输入登录密码',
  register_enterPasswordConfirm: '请再次输入登录密码',
  register_enterCompanyAddres: '请输入公司网址',
  register_enterEmailVerificationCode: '请输入邮箱验证码',
  register_enterFromStaff: '请选择推荐人员',
  register_companyNameNotNull: '公司名称不能为空',
  register_companyContactNotNull: '公司联系人姓名不能为空',
  register_companyContactPhoneNotNull: '公司联系人电话不能为空',
  register_companyContactEmailNotNull: '公司联系人邮箱不能为空',
  register_passwordNotNull: '登录密码不能为空',
  register_passwordLongVerification: '确保密码长度为8到16位',
  register_passwordFormatVerification: '您的密码需要数字和字母组合',
  register_passwordConfirmNotNull: '确认登录密码不能为空',
  register_emailVerificationCodeNotNull: '邮箱验证码不能为空',
  register_passwordVerification: '密码两次输入不一样，请重新输入',
  register_tradingResourceVerification: '请选择您希望得到的板块资源',
  register_registerSuccessInfo: '注册成功',
  register_registerSuccessToLogin: '返回登录页面',

  register_file_serviceAgreement: '用户注册和服务协议',
  register_file_legalNotice: '平台法律声明',
  register_file_privacyPolicy: '隐私政策',
  register_vip_protocol: '优享会员入会协议',

  register_file_readFile: '阅读并同意',

  email_VerificationEmailNotNull: '请先输入邮箱',
  email_VerificationEmailFormat: '请检查您的邮箱是否正确',


  login_title: '欢迎回来！',
  login_account: '账户',
  login_password: '密码',
  login_rememberPassword: '记住密码',
  login_forgetPassword: '忘记密码?',
  login_noAccount: '没有账号?',
  login_signIpNow: '马上注册',
  login_boutton: '登 录',
  login_enterLoginAccount: '请输入账户名',
  login_enterLoginPassword: '请输入密码',
  login_accountNotNull: '账户名不能为空',
  login_passwordNotNull: '密码不能为空',

  oneStopTrading_tradingTitle: '买卖箱',
  oneStopTrading_spoTitle: '舱位通',
  oneStopTrading_leasingTitle: '租赁箱',
  oneStopTrading_factoryPurchase: '箱厂新箱下单 ',
  oneStopTrading_DelegationSale: '委托平台销售',
  oneStopTrading_globalDepot: '全球堆场网络',
  oneStopTrading_ourDemand : '全球箱源需求',
  oneStopTrading_login: '登录',
  oneStopTrading_logOut: '退出',

  delegation_form_title: '委托平台销售',
  delegation_form_size: '尺寸',
  delegation_form_type: '箱型',
  delegation_form_condition: '箱况',
  delegation_form_year: '年份',
  delegation_form_qty: '库存',
  delegation_form_colorSys: '色系',
  delegation_form_others: '其他信息',
  delegation_form_pickUpLoc: '提柜地点',
  delegation_form_price: '价格($)',
  delegation_form_contaiernImg: '上传箱源图片',
  delegation_form_minPrice: '允许最低交易价格($)',
  delegation_form_LastPickUpDate: '可提柜日期',
  delegation_form_confirmAdd: '添加',
  delegation_form_confirm: '确认',


  delegation_form_enterSize: '请选择尺寸',
  delegation_form_enterType: '请选择箱型',
  delegation_form_enterCondition: '请输入箱况',
  delegation_form_enterYear: '请输入单一年份或年份区间',
  delegation_form_enterQty: '请输入库存',
  delegation_form_enterColorSys: '请输入颜色或色系',
  delegation_form_enterHasForklift: '是否有叉车槽',
  delegation_form_enterHasLockBox: '是否有锁盒',
  delegation_form_enterContaiernImg: '图片上传 ',
  delegation_form_enterPickUpLoc: '请输入提柜地点',
  delegation_form_enterPrice: '请输入价格',
  delegation_form_enterMinPrice: '请输入允许最低交易价格',
  delegation_form_enterLastPickUpDate: '请选择可提柜日期',

  delegation_title: '我的委托',
  delegation_title_no: '编号',
  delegation_title_type: '箱型',
  delegation_title_condition: '箱况',
  delegation_title_year: '年份',
  delegation_title_qty: '库存',
  delegation_title_price: '价格',
  delegation_title_minPrice: '允许最低价',
  delegation_title_pickUpLoc: '提柜地点',
  delegation_title_toDetail: '详细信息',

  delegation_delete_title: '提示',
  delegation_delete_info: '确认删除此委托信息吗？',
  delegation_delete_confirm: '确 认',
  delegation_delete_cancel : '取 消',

  delegation_noDate_title : '暂无委托',
  delegation_noDate_info : '您还没有添加任何委托',
  delegation_noDate_toContainer : '再去逛逛',

  delegation_detail_title : '委托详细信息',
  delegation_detail_no: '编号',
  delegation_detail_type: '箱型',
  delegation_detail_condition: '箱况',
  delegation_detail_year: '年份',
  delegation_detail_qty: '库存',
  delegation_detail_price: '价格',
  delegation_detail_minPrice: '允许最低价',
  delegation_detail_pickUpLoc: '提柜地点',
  delegation_detail_color: '色系',
  delegation_detail_forklift: '是否有叉车槽',
  delegation_detail_lockBox: '是否有锁盒',
  delegation_detail_validDate: '可提柜日期',
  delegation_detail_img: '箱源图片',
  delegation_detail_edit: '编辑',
  delegation_detail_delete: '删除',


  inquiry_subject: '我的询价',
  inquiry_title_consultNo: '编号',
  inquiry_title_sizeType: '箱型',
  inquiry_title_containerStatus: '箱况',
  inquiry_title_Qty: '数量',
  inquiry_title_LatestPickupDate: '预计最迟提柜日期',
  inquiry_title_pickupLoc: '提柜地点',

  inquiry_form_subject: '买卖箱询价',
  inquiry_form_size: '尺寸',
  inquiry_form_placeholder_size: '请选择尺寸',
  inquiry_form_type: '箱型',
  inquiry_form_placeholder_type: '请选择箱型',
  inquiry_form_condition: '箱况',
  inquiry_form_placeholder_condition: '请选择箱况',
  inquiry_form_qty: '数量',
  inquiry_form_placeholder_qty: '请输入数量',
  inquiry_form_pickupLoc: '提柜地点',
  inquiry_form_placeholder_pickupLoc: '请输入提柜地点',
  inquiry_form_LatestPickupDate: '预计最迟提柜日期',
  inquiry_form_placeholder_LatestPickupDate: '选择日期',
  inquiry_form_confirm: '确认',

  inquiry_delete_tip_subject: '提示',
  inquiry_delete_tip_msg: '确认删除此询价信息吗？',
  inquiry_delete_confirm: '确 认',
  inquiry_delete_cancel: '取 消',

  inquiry_empty_data: '暂无询价',
  inquiry_empty_msg1: '您还没有添加任何询价',
  inquiry_empty_msg2: '再去逛逛',


  trading_order_subject: '订单管理',
  trading_order_createDate: '下单日期',
  trading_order_orderNo: '编号',
  trading_order_resourceNo: '箱源编号',
  trading_order_containerCondition: '箱况',
  trading_order_total: '合计',
  trading_order_pickupLoc: '提柜地点',
  trading_order_reorder: '重新下单',
  trading_order_cancel: '取消订单',
  trading_order_unpaid: '未付款',
  trading_order_paid: '已付款',
  trading_order_cancel_send_subject: '邮件发送成功',
  trading_order_cancel_send_context: '感謝您的耐心等待，我们会尽快确认您取消的订单！',

  trading_order_downloadVoucher: '下载凭证',
  trading_order_viewVoucher: '查看凭证',

  trading_order_empty: '暂无订单',
  trading_order_empty_msg1: '您还没有添加任何订单',
  trading_order_empty_msg2: '再去逛逛',

  trading_cancel_order_subject: '提示',
  trading_cancel_order_msg: '确认取消此订单吗?',
  trading_cancel_order_cancel: '取 消',
  trading_cancel_order_confirm: '确 认',

  trading_order_upload_pay_subject: '汇款凭证截图上传',
  trading_order_upload_pay_label: '上传截图',
  trading_order_upload_pay_text: '图片上传',
  trading_order_upload_pay_cancel: '取 消',
  trading_order_upload_pay_confirm: '上 传',

  trading_order_detail_subject: '订单详情',
  trading_order_detail_orderDate: '下单时间',
  trading_order_detail_orderNo: '编号',
  trading_order_detail_sizeType: '箱型',
  trading_order_detail_condition: '箱况',
  trading_order_detail_year: '年份',
  trading_order_detail_colorSys: '色系',
  trading_order_detail_hasForklift: '是否有叉车槽',
  trading_order_detail_hasLockBox: '是否有锁盒',
  trading_order_detail_qty: '数量',
  trading_order_detail_price: '价格',
  trading_order_detail_pickupLoc: '提柜地点',
  trading_order_detail_pic: '箱源图片',
  trading_order_detail_total: '合计',



  resource_search_title: '现有箱源',
  resource_search_placeholder_size: '请选择尺寸',
  resource_search_placeholder_type: '请选择箱型',
  resource_search_placeholder_pickupLoc: '请输入提柜点',
  resource_search_button: '搜索现有箱源',
  resource_container_qty: '库存',
  resource_container_updateDate: '近三日更新',
  resource_container_pickUpLoc: '提柜地点',
  resource_container_validDate: '有效期',
  resource_container_purchase: '点击购买',
  resource_container_details: '详细信息',
  resource_container_noData: '暂无数据',
  resource_container_more: '没找到想要的箱源?',
  resource_container_inquiryMore: '询问更多箱源',
  resource_container_AgencySell: '委托平台销售',
  resource_container_factoryOrder: '箱厂新箱下单',
  resource_container_BrandNew: '新箱',
  resource_container_IICL: '次新箱',
  resource_container_CW: '二手箱',
  resource_container_ASIS: '现状箱',




  container_detail_title: '箱源详细信息',
  container_detail_no: '编号',
  container_detail_vaildDate: '有效日期',
  container_detail_sizeType: '箱型',
  container_detail_conditon: '箱况',
  container_detail_year: '年份',
  container_detail_qty: '库存',
  container_detail_pickLoc: '提柜地点',
  container_detail_color: '色系',
  container_detail_forklift: '是否有叉车槽',
  container_detail_lockBox: '是否有锁盒',
  container_detail_price: '价格',
  container_detail_img: '箱源图片',
  container_detail_purchase: '点击购买',

  container_purchase_title: '交易确认',
  container_purchase_no: '编号',
  container_purchase_resource_no: '箱源编号',
  container_purchase_condition: '箱况',
  container_purchase_pickUpLoc: '提柜地点',
  container_purchase_tatalAmount: '合计',
  container_purchase_price: '单价',
  container_purchase_confirmButyon: '确认成交',


  factory_form_directTitle:'直接排产',
  factory_form_collageTitle:'拼单排产',
  factory_form_size:'尺寸',
  factory_form_condition:'箱型',
  factory_form_qty:'数量',
  factory_form_color:'色系',
  factory_form_forklift:'是否有叉车槽',
  factory_form_lockBox:'是否有锁盒',
  factory_form_logoRequest:'是否要显示公司名称和Logo',
  factory_form_pickUpLoc:'提柜地点',
  factory_form_lastPickUpDate:'最迟提柜日期',
  factory_form_price:'目标价格($)',
  factory_form_other:'其他要求',
  factory_form_confrimBoutton:'确认',


  factory_form_enterSize:'请选择尺寸',
  factory_form_enterCondition:'请选择箱型',
  factory_form_enterQty:'请输入数量',
  factory_form_enterColor:'请输入颜色或色系',
  factory_form_enterForklift:'请选择是否有叉车槽',
  factory_form_enterLockBox:'请选择是否有锁盒',
  factory_form_enterlogoRequest:'请选择是否显示',
  factory_form_enterPickUpLoc:'请输入提柜地点',
  factory_form_enterLastPickUpDate:'请选择最迟提柜日期',
  factory_form_enterPrice:'请输入目标价格',
  factory_form_enterOther:'如有其他要求，请在此填写...',

  factory_order_title: '箱厂下单管理',
  factory_order_no: '编号',
  factory_order_type: '排产方式',
  factory_order_sizeType: '箱型',
  factory_order_qty: '数量',
  factory_order_price: '目标价格',
  factory_order_lastPickUpDate: '最迟提柜日期',
  factory_order_pickLoc: '提柜地点',
  factory_order_toDetail: '详细信息',
  factory_order_noOrderTitle: '暂无订单',
  factory_order_noOrderInfo: '您还没有订单信息',
  factory_order_toIndex: '再去逛逛',
  factory_order_deleteTitle: '确认删除此订单吗？',
  factory_order_deleteConfirm: '确认',
  factory_order_deleteCancel: '取消',
  factory_order_delete: '删除',
  factory_order_edit: '编辑',

  factory_orederDetail_title:'箱厂订单详细信息',

  form_validate_error_tip_size: '尺寸不能为空',
  form_validate_error_tip_type: '箱型不能为空',
  form_validate_error_tip_condition: '箱况不能为空',
  form_validate_error_tip_qty: '数量不能为空',
  form_validate_error_tip_pickupLoc: '提柜地点不能为空',
  form_validate_error_tip_colorNumber: '色系不能为空',
  form_validate_error_tip_latestPickupDate: '预计最迟提柜日期不能为空',
  form_validate_error_tip_validate: '有效日期不能为空',
  form_validate_error_tip_year: '年份不能为空',
  form_validate_error_tip_price: '价格不能为空',
  form_validate_error_tip_minPrice: '最低交易价格不能为空',

  profile_info_title: '公司信息',
  profile_info_companyName: '公司名称',
  profile_info_contacts: '公司联系人姓名',
  profile_info_telephone: '公司联系人电话',
  profile_info_email: '公司联系人邮箱',
  profile_info_addres: '公司网址',
  profile_info_resourcePromotion: '您希望得到以下一个或多个板块最新资源推送',
  profile_info_businessLicense: '公司营业执照',
  profile_info_edit: '编辑',
  profile_info_reUpload: '重新上传',
  profile_info_save: '保存',
  profile_info_cancel: '取消',

  profile_form_info_title: '修改公司信息',
  profile_form_info_email: '公司联系人邮箱',
  profile_form_info_emailVerificationCode: '邮箱验证码',
  profile_form_info_contactsName: '公司联系人姓名',
  profile_form_info_telephone: '公司联系人电话',
  profile_form_info_addres: '公司地址',
  profile_form_info_resourcePromotion: '您希望得到以下一个或多个板块的最新资源',
  profile_form_info_containerTrading: '买卖箱',
  profile_form_info_containerLeasing: '租赁箱',
  profile_form_info_spotSpace: '舱位通',

  profile_form_contactsNameNotNull: '公司联系人姓名不能为空',
  profile_form_telephoneNotNull: '公司联系人电话不能为空',
  profile_form_emailNotNull: '公司联系人邮箱不能为空',
  profile_form_emailVerificationCodeNotNull: '邮箱验证码不能为空',
  profile_form_businessLicenseNotNull: '上传文件不能为空',

  profile_form_enterEmail: '请输入公司联系人邮箱',
  profile_form_enterVerificationCode: '请输入邮箱验证码',
  profile_form_enterContactsName: '请输入公司联系人姓名',
  profile_form_enterTelephone: '请输入公司联系人电话',
  profile_form_enteraddres: '请输入公司地址',

  profile_form_uploadLicense_title: '上传公司营业执照',
  profile_form_uploadLicense_prompt: '上传图片',
  profile_form_uploadLicense_uploadImg: '图片上传',
  profile_form_uploadLicense_upload: '上 传',
  profile_form_uploadLicense_cancel: '取 消',

  profile_form_getEmailVerificationCode: '获取验证码',
  profile_form_emailEmailVerificationCodeResend: '秒重发',

  send_confirm_tip_inquiry_subject: '邮件发送成功',
  send_confirm_tip_inquiry_content: '感谢您的查询，最新价格我们将以邮件方式回复您',

  send_confirm_tip_entrust_subject: '邮件发送成功',
  send_confirm_tip_entrust_content: '感谢您的委托，我们的同事将通过邮件联系您',

  send_confirm_tip_factoryOrder_subject: '邮件发送成功',
  send_confirm_tip_factoryOrder_content: '感谢您的下单，我们的同事将通过邮件联系您',

  send_confirm_tip_order_subject: '邮件发送成功',
  send_confirm_tip_order_content: '感谢您的下单，我们的同事将通过邮件联系您',
  send_fail_tip_order: '下单失败',

  contact_IT: '操作失败，请联系 IT 人员，谢谢！',


  passManage_info_title: '设置密码',
  passManage_form_currentPassWord: '当前密码',
  passManage_form_newPassWord: '新密码',
  passManage_form_confirmPassWord: '确认新密码',

  passManage_form_enterCurrentPassWord: '请输入当前登录密码',
  passManage_form_enterNewPassWord: '请输入新密码',
  passManage_form_enterConfirmPassWord: '请再次输入新密码',

  passManage_form_confirmBoutton: '确 定',
  passManage_form_cancelBoutton: '取 消',

  passManage_form_currentPassWordNotNull: '当前密码不能为空',
  passManage_form_newPassWordNotNull: '新密码不能为空',
  passManage_form_confirmPassWordNotNull: '请再次确认新密码',
  passManage_form_confirmPassWordInconsistent: '两次输入不一样，请重新输入',

  customerService_wechat_text: '微信',
  customerService_whatsApp_text: 'WhatsApp',

  customerService_domesticSales: '国内销售联系',
  customerService_southeastAsianSales: '东南亚销售联系',
  customerService_europeAndAmericaSales: '欧美区域销售联系',


  passReset_info_title: '忘记密码',
  passReset_form_email: '邮箱',
  passReset_form_emailVerificationCode: '邮箱验证码',
  passReset_form_newPassword: '新密码',
  passReset_form_confirmNewPassword: '确认新密码',

  passReset_form_enterEmail: '请输入注册邮箱',
  passReset_form_enterVerificationCode: '请输入邮箱验证码',
  passReset_form_enterNewPassword: '请输入新密码',
  passReset_form_enterConfirmNewPassword: '请再次输入新密码',

  passReset_form_emailNotNull: '注册邮箱邮箱不能为空',
  passReset_form_emailVerificationCodeNotNull: '邮箱验证码不能为空',
  passReset_form_newPasswordNotNull: '新密码不能为空',
  passReset_form_confirmNewPasswordNotNull: '请再次确认新密码',
  passReset_form_confirmPassWordInconsistent: '两次输入不一样，请重新输入',

  passReset_form_confirmBoutton: '确 定',
  passReset_form_cancelBoutton: '取 消',

  passReset_form_getEmailVerificationCode: '获取验证码',
  passReset_form_emailEmailVerificationCodeResend: '秒重发',

  service_chat_title:'UMY在线客服 | ',
  mobile_service_chat_title:'UMY在线客服 | ',
  service_chat_item_title:'请选择您想要咨询的业务',
  service_chat_item_trading:'买卖箱',
  service_chat_item_spot:'舱位通',
  mobile_service_chat_item_spot:'舱位通',
  service_chat_item_leasing:'租赁箱',
  service_chat_enter:'请输入您的问题…',

  upgrade_member_title: '升级优享会员',
  mobile_upgrade_member_title: '我的会员',
  premium_member_renew: '续费',
  premium_member_title: '优享会员',
  premium_member_equity: '共6项优享会员权益',
  premium_member_validity: '有效期',
  overdue_member_text: '已过期',
  premium_member_surplus: '剩余天数',
  premium_member_day: '天',
  premium_member_equity_existing: '现有权益',
  premium_member_equity_handling: '无手续费',
  premium_member_equity_account: '提供1个用户账号',
  premium_member_equity_limit: '无集装箱匹配租赁限额',
  premium_member_equity_box: '马上赠送UMIVERSE大礼品盒',
  premium_member_equity_preferential: '享受用箱返点优惠',
  premium_member_equity_integral: '用柜即享UMCC碳积分奖励',
  premium_member_equity_ambassador: '有机会获得年度ESG大奖、成为UM减排大使',
  premium_member_start_leasing: '开始租箱',

  premium_member_renew_title: '续费优享会员',
  premium_member_upgrade_title: '升级优享会员',
  premium_member_renew_text: '您确定需要续费优享会员吗？',
  premium_member_upgrade_text: '您确定需要升级优享会员吗？',
  premium_member_confirm: '确认',
  premium_member_cancel: '取消',

  premium_member_success_text: '正在审核中，我们的同事稍后会与您联系',

  demand_title: '全球资源需求',
  demand_add_text: '发布需求',
  demand_resource_no: '编号',
  demand_resource_container: '箱型',
  demand_resource_condition: '箱况',
  demand_resource_quantity: '数量',
  demand_resource_pickUpDate: '提柜时间',
  demand_resource_pickUpLocation: '提柜地点',
  demand_resource_detail: '详细信息',

  demand_empty_title: '暂无数据',
  demand_empty_text: '没找到想要的资源需求?',
  demand_my_empty_text: '没找到想要的资源需求? 您可以',
  demand_empty_return: '再去逛逛',
  demand_my_empty_return: '发布需求',
  demand_yes_text: '是',
  demand_no_text: '否',
  demand_noSpecified_text: '不指定',


  demand_add_resource_title: '需求录入',
  demand_add_resource_size: '尺寸',
  demand_add_resource_type: '箱型',
  demand_add_resource_quantity: '数量',
  demand_add_resource_condition: '箱况',
  demand_add_resource_forkliftGroove: '是否有叉车槽',
  demand_add_resource_lockBox: '是否有锁盒',
  demand_add_resource_lastPickUpDate: '最迟提柜日期',
  demand_add_resource_pickUpLocation: '提柜地点',
  demand_add_resource_remark: '其他要求',
  demand_add_resource_confirm: '发 布',
  demand_add_resource_cancel: '取 消',
  demand_add_resource_success: '发布成功',

  demand_resource_select_yes: '是',
  demand_resource_select_no: '否',
  demand_resource_select_noSpecified: '不指定',

  demand_form_validate_error_tip_size: '尺寸不能为空',
  demand_form_validate_error_tip_type: '箱型不能为空',
  demand_form_validate_error_tip_quantity: '数量不能为空',
  demand_form_validate_error_tip_condition: '箱况不能为空',
  demand_form_validate_error_lastPickUpDate: '最迟提柜日期不能为空',
  demand_form_validate_error_tip_pickUpLocation: '提柜地点不能为空',

  demand_add_resource_enter_size: '尺寸不能为空',
  demand_add_resource_enter_type: '请选择箱型',
  demand_add_resource_enter_quantity: '请输入数量',
  demand_add_resource_enter_condition: '请选择箱况',
  demand_add_resource_enter_lastPickUpDate: '请选择最迟提柜日期',
  demand_add_resource_enter_ickUpLocation: '请输入提柜地点',
  demand_add_resource_enter_remark: '请输入您的需求',

  demand_detail_resource_title: '资源需求详细信息',
  demand_detail_resource_no: '编号',
  demand_detail_resource_type: '箱型',
  demand_detail_resource_quantity: '数量',
  demand_detail_resource_condition: '箱况',
  demand_detail_resource_forkliftGroove: '是否有叉车槽',
  demand_detail_resource_lockBox: '是否有锁盒',
  demand_detail_resource_lastPickUpDate: '提柜时间',
  demand_detail_resource_pickUpLocation: '提柜地点',
  demand_detail_resource_remark: '其他要求',
  demand_detail_resource_provide: '我能提供资源',

  demand_detail_resource_provide_title: '邮件已发送成功',
  demand_detail_resource_provide_text: '感谢您，我们的同事将通过邮件联系您',

  demand_info_title: '我的需求',
  demand_cancel_resource_success: '撤销成功',

  demand_info_cancel_content: '确认撤销此需求吗？',
  demand_info_cancel_confirm: '确认',
  demand_info_cancel: '取消',
  demand_info_revoke_text: '撤销',
  demand_info_status_cancel: '已撤销',
  demand_info_status_complete: '已完成',

}
export const s = {

  enter_spotSpace_search_departure: '请输入起运地',
  enter_spotSpace_search_route: '请选择航线',
  enter_spotSpace_search_destination: '请输入目的地',
  enter_spotSpace_search_button: '搜索现有舱位',
  spotSpace_search_prompt: '没找到想要的舱位?',
  spotSpace_search_toInquiry: '我要询价',

  spotSpace_resource_noOrderTitle: '暂无数据',
  spotSpace_resource_noOrderInfo: '没找到想要的舱位?',
  spotSpace_resource_toIndex: '询问更多资源',

  spotSpace_inquiry_form_title :'舱位通询价',
  spotSpace_inquiry_form_type :'舱位类型',
  spotSpace_inquiry_form_dangerous :'危险品',
  spotSpace_inquiry_form_departure :'起运地',
  spotSpace_inquiry_form_destination :'目的地',
  spotSpace_inquiry_form_container :'柜型',
  spotSpace_inquiry_form_quantity :'数量',
  spotSpace_inquiry_form_size :'尺寸',
  spotSpace_inquiry_form_containerType :'箱型',
  spotSpace_inquiry_form_shippingTerms :'运输条款',
  spotSpace_inquiry_form_cargoReadyDate :'货好时间',
  spotSpace_inquiry_form_commodity :'货物名称',
  spotSpace_inquiry_form_cargoNetWeight :'货物重量(KG)',
  spotSpace_inquiry_form_cargoVolume :'货物体积(m³)',
  spotSpace_inquiry_form_whetherToAcceptTransfer :'是否接受中转',
  spotSpace_inquiry_form_confirm :' 查 询',
  spotSpace_send_confirm_tip_inquiry_subject : '邮件发送成功',
  spotSpace_send_confirm_tip_inquiry_content : '感谢您的查询，最新价格我们将以邮件方式回复您',
  spotSpace_inquiry_form_edit: '修改',

  enter_inquiry_form_departure : '请输入起运地',
  enter_inquiry_form_destination : '请输入目的地',
  enter_inquiry_form_container : '请输入柜型',
  enter_inquiry_form_quantity : '请输入数量',
  enter_inquiry_form_size : '请选择尺寸',
  enter_inquiry_form_type : '请选择箱型',
  enter_inquiry_form_cargoReadyDate : '请选择货好时间',
  enter_inquiry_form_commodity: '请输入货物名称',
  enter_inquiry_form_cargoNetWeight: '请输入货物重量',
  enter_inquiry_form_cargoVolume: '请输入货物体积',

  spotSpace_inquiry_form_departureVerification: '起运地不能为空',
  spotSpace_inquiry_form_destinationVerification: '起运地不能为空',
  spotSpace_inquiry_form_containerVerification: '柜型不能为空',
  spotSpace_inquiry_form_quantityVerification: '数量不能为空',
  spotSpace_inquiry_form_shippingTermsVerification: '请选择运输条款',
  spotSpace_inquiry_form_cargoReadyDateVerification: '货好时间不能为空',
  spotSpace_inquiry_form_commodityVerification: '货物名称不能为空',
  spotSpace_inquiry_form_cargoNetWeightVerification: '货物重量不能为空',
  spotSpace_inquiry_form_cargoVolumeVerification: '货物体积不能为空',
  spotSpace_inquiry_form_acceptTransferVerification: '请选择是否接受中转',
  spotSpace_inquiry_form_containerTypeVerification: '请最少添加一个箱型',
  spotSpace_inquiry_form_containerSizeVerification: '请确认每个尺寸都选择了对应的箱型',

  spotSpace_full_container_load: '海运整柜',
  spotSpace_resource_exp: '有效期',
  spotSpace_resource_departure: '起运地',
  spotSpace_resource_destination: '目的地',
  spotSpace_resource_transitTime: '海运航程',
  spotSpace_resource_sailingDate: '开航日',
  spotSpace_resource_price: '运费',
  spotSpace_resource_buy: '点击订舱',
  spotSpace_resource_viewDetails: '详细信息',
  spotSpace_resource_transitType_transit: '中转',
  spotSpace_resource_transitType_direct: '直航',

  spotSpace_resource_type_coc: '船司集装箱+船司舱位',
  spotSpace_resource_type_um_coc: '优配集装箱+船司舱位',
  spotSpace_resource_type_soc: '仅提供船司舱位',
  spotSpace_resource_type_empty: '空箱运输',
  spotSpace_resource_type_charter: '优配散杂货船舱位+优配集装箱',

  spotSpace_resource_detail_title: '舱位详情',
  spotSpace_resource_detail_resourceNo: '编号',
  spotSpace_resource_detail_exp: '有效期',
  spotSpace_resource_detail_transportationMode: '运输方式',
  spotSpace_resource_detail_shippingTerms: '运输条款',
  spotSpace_resource_detail_departure: '起运地',
  spotSpace_resource_detail_destination: '目的地',
  spotSpace_resource_detail_shippingLine: '航线',
  spotSpace_resource_detail_vessel: '航线代码/船名航次',
  spotSpace_resource_detail_carrier: '船公司',
  spotSpace_resource_detail_sailingDate: '开航日',
  spotSpace_resource_detail_transitTime: '海运航程',
  spotSpace_resource_detail_shippingSpace: '舱位供应量',
  spotSpace_resource_detail_closingDate: '截关时间',
  spotSpace_resource_detail_transit: '是否中转',
  spotSpace_resource_detail_transitPort: '中转港',
  spotSpace_resource_detail_pod: '卸货港',
  spotSpace_resource_detail_destinationFreeDays: '目的港免柜期(天)',
  spotSpace_resource_detail_remark: '备注(运输公司其他要求)',
  mb_spotSpace_resource_detail_remark1: '备注',
  mb_spotSpace_resource_detail_remark2: '(运输公司其他要求)',
  spotSpace_resource_detail_price: '运费',
  spotSpace_resource_detail_additionalCharges: '附加费',
  spotSpace_resource_detail_purchaseButton: '点击订舱',


  spotSpace_inquiry_order_title: '我的询价',
  spotSpace_inquiry_order_number: '编号',
  spotSpace_inquiry_order_type: '舱位类型',
  spotSpace_inquiry_order_departure: '起运地',
  spotSpace_inquiry_order_destination: '目的地',
  spotSpace_inquiry_order_container: '箱型/数量',
  spotSpace_inquiry_order_quantity: '总数量',
  spotSpace_inquiry_order_details: '详细信息',
  spotSpace_inquiry_order_delete_tip_subject: '提示',
  spotSpace_inquiry_order_delete_tip_content: '确认删除此询价信息吗？',
  spotSpace_inquiry_order_delete_tip_confirm: '确认',
  spotSpace_inquiry_order_delete_tip_cancel: '取消',

  spotSpace_inquiry_order_noOrderTitle: '暂无询价',
  spotSpace_inquiry_order_noOrderInfo: '您还没有添加任何询价',
  spotSpace_inquiry_order_toIndex:'再去逛逛',

  spotSpace_inquiry_detail_title: '询价详细信息',
  spotSpace_inquiry_detail_shippingTerms: '运输条款',
  spotSpace_inquiry_detail_departure: '起运地',
  spotSpace_inquiry_detail_destination: '目的地',
  spotSpace_inquiry_detail_type: '舱位类型',
  spotSpace_inquiry_detail_container: '柜型',
  spotSpace_inquiry_detail_quantity: '数量',
  spotSpace_inquiry_detail_cargoReadyDate: '货好时间',
  spotSpace_inquiry_detail_commodity: '货物名称',
  spotSpace_inquiry_detail_cargoNetWeight: '货物重量 (KG)',
  spotSpace_inquiry_detail_cargoVolume: '货物体积 (m³)',
  spotSpace_inquiry_detail_dangerous: '是否危险品',
  spotSpace_inquiry_detail_yes: '是',
  spotSpace_inquiry_detail_no: '否',
  spotSpace_inquiry_detail_whetherToAcceptTransfer: '是否接受中转',
  spotSpace_inquiry_detail_containerTitle: '箱型/数量',
  spotSpace_inquiry_detail_deleteButton: '删除',
  spotSpace_inquiry_detail_editButton: '编辑',


  spotSpace_purchase_detail_title: '交易确认',
  spotSpace_purchase_detail_resourceNumber: '编号',
  spotSpace_purchase_detail_exp: '有效期',
  spotSpace_purchase_detail_transportationMode: '运输方式',
  spotSpace_purchase_detail_shippingTerms: '运输条款',
  spotSpace_purchase_detail_departure: '起运地',
  spotSpace_purchase_detail_destination: '目的地',
  spotSpace_purchase_detail_shippingLine: '航线',
  spotSpace_purchase_detail_vessel: '航线代码/船名航次',
  spotSpace_purchase_detail_carrier: '船公司',
  spotSpace_purchase_detail_sailingDate: '开航日',
  spotSpace_purchase_detail_transitTime: '海运航程',
  spotSpace_purchase_detail_shippingSpace: '舱位供应量',
  spotSpace_purchase_detail_closingDate: '截关时间',
  spotSpace_purchase_detail_transit: '是否中转',
  spotSpace_purchase_detail_transitPort: '中转港',
  spotSpace_purchase_detail_pod: '卸货港',
  spotSpace_purchase_detail_destinationFreeDays: '目的港免柜期(天)',
  spotSpace_purchase_detail_additional: '附加费',
  spotSpace_purchase_detail_doc: 'DOC',
  spotSpace_purchase_detail_thc: 'THC',
  spotSpace_purchase_detail_remark: '备注(运输公司其他要求)',
  spotSpace_purchase_detail_hazardous: '危险货物',
  spotSpace_purchase_detail_container: '购买箱型和数量',
  spotSpace_purchase_detail_expenseDetail: '费用明细',
  spotSpace_purchase_detail_price: '运费',
  spotSpace_purchase_detail_items: '件',
  spotSpace_purchase_detail_total: '合计',
  spotSpace_purchase_detail_confirm: '确认购买',
  spotSpace_purchase_detail_hazardousYes: '是',
  spotSpace_purchase_detail_hazardousNo: '否',

  spotSpace_send_confirm_tip_order_subject: '邮件发送成功',
  spotSpace_send_confirm_tip_order_content: '感谢您的确认，我们的同事将通过邮件联系您',
  spotSpace_send_fail_tip_order: '下单失败',

  spotSpace_purchase_detail_quantityRemind:'请确认您需要购买的数量',
  spotSpace_purchase_detail_containerRemind:'请先选择要购买的箱型和数量',

  spotSpace_purchase_detail_dangerous_tip_info:'危险品目前暂不支持下单，您可以去询价',
  spotSpace_purchase_detail_dangerous_tip_inquiry:'我要询价',

  spotSpace_purchase_detail_tip_confirm_order_title:'确认订单信息',
  spotSpace_purchase_detail_tip_confirm_order_button:'确认',
  spotSpace_purchase_detail_tip_cancel_order_title:'取消',

  spotSpace_order_title: '订单管理',
  spotSpace_order_orderDate: '下单日期',
  spotSpace_order_number: '订单编号',
  spotSpace_spot_resource_no: '资源编号',
  spotSpace_order_departure: '起运地',
  spotSpace_order_destination: '目的地',
  spotSpace_order_container: '箱型/数量',
  spotSpace_order_price: '运费',
  spotSpace_order_quantity: '总数',
  spotSpace_order_total: '合计',
  spotSpace_order_includesSurcharge: '(含附加费)',
  spotSpace_order_operate: '操作',
  spotSpace_order_orderFileNotNull: '上传文件不能为空',

  spotSpace_order_noOrderTitle: '暂无订单',
  spotSpace_order_noOrderInfo: '您还没有添加任何订单',
  spotSpace_order_toIndex: '再去逛逛',

  spotSpace_order_detail_title: '订单详情',
  spotSpace_order_detail_OrderDate: '下单日期',
  spotSpace_order_detail_number: '编号',
  spotSpace_order_detail_transportationMode: '运输方式',
  spotSpace_order_detail_shippingTerms: '运输条款',
  spotSpace_order_detail_departure: '起运地',
  spotSpace_order_detail_destination: '目的地',
  spotSpace_order_detail_shippingLine: '航线',
  spotSpace_order_detail_vessel: '航线代码/船名航次',
  spotSpace_order_detail_carrier: '船公司',
  spotSpace_order_detail_sailingDate: '开航日',
  spotSpace_order_detail_transitTime: '海运航程',
  spotSpace_order_detail_shippingSpace: '舱位供应量',
  spotSpace_order_detail_closingDate: '截关时间',
  spotSpace_order_detail_transit: '是否中转',
  spotSpace_order_detail_transitPort: '中转港',
  spotSpace_order_detail_pod: '卸货港',
  spotSpace_order_detail_destinationFreeDays: '目的港免柜期(天)',
  spotSpace_order_cancel_send_subject: '邮件发送成功',
  spotSpace_order_cancel_send_context: '感謝您的耐心等待，我们会尽快确认您取消的订单！',


  spotSpace_order_detail_additionalCharges: '附加费',
  spotSpace_order_detail_doc: 'DOC',
  spotSpace_order_detail_thc: 'THC',
  spotSpace_order_detail_remark: '备注(运输公司其他要求)',
  spotSpace_order_detail_hazardousCargo: '危险货物',
  spotSpace_order_detail_isHazardousCargo: '是',
  spotSpace_order_detail_NotHazardousCargo: '否',
  spotSpace_order_detail_container: '购买箱型和数量',
  spotSpace_order_detail_expenseDetail: '费用明细',
  spotSpace_order_detail_price: '运费',
  spotSpace_order_detail_items: '件',
  spotSpace_order_detail_total: '合计',

  spotSpace_order_detail_alreadyPaid: '已付款',
  spotSpace_order_detail_unpaid: '未付款',
  spotSpace_order_detail_cancelOrder: '取消订单',
  spotSpace_order_detail_reorder: '重新下单',

  spotSpace_order_detail_downloadVoucher:'下载凭证',
  spotSpace_order_detail_viewVoucher:'查看凭证',

  spotSpace_cancel_order_subject: '提示',
  spotSpace_cancel_order_msg: '确认取消此订单吗?',
  spotSpace_cancel_order_cancel: '取消',
  spotSpace_cancel_order_confirm : '确认',

  spotSpace_order_upload_pay_subject: '汇款凭证截图上传',
  spotSpace_order_upload_pay_label: '上传截图',
  spotSpace_order_upload_pay_text: '图片上传',
  spotSpace_order_upload_pay_cancel: '取消',
  spotSpace_order_upload_pay_confirm: '上传',



}
export const l= {
  leasing_search_enter_size: '请选择尺寸',
  leasing_search_enter_type: '请选择箱型',
  leasing_search_enter_pickUpLocation: '请选择提柜点',
  leasing_search_enter_dropOffLocation: '请选择还柜点',
  leasing_search_button: '搜索现有箱源',

  leasing_search_empty_text: '没找到想要的箱源?',
  leasing_search_inquiry_button: '询问更多箱源',
  leasing_mobile_search_empty_text: '没找到想要的箱源?',
  leasing_mobile_search_inquiry_button: '询问更多箱源',

  leasing_resource_info_pickUpLocation: '提柜点',
  leasing_resource_info_dropOffLocation: '还柜点',
  leasing_resource_info_containerType: '箱型',
  leasing_resource_info_leaseType: '租赁类型',
  leasing_resource_info_puc: '租柜费/Unit(USD)',
  leasing_resource_info_credit: '补贴/Unit(USD)',
  leasing_mobile_resource_info_puc: '租柜费',
  leasing_mobile_resource_info_credit: '补贴',
  leasing_resource_info_freeDays:'免租期',
  leasing_resource_info_day:'日',
  leasing_resource_info_perDiem:'超期收费(USD/Unit/Day)',
  leasing_mobile_resource_info_perDiem:'超期收费',
  leasing_resource_info_quantity:'数量',
  leasing_resource_sendRequest_button:'点击租赁',
  leasing_resource_viewDetail_button:'详细信息',

  leasing_resource_no_data_title: '暂无数据',
  leasing_resource_no_data_text: '没找到想要的箱源?',
  leasing_resource_no_data_more: '询问更多资源',

  leasing_detail_header_title: '详细信息',
  leasing_detail_info_number: '编号',
  leasing_detail_info_pickUpLocation: '提柜点',
  leasing_detail_info_dropOffLocation: '还柜点',
  leasing_detail_info_container: '箱型',
  leasing_detail_info_quantity: '数量',
  leasing_detail_info_leaseType: '租赁类型',
  leasing_detail_info_condition: '箱况',
  leasing_detail_info_perDiem:'超期收费(USD/Unit/Day)',
  leasing_detail_info_puc: '租柜费/Unit (USD)',
  leasing_detail_info_credit: '补贴/Unit (USD)',
  leasing_detail_info_freeDays:'免租期',
  leasing_detail_info_dpp: 'DPP(USD/Unit)',
  leasing_detail_info_replacementValue: '重置价值(USD/Unit)',
  leasing_detail_info_validity: '有效天数',
  leasing_detail_info_created: '创建日期',

  leasing_detail_info_sendRequest_button:'点击租赁',
  leasing_detail_info_upgrade_button:'升级优享会员',

  leasing_inquiry_info_title: '租赁箱询价',
  leasing_inquiry_info_pickUpLocation: '提柜点',
  leasing_inquiry_info_dropOffLocation: '还柜点',
  leasing_inquiry_info_size: '尺寸',
  leasing_inquiry_info_type: '箱型',
  leasing_inquiry_info_qty: '数量',
  leasing_inquiry_info_condition: '箱况',
  leasing_inquiry_info_leaseType: '租赁类型',
  leasing_inquiry_info_LastPickupDate: '预计最迟提柜日期',

  leasing_inquiry_enter_pickUpLocation: '请输入提柜点',
  leasing_inquiry_enter_dropOffLocation: '请输入还柜点',
  leasing_inquiry_enter_size: '请选择尺寸',
  leasing_inquiry_enter_type: '请选择箱型',
  leasing_inquiry_enter_qty: '请输入数量',
  leasing_inquiry_enter_condition: '请选择箱况',
  leasing_inquiry_enter_leaseType: '请选择租赁类型',
  leasing_inquiry_enter_LastPickupDate: '请选择最迟提柜日期',

  leasing_form_validate_error_pickUpLocation: '提柜点不能为空',
  leasing_form_validate_error_dropOffLocation: '还柜点不能为空',
  leasing_form_validate_error_size: '尺寸不能为空',
  leasing_form_validate_error_type: '箱型不能为空',
  leasing_form_validate_error_qty: '数量不能为空',
  leasing_form_validate_error_condition: '箱况不能为空',
  leasing_form_validate_error_leaseType: '租赁类型不能为空',
  leasing_form_validate_error_lastPickupDate: '最迟提柜日期不能为空',

  leasing_inquiry_info_sendRequest_button:'查 询',
  leasing_inquiry_send_confirm_tip_subject: '价格查询邮件已发送成功',
  leasing_inquiry_send_confirm_tip_content: '感谢您的查询，我们的同事将通过邮件联系您',
  leasing_mobile_inquiry_send_confirm_tip_subject: '邮件已发送成功',
  leasing_mobile_inquiry_send_confirm_tip_content: '感谢您，我们的同事将通过邮件联系您',

  leasing_upgrade_send_confirm_tip_subject: '我们已收到您的升级会员申请',
  leasing_upgrade_send_confirm_tip_content: '感谢您,我们的同事将通过邮件联系您',


  leasing_order_title: '我的询价',
  leasing_order_info_number: '编号',
  leasing_order_info_pickUpLocation: '提柜点',
  leasing_order_info_dropOffLocation: '还柜点',
  leasing_order_info_container: '箱型',
  leasing_order_info_quantity: '数量',
  leasing_order_info_detail: '详细信息',

  leasing_order_empty_title: '暂无询价',
  leasing_order_empty_text: '您还没有添加任何询价',
  leasing_order_empty_return: '再去逛逛',

  leasing_inquiry_order_delete_subject: '提示',
  leasing_inquiry_order_delete_content: '确认删除此询价信息吗？',
  leasing_inquiry_order_delete_confirm: '确认',
  leasing_inquiry_order_delete_cancel: '取消',

  leasing_inquiry_detail_title: '询价详细信息',
  leasing_inquiry_detail_info_number: '编号',
  leasing_inquiry_detail_info_pickUpLocation: '提柜点',
  leasing_inquiry_detail_info_dropOffLocation: '还柜点',
  leasing_inquiry_detail_info_container: '箱型/数量',
  leasing_inquiry_detail_info_leaseType: '租赁类型',
  leasing_inquiry_detail_info_condition: '箱况',
  leasing_inquiry_detail_info_lastPickupDate: '预计最迟提柜日期',
  leasing_inquiry_detail_info_edit_button: '编辑',
  leasing_inquiry_detail_info_delete_button: '删除',

  page_loading_completed_prompt: '我是有底线的',
  pull_down_refresh_prompt: '下拉刷新',

  service_chat_umy_notice: 'Hi, 我是UMY, 需要帮忙吗？',
  service_chat_send_img_prompt_title: '提示',
  service_chat_send_img_prompt_content: '是否确认发送这张图片',
  service_chat_send_file_prompt_content: '是否确认发送这个文件',
  service_chat_send_confirm_button: '确认',
  service_chat_send_cancel_button: '取消',
  service_chat_file_download: '下载',
}

export const d = {
  depot_info_title: '全球堆场网络',
  depot_search_country: '国家',
  depot_search_city: '城市',
  depot_search_button: '搜索堆场',

  depot_free_join_title: '堆场免费入驻',
  depot_free_join_button: '我要入驻',
  depot_upgrade_title: '升级优选堆场',
  depot_upgrade_button: '我要升级',

  depot_title_domesticDepot: '国内堆场',
  depot_title_overseasDepot: '国外堆场',
  depot_info_businessHours: '营业时间',
  depot_info_hours: '小时',
  depot_info_stockpilingService: '可堆存',
  depot_info_repairService: '可修洗',
  depot_mobile_info_stockpilingService: '可堆存',
  depot_mobile_info_repairService: '可修洗',
  depot_info_fromPortDistance: '距离港口或场站',
  depot_info_depotArea: '堆场面积（M²）',
  depot_info_depotCapacity: '堆场容量（TEU）',
  depot_info_throughput: '吞吐能力（柜/月）',
  depot_info_detail_button: '查看价格',
  depot_info_consult_button: '点击咨询',

  depot_info_price_title: '收费标准',
  depot_info_feet : '尺',
  depot_info_emptyContainer : '空柜',
  depot_info_loadedContainer : '重柜',
  depot_info_price_freeStoragePeriod: '免堆期（天）',
  depot_info_price_storageFee: '堆存费（柜/天）',
  depot_info_price_inLiftingFee: '进场吊箱费（元/柜/次）',
  depot_info_price_outLiftingFee: '出场吊箱费（元/柜/次）',

  depot_consult_form_title: '我要咨询',
  depot_consult_form_companyName: '公司名称',
  depot_consult_form_contactNumber: '联系电话',
  depot_consult_form_contactEmail: '联系邮箱',
  depot_consult_form_otherRequirements: '备注',
  depot_consult_form_button: '发送咨询邮件',

  depot_consult_form_enter_companyName: '请输入公司名称',
  depot_consult_form_enter_contactNumber: '请输入联系电话',
  depot_consult_form_enter_contactEmail: '请输入联系邮箱',
  depot_consult_form_enter_otherRequirements: '请填写希望告知我们的信息',

  depot_consult_form_success_title: '咨询邮件已发送成功',
  depot_consult_form_success_text: '感谢您的咨询，我们的同事将通过邮件联系您',

  depot_upgrade_promotion_title: '升级优选堆场',
  depot_upgrade_promotion_notice: '推广期间免费升级',
  depot_upgrade_promotion_advantage1_title: '1.增加曝光，',
  depot_upgrade_promotion_advantage1_content: '优配通过全球网络定期推送优选堆场给世界各地客户；',
  depot_upgrade_promotion_advantage2_title: '2.客户安心，',
  depot_upgrade_promotion_advantage2_content: '升级后拥有优选标识的堆场让客户更放心；',
  depot_upgrade_promotion_advantage3_title: '3.加强合作，',
  depot_upgrade_promotion_advantage3_content: '优配将加强与优选堆场的合作，实现强强联合。 ',
  depot_upgrade_promotion_button: '我要升级',

  depot_no_data_title: '暂无数据',
  depot_no_data_text: '没找到想要的堆场?',
  depot_no_data_return: '返回上一页',

  depot_reside_title: '我要入驻',
  depot_reside_info_title: '堆场信息',
  depot_reside_form_companyName: '公司名称',
  depot_reside_form_contactNumber: '联系电话',
  depot_reside_form_contactEmail: '联系邮箱',
  depot_reside_form_companyWebsite: '公司网址',
  depot_reside_form_depotName: '堆场名称',
  depot_reside_form_depotLocation: '堆场位置',
  depot_reside_form_detailedAddressOfTheDepot: '堆场详细地址',
  depot_reside_form_repairAndCleaningService: '可修洗',
  depot_reside_form_fromPort: '距离港口或场站（KM）',
  depot_reside_form_depotArea: '堆场面积（M²）',
  depot_reside_form_depotCapacity: '堆场容量（TEU）',
  depot_reside_form_throughput: '吞吐能力（柜/月）',
  depot_reside_form_businessHours: '作业时间',
  depot_reside_form_uninterruptedHours: '7*24 小时',
  depot_reside_form_workDay: '工作日',
  depot_reside_form_workTime: '工作时间',
  depot_reside_form_uploadDepotImage: '上传堆场图片',
  depot_reside_form_remark: '备注',
  depot_reside_form_chargingStandards_title: '收费标准',
  depot_reside_form_emptyContainer: '空柜',
  depot_reside_form_loadedContainer: '重柜',
  depot_reside_form_20Feet : '20尺',
  depot_reside_form_40Feet : '40尺',
  depot_reside_form_freeStoragePeriod: '免堆期（天）',
  depot_reside_form_storageFee: '堆存费（柜/天）',
  depot_reside_form_gateInLiftingFee: '进场吊箱费（元/柜/次）',
  depot_reside_form_gateOutLiftingFee: '出场吊箱费（元/柜/次）',

  depot_reside_form_freeStoragePeriod_complete: '免堆期（天）',
  depot_reside_form_storageFee_complete: '堆存费（柜/天）',
  depot_reside_form_gateInLiftingFee_complete: '进场吊箱费（元/柜/次）',
  depot_reside_form_gateOutLiftingFee_complete: '出场吊箱费（元/柜/次）',

  depot_reside_form_enter_companyName: '请输入公司名称',
  depot_reside_form_enter_contactNumber: '请输入联系电话',
  depot_reside_form_enter_contactEmail: '请输入联系邮箱',
  depot_reside_form_enter_companyWebsite: '请输入公司网址',
  depot_reside_form_enter_depotName: '请输入堆场名称',
  depot_reside_form_enter_depotCountry: '请选择国家',
  depot_reside_form_enter_depotCity: '请选择城市',
  depot_reside_form_enter_detailedAddressOfTheDepot: '请填写堆场详细地址',
  depot_reside_form_enter_fromPort: '请输入距离港口或场站距离',
  depot_reside_form_enter_depotArea: '请输入堆场面积',
  depot_reside_form_enter_depotCapacity: '请输入堆场容量',
  depot_reside_form_enter_throughput: '请输入堆场吞吐能力',
  depot_reside_form_enter_depotImg: '图片上传',
  depot_reside_form_enter_remark: '请填写希望告知我们的信息',

  depot_reside_form_enter_freeStoragePeriod: '请输入免堆期',
  depot_reside_form_enter_storageFee: '请输入堆存费',
  depot_reside_form_enter_gateInLiftingFee: '请输入进场吊箱费',
  depot_reside_form_enter_gateOutLiftingFee: '请输入出场吊箱费',

  depot_reside_form_notNull_companyName: '公司名称不能为空',
  depot_reside_form_notNull_contactNumber: '联系电话不能为空',
  depot_reside_form_notNull_contactEmail: '联系邮箱不能为空',
  depot_reside_form_notNull_companyWebsite: '公司网址不能为空',
  depot_reside_form_notNull_depotName: '堆场名称不能为空',
  depot_reside_form_notNull_depotCountry: '国家信息不能为空',
  depot_reside_form_notNull_depotCity: '城市不能为空',
  depot_reside_form_notNull_detailedAddressOfTheDepot: '堆场详细地址不能为空',
  depot_reside_form_notNull_fromPort: '距离港口或场站距离不能为空',
  depot_reside_form_notNull_depotArea: '堆场面积不能为空',
  depot_reside_form_notNull_depotCapacity: '堆场容量不能为空',
  depot_reside_form_notNull_throughput: '堆场吞吐能力不能为空',
  depot_reside_form_notNull_depotImg: '堆场图片不能为空',

  depot_reside_form_notNull_freeStoragePeriod: '免堆期不能为空',
  depot_reside_form_notNull_storageFee: '堆存费不能为空',
  depot_reside_form_notNull_gateInLiftingFee: '进场吊箱费不能为空',
  depot_reside_form_notNull_gateOutLiftingFee: '出场吊箱费不能为空',

  depot_reside_form_button_submit: '马上入驻',
  depot_reside_form_button_cancel: '取 消',

  depot_reside_form_success_text: '正在审核中',
  depot_upgrade_form_success_text: '正在审核中，我们的同事稍后会与您联系',

  depot_info_depot_title: '我的堆场',
  depot_info_upgrade_depot_title: '升级为优选堆场',
  depot_info_form_title: '堆场信息',
  depot_info_form_companyName: '公司名称',
  depot_info_form_contactNumber: '联系电话',
  depot_info_form_contactEmail: '联系邮箱',
  depot_info_form_companyWebsite: '公司网址',
  depot_info_form_depotName: '堆场名称',
  depot_info_form_depotLocation: '堆场位置',
  depot_info_form_detailedAddressOfTheDepot: '堆场详细地址',
  depot_info_form_repairAndCleaningService: '可修洗',
  depot_info_form_isRepairAndCleaningService: '是否可以修洗',
  depot_info_form_fromPort: '距离港口或场站（KM）',
  depot_info_form_depotArea: '堆场面积（M²）',
  depot_info_form_depotCapacity: '堆场容量（TEU）',
  depot_info_form_throughput: '吞吐能力（柜/月）',
  depot_info_form_businessHours: '作业时间',
  depot_info_form_uninterruptedHours: '7*24 小时',
  depot_info_form_workDay: '工作日',
  depot_info_form_workTime: '工作时间',
  depot_info_form_uploadDepotImage: '上传堆场图片',
  depot_info_form_depotImage: '堆场图片',
  depot_info_form_depotImage_view: '查看图片',
  depot_info_form_remark: '备注',
  depot_info_form_chargingStandards_title: '收费标准',
  depot_info_form_emptyContainer: '空柜',
  depot_info_form_loadedContainer: '重柜',
  depot_info_form_20Feet : '20尺',
  depot_info_form_40Feet : '40尺',
  depot_info_form_freeStoragePeriod: '免堆期（天）',
  depot_info_form_storageFee: '堆存费（柜/天）',
  depot_info_form_gateInLiftingFee: '进场吊箱费（元/柜/次）',
  depot_info_form_gateOutLiftingFee: '出场吊箱费（元/柜/次）',

  depot_info_20Feet_title: '20尺收费标准',
  depot_info_40Feet_title: '40尺收费标准',

  depot_info_update_confirm: '确认',
  depot_info_update_cancel: '取消',
  depot_info_return_button: '返回',

  depot_info_form_enter_companyName: '请输入公司名称',
  depot_info_form_enter_contactNumber: '请输入联系电话',
  depot_info_form_enter_contactEmail: '请输入联系邮箱',
  depot_info_form_enter_companyWebsite: '请输入公司网址',
  depot_info_form_enter_depotName: '请输入堆场名称',
  depot_info_form_enter_depotCountry: '请选择国家',
  depot_info_form_enter_depotCity: '请选择城市',
  depot_info_form_enter_detailedAddressOfTheDepot: '请填写堆场详细地址',
  depot_info_form_enter_fromPort: '请输入距离港口或场站距离',
  depot_info_form_enter_depotArea: '请输入堆场面积',
  depot_info_form_enter_depotCapacity: '请输入堆场容量',
  depot_info_form_enter_throughput: '请输入堆场吞吐能力',
  depot_info_form_enter_depotImg: '图片上传',
  depot_info_form_enter_remark: '请填写希望告知我们的信息',

  depot_info_form_enter_freeStoragePeriod: '请输入免堆期',
  depot_info_form_enter_storageFee: '请输入堆存费',
  depot_info_form_enter_gateInLiftingFee: '请输入进场吊箱费',
  depot_info_form_enter_gateOutLiftingFee: '请输入出场吊箱费',

  depot_basic_info_title: '堆场信息',
  depot_basic_info_repairAndCleaningService: '是',
  depot_basic_info_noRepairAndCleaningService: '否',
  depot_update_success_text: '修改成功',
  depot_no_depot_text: '还没有入驻?',
  depot_no_depot_join_text: '马上入驻',





}
