//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_members_info",
  data () {
    return{
      showOverdueModule: false,
      item:{
        companyContact: '',
        companyName: '',
        vipStartDate: '',
        vipEndDate: '',
        vipRemainingDays: ''
      }
    }
  },
  methods: {
    toIndex(){
      this.$router.push({
        path:'/containerTrading'
      })
    },
    toRenew(){
      this.$router.push({
        path:`/register?type=renew`
      })
    },
    async renewMember(){
      try {
        const params = {
          username: this.getLocalStoreItem('username'),
          userId: this.getLocalStoreItem('userId'),
          vipMenu: this.renewVipMenu,
        }
        const result = await this.$axios.post('/bk/vipAudit/vipRenew', params);

        console.log(result + 'result')
        if(success){

        }
      }catch (e){
        console.log(e, 'e')
      }
    },
    async getProfileInfo(){
      const param = {
        username: this.getLocalStoreItem("username")
      }
      try{
        const result = await this.$axios.get('/bk/userSetting/getUserInfo', {
          params: param
        })
        if(result === 401) {
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }else if(result.username){
          this.item = result;

        }else{
          this.$message.error(this.$t('t.contact_IT'))
        }
      }catch (e) {

      }
    },
  },
  created() {
    this.showOverdueModule = this.getLocalStoreItem('um-vip') === '2';
    this.getProfileInfo()
  }
}
