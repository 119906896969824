//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import videojs from "video.js";
import "videojs-contrib-hls";

export default {
  name: 'about',
  data () {
    return {
      menuList: [
        { abouttitle: this.$t('a.abouttitle') },
        { missioncontent1: this.$t('a.missioncontent1') },
        { missioncontent2: this.$t('a.missioncontent2') },
        { reduceco2title: this.$t('a.reduceco2title') },
        { reduceco2desc1_1: this.$t('a.reduceco2desc1_1') },
        { reduceco2desc2_1: this.$t('a.reduceco2desc2_1') },
        { reduceco2desc1_2: this.$t('a.reduceco2desc1_2') },
        { reduceco2desc2_2: this.$t('a.reduceco2desc2_2') },
        { reduceco2desc1_3: this.$t('a.reduceco2desc1_3') },
        { reduceco2desc2_3: this.$t('a.reduceco2desc2_3') },
        { containergoodstitle: this.$t('a.containergoodstitle') },
        { containergoodsdesccitle1: this.$t('a.containergoodsdesccitle1') },
        { containergoodsdesc1: this.$t('a.containergoodsdesc1') },
        { containergoodsdesccitle2: this.$t('a.containergoodsdesccitle2') },
        { containergoodsdesc2: this.$t('a.containergoodsdesc2') },
        { containergoodsdesccitle3: this.$t('a.containergoodsdesccitle3') },
        { containergoodsdesc3: this.$t('a.containergoodsdesc3') },
        { fromcustomerstitle: this.$t('a.fromcustomerstitle') },
        { customersname1: this.$t('a.customersname1') },
        { customersarea1: this.$t('a.customersarea1') },
        { customersmessageinfo1: this.$t('a.customersmessageinfo1') },
        { customersname2: this.$t('a.customersname2') },
        { missioncontencustomersarea2t2: this.$t('a.customersarea2') },
        { customersmessageinfo2: this.$t('a.customersmessageinfo2') },
        { customersmessageinfo3: this.$t('a.customersmessageinfo3') }
      ],
      videoSrc: this.baseURL + '/resource/about_UM.m3u8'
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  mounted() {
    this.getVideo();
  },
  methods: {
    getVideo() {
      videojs(
        "my-video",
        {
          bigPlayButton: true,
          textTrackDisplay: false,
          posterImage: true,
          errorDisplay: true,
          controlBar: true
        },
        function() {
          this.play();
        }
      );
    }
  }
}
