//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
  },
  data () {
      return {
          currentOffset: 0,
          windowSize: 3,
          paginationFactor: 440,
          totalRecords: 8
      }
  },
  created () {
      let w =document.documentElement.clientWidth;
      this.paginationFactor = parseInt(w * 0.270);
  },
    computed: {
        atEndOfList() {
            return this.currentOffset <= (this.paginationFactor * -1) * (this.totalRecords - this.windowSize);
        },
        atHeadOfList() {
            return this.currentOffset >= 0;
        },
    },
    methods: {
        moveCarousel(direction) {
            if (direction === 1 && !this.atEndOfList) {
                this.currentOffset -= this.paginationFactor;
            } else if (direction === -1 && !this.atHeadOfList) {
                this.currentOffset += this.paginationFactor;
            }
        },
        resetOffset(){
            this.currentOffset = 0;
        }
    }
}
