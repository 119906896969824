//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "consult_detail",
  data(){
    const validateEnquirySize = (rule, value, callback) => {
        if (!value) {
            return callback(new Error(this.$t('t.form_validate_error_tip_size')))
        } else {
            return callback()
        }
    }
    const validateEnquiryType = (rule, value, callback) => {
        if (!value) {
            return callback(new Error(this.$t('t.form_validate_error_tip_type')))
        } else {
            return callback()
        }
    }
    const validateEnquiryContainerStatus = (rule, value, callback) => {
        if (!value) {
            return callback(new Error(this.$t('t.form_validate_error_tip_condition')))
        } else {
            return callback()
        }
    }
    const validateEnquiryRequestQty = (rule, value, callback) => {
        if (!value) {
            return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
        } else {
            return callback()
        }
    }
    const validateEnquiryPickupLoc = (rule, value, callback) => {
        if (!value) {
            return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
        } else {
            return callback()
        }
    }
    const validateEnquiryLastPickupDate = (rule, value, callback) => {
        if (!value) {
            return callback(new Error(this.$t('t.form_validate_error_tip_latestPickupDate')))
        } else {
            return callback()
        }
    }
    return {
      item: {},
      consultForm: {
          sizeType: '',
          requestQty: '',
          pickupLoc: '',
          containerStatus: '',
          lastPickupDate: '',
          size: '',
          type: ''
        },
      showConsultDialog: false,
      enquiryRules: {
          size: [{ required: true, validator: validateEnquirySize, trigger: 'blur' }],
          type: [{ required: true, validator: validateEnquiryType, trigger: 'blur' }],
          requestQty: [{ required: true, validator: validateEnquiryRequestQty, trigger: 'blur' }],
          containerStatus: [{ required: true, validator: validateEnquiryContainerStatus, trigger: 'blur' }],
          pickupLoc: [{ required: true, validator: validateEnquiryPickupLoc, trigger: 'blur' }],
          lastPickupDate: [{ required: true, validator: validateEnquiryLastPickupDate, trigger: 'blur' }],
      },
      showTip: false,
      consultNo:'',
      portNameOptions: [],
      loading: false,
    }
  },
  methods:{
    openConsultDialog(){
        if(!this.getLocalStoreItem('token')){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
            return ;
        }
        this.consultForm = {
            ...this.item,
            size: this.item.sizeType.substring(0,2),
            type: this.item.sizeType.substring(2,4)
        }
        this.showConsultDialog = true;
    },
    async updateConsultRecord(){
        await this.$refs.consultForm.validate()

        this.consultForm.sizeType = this.consultForm.size + this.consultForm.type;
        this.consultForm.lastPickupDate = this.dateFormat(this.consultForm.lastPickupDate);
        const params = {
            ...this.consultForm,
            userId: this.getLocalStoreItem('userId')
        }
        const result = await this.$axios.post('/bk/tradingConsult/updateConsult', params);
        if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(result === 'success'){
            this.item = this.consultForm;
            this.showConsultDialog = false;
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
    },
    async deleteConsultRecord(){
        const params = {
            id: this.item.id,
            userId: this.getLocalStoreItem('userId')
        }
        const result = await this.$axios.post('/bk/tradingConsult/deleteConsult', params);
        if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(result === 'success'){
          this.$message({
            message: 'Delete Success',
            type: 'success'
          });
          this.$router.push({
            path:'/containerInquiry'
          })
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
    },
    dateFormat(dateStr){
          const date = new Date(dateStr);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          const formattedMonth = month < 10 ? `0${month}` : month;
          const formattedDay = day < 10 ? `0${day}` : day;

          const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
          return formattedDate;
      },
    async getDetail () {
          try {
              const param = {
                  consultNo: this.consultNo
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/tradingConsult/getDetail', {
                  params: param
              })
              this.loading = false;
              if(res.consultNo){
                  this.item = res;
                  this.consultForm = {
                      ...this.item
                  }
                  this.consultForm.size = this.item.sizeType.substring(0,2);
                  this.consultForm.type = this.item.sizeType.substring(2,4);
              }
          } catch (e) {
              console.log(e, 'e')
          }
      },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.consultNo = this.$route.params.consultNo;
      this.getDetail();
      this.searchPort('SHA');
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
