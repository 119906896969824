//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'registerresult',
  data () {
    return {
      currentCategoary: 'free'
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.currentCategoary = this.$route.query.type
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
    totalForm () {
      return this.currentCategoary && this.currentCategoary === 'toll'
    }
  },
  methods: {
    jumperLink () {
      window.location = 'http://www.unit-match.com'
    }
  }
}
