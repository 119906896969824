//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Footer",
  props: {

  },
  data() {
    return {
      footeritem:[],
      copyright:this.$t('m.copyright')
    };
  },
  computed: {
      currentLang () {
          this.footeritem = [{
              name:this.$t('m.footer_title1'),
              children:[ {
                  name:this.$t('m.footer_list_info1')
                },
                {
                    name:this.$t('m.footer_list_info2')
                },
                {
                    name:this.$t('m.footer_list_info3')
                },
                {
                    name:this.$t('m.footer_list_info4')
                },
              ]
            },{
              name:this.$t('m.footer_title2'),
              children:[
                  {
                      name:this.$t('m.footer_list_info5')
                  },
                  {
                      name:this.$t('m.footer_list_info6')
                  },
                  {
                      name:this.$t('m.footer_list_info7')
                  },
                  {
                      name:this.$t('m.footer_list_info8')
                  },
              ]
            },{
              name:this.$t('m.footer_title3'),
              children:[
                  {
                      name:this.$t('m.trading_purchase_label')
                  },
                  {
                      name:this.$t('m.trading_space_label')
                  },
                  {
                      name:this.$t('m.trading_lease_label')
                  },
                  {
                      name:this.$t('m.trading_management_label')
                  },
              ]
            }];
          return this.$i18n.locale
      }
    },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
  },
  methods: {

  },
  mounted() {}
};
