//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmojiIcon from './icons/EmojiIcon.vue'
import UmyIcon from './icons/UmyIcon.vue'
import FileIcons from './icons/FileIcons.vue'
import UserInputButton from './UserInputButton.vue'
import Suggestions from './Suggestions.vue'
import FileIcon from '../../assets/file.svg'
import CloseIconSvg from '../../assets/close.svg'
import store from './store/'
import IconCross from './components/icons/IconCross.vue'
import IconOk from './components/icons/IconOk.vue'
import IconSend from './components/icons/IconSend.vue'

export default {
  components: {
    EmojiIcon,
    UmyIcon,
    FileIcons,
    UserInputButton,
    Suggestions,
    IconCross,
    IconOk,
    IconSend
  },
  props: {
    icons: {
      type: Object,
      default: function () {
        return {
          file: {
            img: FileIcon,
            name: 'default'
          },
          // closeSvg: {
          //   img: CloseIconSvg,
          //   name: 'default'
          // }
        }
      }
    },
    showEmoji: {
      type: Boolean,
      default: () => false
    },
    showUmy: {
      type: Boolean,
      default: () => false
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    showFile: {
      type: Boolean,
      default: () => false
    },
    onSubmit: {
      type: Function,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Write something...'
    },
    colors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file: {},
      inputActive: false,
      currentPasteImg: '',
      showSendImg: false,
      srcList: [],
      showSendFile: false,
      isImgFile: false
    }
  },
  computed: {
    editMessageId() {
      return this.isEditing && store.state.editMessage.id
    },
    isEditing() {
      return store.state.editMessage && store.state.editMessage.id
    }
  },
  watch: {
    editMessageId(m) {
      if (store.state.editMessage) {
        this.$refs.userInput.focus()
        this.$refs.userInput.textContent = store.state.editMessage.data.text
      } else {
        this.$refs.userInput.textContent = ''
      }
    }
  },
  mounted() {
    this.$root.$on('focusUserInput', () => {
      if (this.$refs.userInput) {
        this.focusUserInput()
      }
    })
  },
  methods: {
    cancelFile() {
      this.file = null
    },
    setInputActive(onoff) {
      this.inputActive = onoff
    },
    handleKey(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        if (!this.isEditing) {
          this._submitText(event)
        } else {
          this._editText(event)
        }
        this._editFinish()
        event.preventDefault()
      } else if (event.keyCode === 27) {
        this._editFinish()
        event.preventDefault()
      }

      this.$emit('onType')
    },
    focusUserInput() {
      this.$nextTick(() => {
        this.$refs.userInput.focus()
      })
    },
    mousedown(event) {
        event.stopPropagation();
    },
    _submitSuggestion(suggestion) {
      this.onSubmit({author: 'me', type: 'text', data: {text: suggestion}})
    },
    _checkSubmitSuccess(success,sendType) {
      this.showSendFile = false;
      if (Promise !== undefined) {
        let root = this;
        Promise.resolve(success).then(()=>{
            if(sendType === 'text'){
              root.$refs.userInput.innerHTML = ''
            }else if(sendType === 'file'){
              root.showSendFile = false;
              root.file = null;
            }else if(sendType === 'img'){
              root.showSendImg = false;
              root.currentPasteImg = null;
            }
          }
        )
      }
    },
    _submitText(event) {
      let content = this.$refs.userInput.innerHTML;
      if(content.indexOf('<img') !== -1){
        this._checkSubmitSuccess(
          this.onSubmit({
            author: 'me',
            type: 'img',
            fileContent: this.currentPasteImg,
            createTime: this.getCurrentDateTime()
          }),'img'
        )
      }
      const text = this.$refs.userInput.textContent
      if (text && text.length > 0) {
        this._checkSubmitSuccess(
          this.onSubmit({
            author: 'me',
            type: 'text',
            content: text,
            createTime: this.getCurrentDateTime()
          }),'text'
        )
      }
    },
    _submitTextWhenFile(event, text, file) {
      if (text && text.length > 0) {
        this._checkSubmitSuccess(
          this.onSubmit({
            author: 'me',
            type: 'text',
            content: text,
            createTime: this.getCurrentDateTime()
          }),'text'
        )
      }
      if(file){
        if(file.type.indexOf('image') !== -1){
          this._checkSubmitSuccess(
            this.onSubmit({
              author: 'me',
              type: 'img',
              content: this.currentPasteImg,
              createTime: this.getCurrentDateTime()
            }),'file'
          )
        }else {
          this._checkSubmitSuccess(
            this.onSubmit({
              author: 'me',
              type: 'file',
              content: this.getBase64(file),
              createTime: this.getCurrentDateTime()
            }),'file'
          )
        }
      }

    },
    _editText(event) {
      const text = this.$refs.userInput.textContent
      if (text && text.length) {
        this.$emit('edit', {
          author: 'me',
          type: 'text',
          id: store.state.editMessage.id,
            content: text
        })
        this._editFinish()
      }
    },
    _handleEmojiPicked(emoji) {
      this._checkSubmitSuccess(
        this.onSubmit({
          author: 'me',
          type: 'emoji',
          content: emoji
        }),'emoji'
      )
    },
    _handleUmyPicked(umy) {
      this._checkSubmitSuccess(
        this.onSubmit({
          author: 'me',
          type: 'umy',
          content: umy
        }),'umy'
      )
    },
    _handleFileSubmit(file) {
      this.file = file
      this.showSendFile = true
      this.isImgFile = this.file.type.indexOf('image') !== -1
      console.log(file)
      console.log('file')
    },
    _editFinish() {
      store.setState('editMessage', null)
    },
    compressImage(event) {
      // 获取粘贴的数据
      const items = (event.clipboardData || window.clipboardData).items;
      let item = null;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          item = items[i];
          event.preventDefault();
          break;
        }
      }
      if (item === null) {
        return;
      }
      // 读取图片文件
      const blob = item.getAsFile();
      const reader = new FileReader();
      reader.onload = (event) => {
        // 创建一个新的Image对象
        const img = new Image();
        img.src = event.target.result; // 设置图片源为读取到的图片数据URL
        this.currentPasteImg = event.target.result;
        this.srcList.push(this.currentPasteImg)
        console.log(this.srcList.add)
        this.showSendImg = true;
      };
      reader.readAsDataURL(blob); // 将图片文件转换为DataURL格式的字符串数据
    },
    sendPastedImages(){
      this._checkSubmitSuccess(
        this.onSubmit({
          author: 'me',
          type: 'img',
          fileContent: this.currentPasteImg,
          createTime: this.getCurrentDateTime()
        }),'img'
      )
    },
    sendFile(){
      if(this.file.type.indexOf('image') !== -1){
        this.getBase64(this.file).then(res => {
          this.currentPasteImg = res
          this._checkSubmitSuccess(
            this.onSubmit({
              author: 'me',
              type: 'img',
              fileContent: res,
              content: this.file.name,
              fileName: this.file.name,
              createTime: new Date()
            }), 'file'
          )
        })
      }else {
        this.getBase64(this.file).then(res => {
          this._checkSubmitSuccess(
            this.onSubmit({
              author: 'me',
              type: 'file',
              fileName: this.file.name,
              content: this.file.name,
              fileContent: res,
              createTime: new Date()
            }),'file'
          )
        })
      }
    },
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从0开始，所以需要加1
      const date = now.getDate();
      const hours = now.getHours();
      const minutes = String(now.getMinutes()).padStart(2, '0'); // 使用padStart方法添加前导零
      const formattedDateTime = `${year}-${month}-${date} ${hours}:${minutes}`;
      return formattedDateTime;
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    }
  }
}
