//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_factory_order_detail",
  data(){
    const validateSize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateColorSys = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('色系不能为空'))
      } else {
        return callback()
      }
    }
    const validatePickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('最迟提柜日期不能为空'))
      } else {
        return callback()
      }
    }
    const validatePrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_price')))
      } else {
        return callback()
      }
    }
    return{
      item: {},
      editDirectFactoryOrderForm:{
        size: '',
        Type: '',
        sizeType: '',
        qty: '',
        colorSys: '',
        hasLockBox: '',
        hasForklift: '',
        logoRequest: '',
        pickupLoc: '',
        lastPickupDate:'',
        price: '',
        remark: '',
      },
      editCollageFactoryOrderForm:{
        size: '',
        Type: '',
        sizeType: '',
        qty: '',
        pickupLoc: '',
        lastPickupDate:'',
        price: '',
        remark: '',
      },
      directFactoryOrderRules: {
        size: [{ required: true, validator: validateSize, trigger: 'blur' }],
        type: [{ required: true, validator: validateType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateQty, trigger: 'blur' }],
        colorSys: [{ required: true, validator: validateColorSys, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validatePickupLoc, trigger: 'blur' }],
        lastPickupDate: [{ required: true, validator: validateLastPickupDate, trigger: 'blur' }],
        price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
      },
      collageFactoryOrderRules: {
        size: [{ required: true, validator: validateSize, trigger: 'blur' }],
        type: [{ required: true, validator: validateType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateQty, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validatePickupLoc, trigger: 'blur' }],
        lastPickupDate: [{ required: true, validator: validateLastPickupDate, trigger: 'blur' }],
        price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
      },
      currentRecordId:'',
      showDirectFactoryDialog: false,
      showCollageFactoryDialog: false,
      deleteInfoDialog: false,
      orderNo: '',
      portNameOptions: [],
      loading: false,
    }
  },
  methods:{
    openEditDialog(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      if(item.productType === 'Single Order'){
        this.editDirectFactoryOrderForm = {
          ...item,
          size: item.sizeType.substring(0,2),
          type: item.sizeType.substring(2,4)
        }
        this.showDirectFactoryDialog = true;
      }else if(item.productType === 'Group Order'){
        this.editCollageFactoryOrderForm = {
          ...item,
          size: item.sizeType.substring(0,2),
          type: item.sizeType.substring(2,4)
        }
        this.showCollageFactoryDialog = true;
      }
    },
    async updateFactoryRecord(flag){

      let params;
      if(flag === 'Single Order'){
        await this.$refs.editDirectFactoryOrderForm.validate();
        this.editDirectFactoryOrderForm.sizeType = this.editDirectFactoryOrderForm.size + this.editDirectFactoryOrderForm.type;
        this.editDirectFactoryOrderForm.lastPickupDate = this.dateFormat(this.editDirectFactoryOrderForm.lastPickupDate);
        params = {
          ...this.editDirectFactoryOrderForm,
          username: this.getLocalStoreItem('username')
        }
      }

      if(flag === 'Group Order'){
        await this.$refs.editCollageFactoryOrderForm.validate();
        this.editCollageFactoryOrderForm.sizeType = this.editCollageFactoryOrderForm.size + this.editCollageFactoryOrderForm.type;
        this.editCollageFactoryOrderForm.lastPickupDate = this.dateFormat(this.editCollageFactoryOrderForm.lastPickupDate);
        params = {
          ...this.editCollageFactoryOrderForm,
          username: this.getLocalStoreItem('username')
        }
      }

      const result = await this.$axios.post('/bk/tradingFactoryOrder/updateOrder', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        if(flag === 'Single Order'){
          this.item = this.editDirectFactoryOrderForm;
          this.showDirectFactoryDialog = false;
        }
        if(flag === 'Group Order'){
          this.item = this.editCollageFactoryOrderForm;
          this.showCollageFactoryDialog = false;
        }

        this.$message({
          message: 'Update Success',
          type: 'success'
        });
        // location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    openDeleteDialog(item){
      this.currentRecordId = item.id;
      this.deleteInfoDialog = true;
    },
    async deleteFactoryRecord(){
      const params = {
        id: this.currentRecordId,
        username: this.getLocalStoreItem('username')
      }
      console.log(params);
      const result = await this.$axios.post('/bk/tradingFactoryOrder/deleteOrder', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.$message({
          message: 'Delete Success',
          type: 'success'
        });
        this.$router.push({
          path:'/factoryOrderManage'
        })
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
      this.currentRecordId = '';
      this.deleteInfoDialog = false;
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async getDetail () {
          try {
              const param = {
                  orderNo: this.orderNo
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/tradingFactoryOrder/getDetail', {
                  params: param
              })
              this.loading = false;
              if(res.orderNo){
                  this.item = res;
              }
          } catch (e) {
              console.log(e, 'e')
          }
      },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.orderNo = this.$route.params.orderNo;
    this.getDetail();
    this.searchPort('SHA');
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
