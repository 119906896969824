//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_space_inquiry_detail",
  data(){
    return{
      consultNo: '',
      item:{}
    }
  },
  methods:{
    async getSpotSpaceInquiryDetail () {
      try {
        const params = {
          consultNo: this.consultNo
        }
        const res = await this.$axios.get('/bk/spotConsult/getDetail', {
          params: params
        });

        if(res.consultNo){
          this.item = res;
        }
        console.log(res)
      } catch (e) {
        console.log(e, 'e')
      }
    },
    deleteInquiryRecord(){}
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.consultNo = this.$route.params.consultNo;
    this.getSpotSpaceInquiryDetail();
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
