//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "consult_manage",
  data(){
    return {
      consultList: [],
      isEmpty: true,
    }
  },
  methods:{
      async viewAllConsult(){
          const params = {
              userId:this.getLocalStoreItem("userId"),
          }
          const Result = await this.$axios.get('/bk/tradingConsult/listContainerConsult', {
              params: params
          });
          if(Result === 401){
              localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
              this.$router.push({
                  path:'/login'
              })
          }
          console.log(Result);
          this.consultList = Result;
          this.isEmpty = this.consultList.length === 0;
      },
      toDetail(item){
          console.log(item)
          try {
              this.$router.push({
                  path: '/consultDetail/' + item.consultNo
              });
          } catch (e) {
              console.log(e, 'e')
          }
      },
    continueBrowsing(){
      this.$router.push({
        path:'/containerTrading'
      })
    }
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.viewAllConsult();
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
