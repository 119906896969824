//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "pass_manage",

  data(){
    const validateOldPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.passManage_form_currentPassWordNotNull')))
      } else {
        return callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.passManage_form_newPassWordNotNull')))
      } else {
        return callback()
      }
    }
    const validatePasswordConfirmation = (rule, value, callback) => {

      if (!value) {
        return callback(new Error(this.$t('t.passManage_form_confirmPassWordNotNull')))
      } else if(value !== this.passwordForm.password){
        return callback(new Error(this.$t('t.passManage_form_confirmPassWordInconsistent')))
      }
      else {
        return callback()
      }
    }
    return{
      passwordForm:{
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ required: true, validator: validateOldPassword, trigger: 'blur' }],
        password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: validatePasswordConfirmation, trigger: 'blur' }]
      },
      showErrorMessage : false,
    }
  },
  methods:{
    async submitInfo (e) {
      try {
        await this.$refs.passwordForm.validate()

        const params = {
          ...this.passwordForm,
          username: this.getLocalStoreItem("username"),
        }
        delete params.confirmPassword;
        console.log(JSON.stringify(params) + 'params')
        const result = await this.$axios.post('/bk/userSetting/updatePwd', params);
        console.log(result.message + 'status');
        if (result.message === 'update success') {
            localStorage.removeItem('token');
            localStorage.removeItem("username");
            localStorage.removeItem("userId");
            localStorage.removeItem("um-vip");
            localStorage.removeItem("depotVip");
            localStorage.removeItem("role");
            localStorage.removeItem("lastRefreshTime");

            this.$router.push({
                path:'/login'
            })
        }else if(result === 401) {

          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })

        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }

      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
