//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "delegation_manage",
  data(){
    return {
        delegationList: [],
        isEmpty: true,
    }
  },
  methods:{
    async viewAllDelegation(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const Result = await this.$axios.get('/bk/tradingEntrust/listEntrustSell', {
        params: params
      });
      if(Result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
            path:'/login'
        })
      }
      this.delegationList = Result;
      this.isEmpty = this.delegationList.length === 0;
    },
    viewDelegationDetail(item){
      console.log(item)
      try {
        this.$router.push({
            path: '/delegationDetail/' + item.entrustNo
        });
      } catch (e) {
        console.log(e, 'e')
      }
    },
    continueBrowsing(){
      this.$router.push({
        path:'/containerTrading'
      })
    }
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.viewAllDelegation();
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
