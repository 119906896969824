export const m = {
  home: 'Home',
  blog: 'Blog',
  aboutUs: 'About us',
  product: 'UM products',
  contactUs: 'Contact us',
  button: 'Sign in',
  nameNotEmpty: 'Name cannot be empty',
  emailNotEmpty: 'E-mail cannot be empty',
  errorEmailAddress: 'Please input the correct email address',
  phoneNotEmpty: 'Phone cannot be empty',
  umMarketPlace1: '',
  umMarketPlace2: 'UM Marketplace',
  umMarketPlace3: '',
  containerLeasing: 'Container Leasing',
  containerContent1: 'As a solution specialist in cargo container leasing, UM proudly provides vast container resources for our users, also UM Marketplace will quickly target the best container options for you to choose from through our intelligent matching technology.',
  containerContent2: 'With our smart operating platform, you can check the available containers with one click and send a rental request in seconds. Additionally, the subsequent transmission of the container documents can be finished online.',
  containerContent3: ' provides efficient and seamless rental service, saving time and effort!',
  containerContent4:'',
  mbContainerContent1: 'As a solution specialist in cargo container leasing, UM proudly provides vast container resources for our users, also UM Marketplace will quickly target the best container options for you to choose from through our intelligent matching technology.',
  mbContainerContent2: 'With our smart operating platform, you can check the available containers with one click and send a rental request in seconds. Additionally, the subsequent transmission of the container documents can be finished online.',
  mbContainerContent3: 'UM Marketplace provides efficient and seamless rental service, saving time and effort!',
  getDemo: 'Get a Demo',
  shareContainer: 'Share Container Platform',
  newRequirment: 'Promotion',
  viewAll: 'View All',
  nullData: 'NUll Data',
  register: 'Register',
  // 注册
  memberRegistration: 'Member Registration',
  leasingPass: 'Free',
  vipLeasingPass: 'Premium',
  visitor: 'Visitor',
  visitor_01: 'Available for searching container leasing updates;',
  visitor_02: 'View container matching results by registering;',
  visitor_03: 'Not available for container leasing.',
  free: 'Free',
  leasingPass_01: 'No transaction fees',
  leasingPass_02: 'Provide 1 member account',
  leasingPass_03: 'No limitation on container leasing volume',
  leasingPass_04: 'Exclusive UMIVERSE Membership Box',
  leasingPass_05: 'Exclusive Container Fee Refund Plan',
  leasingPass_06: 'UM Carbon Credits Exchange Rewards',
  leasingPass_07: 'Eligible to be selected as the UM Emissions Reduction Ambassador in the annual ESG Awards ',
  leasingPass_button: '268 USD/1888 RMB/Month',
  free_button: 'Free',
  leasingPass_tip: 'Quarterly / semi-annual / annual payment options available',
  upgrade_member_title: 'Upgrade to Premium',
  upgrade_member_text: 'Prioritize rebates and low-carbon',
  upgrade_member_quarter_text: '3 MONTHS',
  upgrade_member_halfQuarter_text: '6 MONTHS',
  upgrade_member_year_text: '12 MONTHS',
  upgrade_member_unit: 'about',
  upgrade_member_month: 'month',
  upgrade_member_button: 'Upgrade',
  renew_member_button: 'Current',
  upgrade_member_free_text: 'Free',
  upgrade_member_premium_text: 'Premium',
  companyAndBilling: 'Company And Billing Details',
  companyName: 'Company Name (Full legal name)',
  legalRepresentative: 'Legal Representative Name',
  companyAddress: 'Company Address',
  companyBusinessLicense: 'Company Business License',
  imageUpload: 'Image upload',
  uniformCreditCode: 'UCC No. / TIN',
  companyBankAccount: 'Company Bank Account',
  bankNameOfAccount: 'Bank Name Of The Company Account',
  yourContactDetails: 'Your Contact Details',
  userName: "User's full Name",
  position: 'Position',
  IDNumber: 'ID number',
  IDCardFrontSide: 'ID Card front side / Business Card / Authorization',
  IDCardBackSide: 'ID Card back side / Business Card / Authorization',
  contactEmail: 'Contact Email',
  companyContactEmail: 'Contact Email',
  registeredMember: 'Registered Member',
  agreeColle: 'I agree to the collection of the above information',
  agreeprotocol: 'read and agree《Terms of use》《Privacy policy》《Disclaimer》《Premium Member Services Agreement》',
  termsOfUse: '《Terms of use》',
  privacyPolicy: '《Privacy policy》',
  disclaimer: '《Disclaimer》',
  companyNameNotNull: 'Company name cannot be empty',
  legalRepresentativeNotNull: 'Legal representative cannot be empty',
  companyAddressNotNull: 'Company address cannot be empty',
  uniformCreditCodeNotNull: 'Unified Credit Code cannot be empty',
  companyBankAccountNotNull: 'Company account cannot be empty',
  bankNameOfAccountNotNull: 'Account bank cannot be empty',
  userNameNotNull: 'Username cannot be empty',
  positionNotNull: 'Company position cannot be empty',
  IDNumberNotNull: 'ID number cannot be empty',
  contactEmailNotNull: 'Contact email cannot be empty',
  companyContactEmailNotNull: 'Contact email cannot be empty',
  agreeColleFirst: 'Check first and then submit the registration',
  registeredMemberSuccess: 'Membership application submitted successfully',
  city: 'City',
  country: 'Country',
  cityNotNull: 'City cannot be empty',
  countryNotNull: 'Country cannot be empty',
  companyBusinessLicenseNotNull: 'Company Business License cannot be empty',
  IDCardFrontSideNotNull: 'ID Card front side / Business Card / Authorization cannot be empty',
  IDCardBackSideilNotNull: 'ID Card back side / Business Card / Authorization cannot be empty',
  result_1: 'Dear user :',
  result_2: 'Hi! We have received your registration information. The COE team will send the membership agreement and payment to your email.Please kindly check it.',
  result_3: 'If you have any questions, please send them to the COE team. ',
  result_4: 'Contact：',
  result_5: ' and we will reply to you ASAP.',
  result_6: 'Yours sincerely',
  result_7: 'UM COE team',
  result_8: 'Congratulations! You are now a member of Marketplace, click on the link',
  result_9: 'to login in and enjoy the member services.',
  result_10: 'Thank you for registering as a member of UM Marketplace!',
  benefits_title: 'NOR Reward Is Coming',
  benefits_content_subject1: 'Take advantage of our special offer monthly.',
  benefits_content1: 'We offer unique transportation experiences with Non-Operating Reefer containers. Compared to a 40 GP container, the NOR have the same loading space, but they are more environmentally friendly and more cost-effective.',
  benefits_content_subject2: 'SOC Access Global',
  benefits_content2: 'For a limited time, enjoy exclusive rates for 45HCPW to Europe and Turkey, and 53HC to the United States. Register for priority access in the Marketplace!',
  benefits_content3: '',
  benefits_content4: '*Container credits vary by destination port.',
  goSearch:'Go Search',
  mb_benefits_title: 'NOR Reward Is Coming',
  mb_benefits_content_subject1: 'Take advantage of our special offer monthly.',
  mb_benefits_content1: 'We offer unique transportation experiences with Non-Operating Reefer containers. Compared to a 40 GP container, the NOR have the same loading space, but they are more environmentally friendly and more cost-effective.',
  mb_benefits_content_subject2: 'SOC Access Global',
  mb_benefits_content2: 'For a limited time, enjoy exclusive rates for 45HCPW to Europe and Turkey, and 53HC to the United States. Register for priority access in the Marketplace!',
  mb_benefits_content3: '',
  mb_benefits_content4: '*Container credits vary by destination port.',
  buyContainers: 'BUY',
  sellContainers: 'SELL',
  order: 'ORDER',
  CarbonCreditStore: 'Carbon Credit Store',
  spot_title: ' Spot Price Inquiry',
  capacity_unit: 'm³',
  spot_price_label: 'Total',
  size_type_label: 'Size type',
  pol_label: 'POL',
  pod_label: 'POD',
  spot_search: 'Search',
  spot_order: 'Order',
  spot_desc1: 'Interested or need more details?  ',
  spot_desc2: 'CONTACT US',
  spot_prod_title: 'Production Detail',
  spot_prod_content: 'To learn more about the shipping line, click "Download" to get the container brochure.',
  spot_download:'Download',
  tradingTitle: 'One-stop trading platform   Logistics information visualization',
  freeRegister: 'Register',
  register_premium: 'Premium Register',
  freeLogin: 'Login',
  trading_purchase_label: 'Container Trading',
  trading_space_label: 'Spot Space',
  trading_lease_label: 'Container Leasing',
  trading_management_label: 'Management System',
  trading_purchase_info: 'New、IICL、CW resource globally',
  trading_space_info: 'Premium Product For Ocean',
  trading_lease_info: 'Multiple type for one-way worldwide',
  trading_management_info: ' Manage well all containers in CRM',
  Lease_service:'Marketplace Platform',
  mb_footer_1: '©2020-2023 优配共享集装箱科技（珠海）有限公司',
  mb_footer_2: '网站备案号: 粤ICP备2023078801号-1',

  footer_title1:'Home',
  footer_title2:'About us',
  footer_title3:'UM products',

  footer_list_info1: 'Newest Resources',
  footer_list_info2: 'Marketplace Platform',
  footer_list_info3: 'Container Leasing',
  footer_list_info4: 'Huge NOR Rewards Is On Its Way',

  footer_list_info5: 'Vision & Mission',
  footer_list_info6: 'Reduce CO2 Emission Potencial',
  footer_list_info7: 'Containers and goods in seamless collaboration',
  footer_list_info8: 'From Our Customers',

  copyright: '©2020-2023 UNIT-MATCH | 网站备案号: 粤ICP备2023078801号-1',

}
export const a = {
  abouttitle: 'Vision & Mission',
  missioncontent1: 'Unit-Match, with the data visible platform to connect containers and goods,with data analysis and artificial intelligence.',
  missioncontent2: 'Optimize the container logistic chain by smart matching, increasing productivities, reducing cost and waste, to save earth resources.',
  reduceco2title: 'Reduce CO2 Emission Potencial',
  reduceco2desc1_1: '1,284,798 vehicle',
  reduceco2desc2_1: 'Annual Emission',
  reduceco2desc1_2: '6 Million ton/Year',
  reduceco2desc2_2: 'Annual Emission',
  reduceco2desc1_3: '647,880 households',
  reduceco2desc2_3: 'Annual Emission',
  containergoodstitle: 'Containers and goods in seamless collaboration',
  containergoodsdesccitle1: 'Goods Matching',
  containergoodsdesc1: 'UM connect the best logistic for goods owner and container user,deploy the best matched container on your demand.Take 45 Pallet Widecontainer for example, 30% more loadingcapacity which means 30%saving in cost.',
  containergoodsdesccitle2: 'Containers Matching',
  containergoodsdesc2: 'We have a dedicated team together with the UM platform to serve forcontainer suppliers, to match the best route for any kind ofcontainers with loaded goods for cost and time saving.',
  containergoodsdesccitle3: 'Internet of Things',
  containergoodsdesc3: 'UM Platform will connect with IoT for real-time data communication.We will trace and track all containers starting from pick-up todelivered, securing both the container and loaded goods in globaltransportation.',
  fromcustomerstitle: 'From Our Customers',
  customersname1: 'Container Supplier',
  customersarea1: 'Europe',
  customersmessageinfo1: 'Before we partnered with UM, the one way containers were always ahuge cost to us. more than 90% of containers are made in China, andwe trust the quality of made in China, so that we have the biggestcontainer in Guangdong, China to deliver to our European customers,however, the empty container transportation costed not only thelogistic value, but also it\'s a waste of earth resources. UMprovides us a perfect solution, saving us more than 50% overall costincluding the transportation and management cost against thetraditional way, and they help us to increase our efficacy inlogistic, shorten the turnover cycle to increase a lot to ourprofitability.',
  customersname2: 'Manufacturer',
  customersarea2: 'Guangdong, China',
  customersmessageinfo2: 'We gained a huge benefit from UM\'s digital platform and theirservice, the platform will match bigger container on our demand, butkeep the cost in a compatible level, that means we have more than30% saving of the logistic cost, every cent is counted for amanufacturer!',
  customersmessageinfo3: 'Beside the cost saving, the platform service from Unit-Match is alsoset us as a traditional manufacturer a very good example in digitaltransformation, they help us optimized our logistic process withsignificant productivity increased.'
}
export const h = {
  companyname: 'UNIT·MATCH',
  containersInfo: 'UNIT-MATCH provides an integrated platform to support the digital transformation of the container shipping industry. With the vision of enabling the visualization of container shipping data and empowering the industry’s Net Zero-Emission goal with digital transformation, UM’s one-stop container-matching platform brings container and goods data out of information silos with advanced technology.',
  homenavtitle: 'Share Container Platform',
  signbutton: 'Leasing For Premium Member',
  pickuplocations: 'Pickup locations',
  dropofflocations: 'Dropoff locations',
  containerType: 'Container Type',
  quantity: 'Quantity',
  searchbutton: 'Search',
  formtitle: 'Contact Demo',
  name: 'Name',
  companyName: 'Company Name',
  email: 'E-mail',
  phonenumber: 'Phone number',
  submitbutton: 'Contact Demo',
  container: 'Container',
  supplierofcontainers: 'Supplier of containers',
  detailsbutton: 'View Details',
  updateDate: 'Update Date:'
}
export const c = {
  contacttitle: 'Contact us',
  addresstitle: 'Business Address',
  addresstitle1: 'Headquater',
  addresstinfo1: 'Room 703, Building 2, No. 88, Xiangshan Road, Tangjiawan Town, high tech Zone, Zhuhai, Guangdong, China',
  addresstitle2: 'R&D',
  addresstinfo2: 'Room 722, Office Building, 2819 Chuang Kai Avenue, Huangpu District, Guangzhou, Guangdong, China',
  addresstitle3: 'Marketing Center',
  addresstinfo3: 'LC403, CEEC loft Town, Shenye Shangcheng, No. 5001, Huanggang Road, Huafu street, Futian District, Shenzhen, Guangdong, China',
  addresstitle4: 'Business Center',
  addresstinfo4: '2 Venture Drive #16-21, Vision Exchange, Singapore 608526',
  name: 'Name',
  email: 'E-mail',
  tel: 'Tel',
  message: 'Message',
  button: 'Send'
}
export const t = {
  logout_tip_title: 'Reminder',
  logout_tip_content: 'Are you sure to log out?',
  logout_tip_op_cancel: 'Cancel',
  logout_tip_op_confirm: 'Confirm',

  settings_companyInfo:'Company Profile',
  settings_password:'Password Setting',
  settings_order:'Order Management',
  settings_delegation:'Agency For Sell',
  settings_inquiry:'My Inquiry',
  settings_factoryOrder:'Manufacturer Order',
  settings_depot:'My Depot',
  settings_demand:'My Demand ',
  settings_logOut: 'Log Out',

  mobile_settings_companyInfo:'Company',
  mobile_settings_password:'Password',
  mobile_settings_order:'Order',
  mobile_settings_delegation:'Entrust',
  mobile_settings_inquiry:'Inquiry',
  mobile_settings_factoryOrder:'Manufacturer',
  mobile_settings_depot:'My Depot',
  mobile_settings_demand:'My Demand ',
  mobile_settings_member:'Member',


  register_title: 'User Registration',
  register_companyName: 'Company Name',
  register_companyContact: 'Contact Name',
  register_companyContactPhone: 'Contact Number',
  register_companyContactCountryCode: 'Country Code',
  register_companyContactEmail: 'Contact Email',
  register_password: 'Setting Login Password',
  register_passwordConfirm: 'Confirm Login Password',
  // register_passwordHint: '(The login account is the registered email address)',
  register_companyBusinessLicense: 'Company Business License',
  register_companyAddres: 'Company Website',
  register_emailVerificationCode: 'Email Verification Code',
  register_getEmailVerificationCode: 'Send Code',
  register_emailEmailVerificationCodeResend: 's to resend',
  register_resourceNotification: 'You want to get the updated resource for one or more segment below: ',
  register_fromStaff: 'From Staff',
  register_resource_buying: 'Container Trading',
  register_resource_lease: 'Container Leasing',
  register_resource_spot: 'Spot Space ',
  register_imgUpload: 'Image upload',
  register_confirmRegister: 'Register',
  register_confirmCancel: 'Cancel',
  register_currentEmail_registered: 'The current email has been registered',
  register_to_login: 'Back to login page',
  register_consentCollectionInfo: 'I agree to collect the above information',
  register_consentRead: 'Agree and read',
  register_agreementFile: '《User Agreement》',
  register_privacyFile: '《Privacy Policy》',
  register_disclaimerFile: '《Disclaimers》',
  register_vip_protocolFile: '《Premium Member Services Agreement》',
  register_enterCompanyName: 'Enter company name',
  register_enterCompanyContact: 'Enter contact name',
  register_enterCompanyContactPhone: 'Enter contact number',
  register_enterCompanyContactEmail: 'Enter contact email',
  register_enterPassword: 'Enter login password',
  register_enterPasswordConfirm: 'Enter the login password again',
  register_enterCompanyAddres: 'Enter company Website',
  register_enterEmailVerificationCode: 'Enter verification code',
  register_enterFromStaff: 'Please select from staff',
  register_companyNameNotNull: 'Company name cannot be empty',
  register_companyContactNotNull: 'Contact name cannot be empty',
  register_companyContactPhoneNotNull: 'Contact number cannot be empty',
  register_companyContactEmailNotNull: 'Contact email cannot be empty',
  register_passwordNotNull: 'Login password cannot be empty',
  register_passwordLongVerification: 'Ensure passwords are 8 to 16 characters long',
  register_passwordFormatVerification: 'Your password needs both numbers and letters',
  register_passwordConfirmNotNull: 'Confirm password cannot be empty',
  register_emailVerificationCodeNotNull: 'Verification code cannot be empty',
  register_passwordVerification: 'The password is not the same, please enter it again',
  register_tradingResourceVerification: 'Please select you want to get the latest resource push from one or more of the following sectors',
  register_registerSuccessInfo: 'Registration success,',
  register_registerSuccessToLogin: 'back to login page',

  register_file_serviceAgreement: 'Term of Use',
  register_file_legalNotice: 'Legal Statement',
  register_file_privacyPolicy: 'Privacy Policy',
  register_vip_protocol: 'Premium Member Services Agreement',

  register_file_readFile: 'Read and Agree',


  email_VerificationEmailNotNull: 'Please enter the email address for',
  email_VerificationEmailFormat: 'Please check if your email is correct',

  login_title: 'Welcome!',
  login_account: 'Account',
  login_password: 'Password',
  login_rememberPassword: 'Remember password',
  login_forgetPassword: 'Forgot the password？',
  login_noAccount: 'No account?',
  login_signIpNow: 'Register now',
  login_boutton: 'Login',
  login_enterLoginAccount: 'Enter your account',
  login_enterLoginPassword: 'Enter your password',
  login_accountNotNull: 'Login account cannot be empty',
  login_passwordNotNull: 'Login password cannot be empty',

  oneStopTrading_tradingTitle: 'Trading',
  oneStopTrading_spoTitle: 'Spot',
  oneStopTrading_leasingTitle: 'Leasing',
  oneStopTrading_factoryPurchase: 'Order Fm Manufacturer',
  oneStopTrading_DelegationSale: 'Agency For Sell',
  oneStopTrading_globalDepot: 'Global Depot Network',
  oneStopTrading_ourDemand : 'Demand Globally',

  oneStopTrading_login: 'Log in',
  oneStopTrading_logOut: 'Log out',

  delegation_form_title: 'Agency For Sell',
  delegation_form_size: 'Size',
  delegation_form_type: 'Type',
  delegation_form_condition: 'Condition',
  delegation_form_year: 'Years',
  delegation_form_qty: 'Inventory',
  delegation_form_colorSys: 'Color',
  delegation_form_others: 'Other information',
  delegation_form_pickUpLoc: 'Pickup location',
  delegation_form_price: 'Price($)',
  delegation_form_contaiernImg: 'Image upload',
  delegation_form_minPrice: 'Lowest price($)',
  delegation_form_LastPickUpDate: 'Available pick-up date',
  delegation_form_confirmAdd: 'Send Request',
  delegation_form_confirm: 'Confirm',

  delegation_form_enterSize: 'Select size',
  delegation_form_enterType: 'Select type',
  delegation_form_enterCondition: 'Enter condition',
  delegation_form_enterYear: 'Enter a single year or range of years',
  delegation_form_enterQty: 'Enter inventory',
  delegation_form_enterColorSys: 'Enter color or color number',
  delegation_form_enterHasForklift: 'Forklift',
  delegation_form_enterHasLockBox: 'Lock-Box',
  delegation_form_enterContaiernImg: 'Image upload ',
  delegation_form_enterPickUpLoc: 'Enter pickup location',
  delegation_form_enterPrice: 'Enter price',
  delegation_form_enterMinPrice: 'Enter lowest price  ',
  delegation_form_enterLastPickUpDate: 'Select available pick-up date',

  delegation_title: 'Agency For Sell',
  delegation_title_no: 'Number',
  delegation_title_type: 'Type',
  delegation_title_condition: 'Condition',
  delegation_title_year: 'YOM',
  delegation_title_qty: 'Inventory',
  delegation_title_price: 'Price',
  delegation_title_minPrice: 'Lowest price',
  delegation_title_pickUpLoc: 'Pickup location',
  delegation_title_toDetail: 'Details',

  delegation_delete_title: 'Reminder',
  delegation_delete_info: 'Are you sure to delete this commission information?？',
  delegation_delete_confirm: 'Confirm',
  delegation_delete_cancel : 'Cancel',

  delegation_noDate_title : 'Not Data yet',
  delegation_noDate_info : 'You haven\'t added any commissions yet',
  delegation_noDate_toContainer : 'Return to Homepage ',

  delegation_detail_title : 'Agency For Sell Details',
  delegation_detail_no: 'Number',
  delegation_detail_type: 'Container',
  delegation_detail_condition: 'Condition',
  delegation_detail_year: 'Years',
  delegation_detail_qty: 'Inventory',
  delegation_detail_price: 'Price',
  delegation_detail_minPrice: 'Lowest price',
  delegation_detail_pickUpLoc: 'Pickup location',
  delegation_detail_color: 'Color',
  delegation_detail_forklift: 'Forklift',
  delegation_detail_lockBox: 'Lock-Box',
  delegation_detail_validDate: 'Available pickup date',
  delegation_detail_img: 'Container image',
  delegation_detail_edit: 'edit',
  delegation_detail_delete: 'delete',



  inquiry_subject: 'My Inquiry',
  inquiry_title_consultNo: 'Inquiry No',
  inquiry_title_sizeType: 'Type',
  inquiry_title_containerStatus: 'Condition',
  inquiry_title_Qty: 'Quantity',
  inquiry_title_LatestPickupDate: 'Latest pickup date',
  inquiry_title_pickupLoc: 'Pickup location',

  inquiry_form_subject: 'Make An Inquiry',
  inquiry_form_size: 'Size',
  inquiry_form_placeholder_size: 'Please Select Size',
  inquiry_form_type: 'Type',
  inquiry_form_placeholder_type: 'Please Select Type',
  inquiry_form_condition: 'Condition',
  inquiry_form_placeholder_condition: 'Please Select Condition',
  inquiry_form_qty: 'Quantity',
  inquiry_form_placeholder_qty: 'Please input Quantity',
  inquiry_form_pickupLoc: 'Pick-up location',
  inquiry_form_placeholder_pickupLoc: 'Please input Pick-up location',
  inquiry_form_LatestPickupDate: 'Latest pickup date',
  inquiry_form_placeholder_LatestPickupDate: 'Please Select Latest pickup date',
  inquiry_form_confirm: 'Confirm',

  inquiry_delete_tip_subject: 'Reminder',
  inquiry_delete_tip_msg: 'Are you sure to delete this inquiry information?？',
  inquiry_delete_confirm: 'Confirm',
  inquiry_delete_cancel: 'Cancel',

  inquiry_empty_data: 'Not Data yet',
  inquiry_empty_msg1: 'You haven\'t added any inquiries yet',
  inquiry_empty_msg2: 'Return to Homepage',

  inquiry_edit: 'Edit',
  inquiry_delete: 'Delete',

  trading_order_subject: 'Order Management',
  trading_order_createDate: 'Order Date',
  trading_order_orderNo: 'Order No',
  trading_order_resourceNo: 'Resource No',
  trading_order_containerCondition: 'Condition',
  trading_order_total: 'Total',
  trading_order_pickupLoc: 'LOC',
  trading_order_reorder: 'Re-order',
  trading_order_cancel: 'Cancel Order',
  trading_order_unpaid: 'Unpaid',
  trading_order_paid: 'Paid',
  trading_order_cancel_send_subject: 'Email has been sent successfully',
  trading_order_cancel_send_context: 'Thank you for your waiting, we will confirm the cancellation soon!',

  trading_order_downloadVoucher: 'Download',
  trading_order_viewVoucher: 'View',

  trading_order_empty: 'Not Data yet',
  trading_order_empty_msg1: 'You haven\'t added any orders yet',
  trading_order_empty_msg2: 'Return to Homepage',

  trading_cancel_order_subject: 'Reminder',
  trading_cancel_order_msg: 'Are you sure to delete this commission information??',
  trading_cancel_order_cancel: 'Cancel',
  trading_cancel_order_confirm: 'Confirm',

  trading_order_upload_pay_subject: 'Upload Remittance Voucher',
  trading_order_upload_pay_label: 'Screenshot upload',
  trading_order_upload_pay_text: 'Image upload',
  trading_order_upload_pay_cancel: 'Cancel',
  trading_order_upload_pay_confirm: 'Upload',

  trading_order_detail_subject: 'Order Details',
  trading_order_detail_orderDate: 'Order Date',
  trading_order_detail_orderNo: 'Order No',
  trading_order_detail_sizeType: 'Container',
  trading_order_detail_condition: 'Condition',
  trading_order_detail_year: 'Years',
  trading_order_detail_colorSys: 'Color',
  trading_order_detail_hasForklift: 'Forklift',
  trading_order_detail_hasLockBox: 'Lock-Box',
  trading_order_detail_qty: 'Quantity',
  trading_order_detail_price: 'Price',
  trading_order_detail_pickupLoc: 'Pickup location',
  trading_order_detail_pic: 'Container Image',
  trading_order_detail_total: 'Total',

  resource_search_title: ' Existing Resource',
  resource_search_placeholder_size: 'Select size',
  resource_search_placeholder_type: 'Select type',
  resource_search_placeholder_pickupLoc: 'Enter pick-up location',
  resource_search_button: 'Search',
  resource_container_qty: 'Inventory',
  resource_container_updateDate: 'Updated',
  resource_container_pickUpLoc: 'Location',
  resource_container_validDate: 'EXP',
  resource_container_purchase: 'Buy',
  resource_container_details: 'Details',
  resource_container_noData: 'Not Data yet',
  resource_container_more: 'Can\'t find what you need?',
  resource_container_inquiryMore: 'Ask for More Resource',
  resource_container_AgencySell: 'Agency For Sell',
  resource_container_factoryOrder: 'Order Fm Manufacturer',
  resource_container_BrandNew: 'Brand New',
  resource_container_IICL: 'IICL',
  resource_container_CW: 'CW',
  resource_container_ASIS: 'AS-IS',

  container_detail_title: 'Container Resource Details',
  container_detail_no: 'Number',
  container_detail_vaildDate: 'EXP',
  container_detail_sizeType: 'Container',
  container_detail_conditon: 'Condition',
  container_detail_year: 'Years',
  container_detail_qty: 'Inventory',
  container_detail_pickLoc: 'Pickup location',
  container_detail_color: 'Color',
  container_detail_forklift: 'Forklift',
  container_detail_lockBox: 'Lock-Box',
  container_detail_price: 'Price',
  container_detail_img: 'Container Image',
  container_detail_purchase: 'Buy',

  container_purchase_title: 'Transaction Confirmation',
  container_purchase_no: 'Order Number',
  container_purchase_resource_no: 'Resource Number',
  container_purchase_condition: 'Condition',
  container_purchase_pickUpLoc: 'Location',
  container_purchase_tatalAmount: 'Total',
  container_purchase_price: 'Price',
  container_purchase_confirmButyon: 'Confirm',


  factory_form_directTitle:'Single Order',
  factory_form_collageTitle:'Group Order',
  factory_form_size:'Size',
  factory_form_condition:'Type',
  factory_form_qty:'Quantity',
  factory_form_color:'Color number',
  factory_form_forklift:'Forklift',
  factory_form_lockBox:'Lock-Box',
  factory_form_logoRequest:'Company name and logo',
  factory_form_pickUpLoc:'Pickup location',
  factory_form_lastPickUpDate:'Latest pickup date',
  factory_form_price:'Target price($)',
  factory_form_other:'Other requirements',
  factory_form_confrimBoutton:'Confirm',
  factory_form_enterSize:'Select size',
  factory_form_enterCondition:'Select type',
  factory_form_enterQty:'Enter quantity',
  factory_form_enterColor:'Enter color or color number',
  factory_form_enterForklift:'Enter forklift',
  factory_form_enterLockBox:'Enter lock-box',
  factory_form_enterlogoRequest:'Enter logo',
  factory_form_enterPickUpLoc:'Enter pickup location',
  factory_form_enterLastPickUpDate:'Select latest pickup date',
  factory_form_enterPrice:'Enter target price',
  factory_form_enterOther:'If you have other requirements, please fill in here…',

  factory_order_title: 'Manufacturer Order',
  factory_order_no: 'Number',
  factory_order_type: 'Order Type',
  factory_order_sizeType: 'Type',
  factory_order_qty: 'Quantity',
  factory_order_price: 'Target Price',
  factory_order_lastPickUpDate: 'Latest pickup date',
  factory_order_pickLoc: 'Pickup Location',
  factory_order_toDetail: 'Details',
  factory_order_noOrderTitle: 'Not Data yet',
  factory_order_noOrderInfo: 'You haven\'t added any orders yet',
  factory_order_toIndex: 'Return to Homepage ',
  factory_order_deleteTitle: 'Are you sure to delete this orde?',
  factory_order_deleteConfirm: 'Confirm',
  factory_order_deleteCancel: 'Cancel',
  factory_order_delete: 'Delete',
  factory_order_edit: 'Edit',

  factory_orederDetail_title:'Manufacturer Order Details',


  form_validate_error_tip_size: 'Size can\'t be empty',
  form_validate_error_tip_type: 'Type can\'t be empty',
  form_validate_error_tip_condition: 'Condition can\'t be empty',
  form_validate_error_tip_qty: 'Quantity can\'t be empty',
  form_validate_error_tip_pickupLoc: 'Pickup Location can\'t be empty',
  form_validate_error_tip_colorNumber: 'Color number can\'t be empty',
  form_validate_error_tip_latestPickupDate: 'Last Pickup date can\'t be empty',
  form_validate_error_tip_validate: 'Date of Expiry can\'t be empty',
  form_validate_error_tip_year: 'Year can\'t be empty',
  form_validate_error_tip_price: 'Price can\'t be empty',
  form_validate_error_tip_minPrice: 'Lowest Price can\'t be empty',

  profile_info_title: 'Company Profile',
  profile_info_companyName: 'Company Name',
  profile_info_contacts: 'Contact Name',
  profile_info_telephone: 'Contact Number',
  profile_info_email: 'Contact Email',
  profile_info_addres: 'Company Website',
  profile_info_resourcePromotion: 'You want to get the updated resource for one or more segment below: ',
  profile_info_businessLicense: 'Company Business License',
  profile_info_edit: 'Edit',
  profile_info_reUpload: 'Re-upload',
  profile_info_save: 'Save',
  profile_info_cancel: 'Cancel',

  profile_form_info_title: 'Modify Company Profile',
  profile_form_info_email: 'Contact Email',
  profile_form_info_emailVerificationCode: 'Verification Code',
  profile_form_info_contactsName: 'Contact Name',
  profile_form_info_telephone: 'Contact Number',
  profile_form_info_addres: 'Company Address',
  profile_form_info_resourcePromotion: 'You want to get the updated resource for one or more segment below: ',
  profile_form_info_containerTrading: 'Container Trading',
  profile_form_info_containerLeasing: 'Container Leasing',
  profile_form_info_spotSpace: 'Spot Space',

  profile_form_contactsNameNotNull: 'Contact name can\'t be empty',
  profile_form_telephoneNotNull: 'Contact number can\'t be empty',
  profile_form_emailNotNull: 'Contact email can\'t be empty',
  profile_form_emailVerificationCodeNotNull: 'email verification code can\'t be empty',
  profile_form_businessLicenseNotNull: 'Business license can\'t be empty',

  profile_form_enterEmail: 'Enter contact email',
  profile_form_enterVerificationCode: 'Enter verification code',
  profile_form_enterContactsName: 'Enter contact name',
  profile_form_enterTelephone: 'Enter contact number',
  profile_form_enteraddres: 'Enter company address',

  profile_form_uploadLicense_title: 'Upload Company Business License',
  profile_form_uploadLicense_prompt: 'Image upload',
  profile_form_uploadLicense_uploadImg: 'Image upload',
  profile_form_uploadLicense_upload: 'Upload',
  profile_form_uploadLicense_cancel: 'Cancel',

  profile_form_getEmailVerificationCode: 'Send Code',
  profile_form_emailEmailVerificationCodeResend: 's to resend',

  send_confirm_tip_inquiry_subject: 'Email has been sent successfully',
  send_confirm_tip_inquiry_content: 'Thank you for your inquiry, we will reply you with the latest price by email',

  send_confirm_tip_entrust_subject: 'Email has been sent successfully',
  send_confirm_tip_entrust_content: 'Thank you for your commission, we will contact you by email',

  send_confirm_tip_factoryOrder_subject: 'Email has been sent successfully',
  send_confirm_tip_factoryOrder_content: 'Thank you for your order, we will contact you by email',

  send_confirm_tip_order_subject: 'Email has been sent successfully',
  send_confirm_tip_order_content: 'Thank you for your confirmation, we will contact you by email',

  passManage_info_title: 'Password Setting',
  passManage_form_currentPassWord: 'Current Password',
  passManage_form_newPassWord: 'New Password',
  passManage_form_confirmPassWord: 'Confirm the New Password',

  passManage_form_enterCurrentPassWord: 'Enter current login password',
  passManage_form_enterNewPassWord: 'Enter new password',
  passManage_form_enterConfirmPassWord: 'Enter the new password again',

  passManage_form_confirmBoutton: 'Confirm',
  passManage_form_cancelBoutton: 'Cancel',
  passManage_form_currentPassWordNotNull: 'Current password can\'t be empty',
  passManage_form_newPassWordNotNull: 'New password can\'t be empty',
  passManage_form_confirmPassWordNotNull: 'Confirm the New Password',
  passManage_form_confirmPassWordInconsistent: 'The password is not the same, please enter it again',

  contact_IT: 'Operation Fail. Please contact with developer. Thanks!',

  customerService_wechat_text: 'WeChat',
  customerService_whatsApp_text: 'WhatsApp',

  customerService_domesticSales: 'Domestic Sales Contact',
  customerService_southeastAsianSales: 'Southeast Asia Sales Contact',
  customerService_europeAndAmericaSales: 'European and American regional sales contact',


  passReset_info_title: 'Forget the Password',
  passReset_form_email: 'Contact Email',
  passReset_form_emailVerificationCode: 'Verification Code',
  passReset_form_newPassword: 'New Password',
  passReset_form_confirmNewPassword: 'Confirm the new password',

  passReset_form_enterEmail: 'Enter contact email',
  passReset_form_enterVerificationCode: 'Enter verification code',
  passReset_form_enterNewPassword: 'Enter new password',
  passReset_form_enterConfirmNewPassword: 'Enter the new password again',

  passReset_form_emailNotNull: 'Contact email can\'t be empty',
  passReset_form_emailVerificationCodeNotNull: 'Email verification code can\'t be empty',
  passReset_form_newPasswordNotNull: 'New password can\'t be empty',
  passReset_form_confirmNewPasswordNotNull: 'Enter the new password again',
  passReset_form_confirmPassWordInconsistent: 'The password is not the same, please enter it again',

  passReset_form_confirmBoutton: 'Confirm',
  passReset_form_cancelBoutton: 'Cancel',

  passReset_form_getEmailVerificationCode: 'Send Code',
  passReset_form_emailEmailVerificationCodeResend: 's to resend',

  service_chat_title:'UMY Online Customer Service | ',
  mobile_service_chat_title:'Customer Service | ',
  service_chat_item_title:'Please select the business',
  service_chat_item_trading:'Trading',
  service_chat_item_spot:'Spot Space',
  mobile_service_chat_item_spot:'Spot',
  service_chat_item_leasing:'Leasing',
  service_chat_enter:'Please enter your question…',

  upgrade_member_title: 'Upgrade to Premium',
  mobile_upgrade_member_title: 'My Premium',
  premium_member_renew: 'Renew',
  premium_member_title: 'Premium',
  premium_member_equity: 'A total of 6 exclusive membership benefits',
  premium_member_validity: 'Exp',
  overdue_member_text: 'Expired',
  premium_member_surplus: 'Remaining',
  premium_member_day: 'days',
  premium_member_equity_existing: 'Existing Benefits',
  premium_member_equity_handling: 'No transaction fees',
  premium_member_equity_account: 'Provide 1 member account',
  premium_member_equity_limit: 'No limitation on container leasing volume',
  premium_member_equity_box: 'Exclusive UMIVERSE Membership Box',
  premium_member_equity_preferential: 'Exclusive Container Fee Refund Plan',
  premium_member_equity_integral: 'UM Carbon Credits Exchange Rewards',
  premium_member_equity_ambassador: 'Eligible to be selected as the UM Emissions Reduction Ambassador in the annual ESG Awards',
  premium_member_start_leasing: 'Start leasing',


  premium_member_renew_title: 'Renewal of Premium',
  premium_member_upgrade_title: 'Upgrade to Premium',
  premium_member_renew_text: 'Are you sure you need to renew the Premium Member?',
  premium_member_upgrade_text: 'Are you sure you need to upgrade to the Premium Member?',
  premium_member_confirm: 'Confirm',
  premium_member_cancel: 'Cancel',
  premium_member_success_text: 'Under review,and our colleagues will contact you later.',

  demand_title: 'Global Demand',
  demand_add_text: 'Release',
  demand_resource_no: 'Number',
  demand_resource_container: 'Container',
  demand_resource_condition: 'Condition',
  demand_resource_quantity: 'Quantity',
  demand_resource_pickUpDate: 'Pickup date',
  demand_resource_pickUpLocation: 'Pickup location',
  demand_resource_detail: 'Details',

  demand_empty_title: 'Not Data yet',
  demand_empty_text: 'Can\'t find what you demand?',
  demand_my_empty_text: 'Can\'t find what you demand? You can',
  demand_empty_return: 'Return to Homepage',
  demand_my_empty_return: 'demand entry',
  demand_yes_text: 'Yes',
  demand_no_text: 'No',
  demand_noSpecified_text: 'No Specified',


  demand_add_resource_title: 'Demand Entry',
  demand_add_resource_size: 'Size',
  demand_add_resource_type: 'Type',
  demand_add_resource_quantity: 'Quantity',
  demand_add_resource_condition: 'Condition',
  demand_add_resource_forkliftGroove: 'Forklift',
  demand_add_resource_lockBox: 'Lock-Box',
  demand_add_resource_lastPickUpDate: 'Pickup date',
  demand_add_resource_pickUpLocation: 'Pickup location',
  demand_add_resource_remark: 'Other requirements',
  demand_add_resource_confirm: 'Release',
  demand_add_resource_cancel: 'Cancel',
  demand_add_resource_success: 'Released successful',

  demand_resource_select_yes: 'Yes',
  demand_resource_select_no: 'No',
  demand_resource_select_noSpecified: 'No Specified',

  demand_form_validate_error_tip_size: 'Size can\'t be empty',
  demand_form_validate_error_tip_type: 'Type can\'t be empty',
  demand_form_validate_error_tip_quantity: 'Quantity can\'t be empty',
  demand_form_validate_error_tip_condition: 'Condition can\'t be empty',
  demand_form_validate_error_lastPickUpDate: 'Pickup date can\'t be empty',
  demand_form_validate_error_tip_pickUpLocation: 'Pickup location can\'t be empty',

  demand_add_resource_enter_size: 'Select size',
  demand_add_resource_enter_type: 'Select type',
  demand_add_resource_enter_quantity: 'Enter quantity',
  demand_add_resource_enter_condition: 'Select condition',
  demand_add_resource_enter_lastPickUpDate: 'Select pickup date',
  demand_add_resource_enter_ickUpLocation: 'Enter pickup location',
  demand_add_resource_enter_remark: 'Enter your requirements',

  demand_detail_resource_title: 'Demand Details ',
  demand_detail_resource_no: 'Number',
  demand_detail_resource_type: 'Container',
  demand_detail_resource_quantity: 'Quantity',
  demand_detail_resource_condition: 'Condition',
  demand_detail_resource_forkliftGroove: 'Forklift',
  demand_detail_resource_lockBox: 'Lock-Box',
  demand_detail_resource_lastPickUpDate: 'Pickup date',
  demand_detail_resource_pickUpLocation: 'Pick-up location',
  demand_detail_resource_remark: 'Other requirements',
  demand_detail_resource_provide: 'I Can Supply ',

  demand_detail_resource_provide_title: 'Email has been sent successfully',
  demand_detail_resource_provide_text: 'Thank you, we will contact you by email',

  demand_info_title: 'My Demand',
  demand_cancel_resource_success: 'Revoke successful',
  demand_info_cancel_content: 'Are you sure to revoke this demand information?',
  demand_info_cancel_confirm: 'Confirm',
  demand_info_cancel: 'Cancel',

  demand_info_revoke_text: 'revoke',
  demand_info_status_cancel: 'Revoked',
  demand_info_status_complete: 'Completed',

}
export const s = {

  enter_spotSpace_search_departure: 'Select Departure',
  enter_spotSpace_search_route: 'Select Route',
  enter_spotSpace_search_destination: 'Select Destination',
  enter_spotSpace_search_button: 'Search',
  spotSpace_search_prompt: 'Can\'t find what you need? ',
  spotSpace_search_toInquiry: ' Make an inquiry',

  spotSpace_resource_noOrderTitle: 'No data yet',
  spotSpace_resource_noOrderInfo: 'Didn\'t find the resource you wanted?',
  spotSpace_resource_toIndex: 'Ask for more resource',

  spotSpace_inquiry_form_title :'Make An Inquiry',
  spotSpace_inquiry_form_type :'Type',
  spotSpace_inquiry_form_dangerous :'Hazardous Cargo',
  spotSpace_inquiry_form_departure :'Departure',
  spotSpace_inquiry_form_destination :'Destination',
  spotSpace_inquiry_form_container :'Container',
  spotSpace_inquiry_form_quantity :'Quantity',
  spotSpace_inquiry_form_size :'Size',
  spotSpace_inquiry_form_containerType :'Type',
  spotSpace_inquiry_form_shippingTerms :'Terms',
  spotSpace_inquiry_form_cargoReadyDate :'Cargo Ready Date',
  spotSpace_inquiry_form_commodity :'Commodity',
  spotSpace_inquiry_form_cargoNetWeight :'Cargo Net Weight(KG)',
  spotSpace_inquiry_form_cargoVolume :'Cargo Volume(m³)',
  spotSpace_inquiry_form_whetherToAcceptTransfer :'Whether to accept transfer',
  spotSpace_inquiry_form_confirm :' Send Request',
  spotSpace_send_confirm_tip_inquiry_subject : 'Email has been sent successfully',
  spotSpace_send_confirm_tip_inquiry_content : 'Thank you for your inquiry, we will reply you with the latest price by email',
  spotSpace_inquiry_form_edit: 'Edit',

  enter_inquiry_form_departure : 'Enter departure',
  enter_inquiry_form_destination : 'Enter destination',
  enter_inquiry_form_container : 'Enter container',
  enter_inquiry_form_quantity : 'Enter quantity',
  enter_inquiry_form_size : 'Select Size',
  enter_inquiry_form_type : 'Select Type',
  enter_inquiry_form_cargoReadyDate : 'Select the cargo ready date',
  enter_inquiry_form_commodity: 'Enter the commodity',
  enter_inquiry_form_cargoNetWeight: 'Enter the cargo net weight',
  enter_inquiry_form_cargoVolume: 'Enter the cargo volume',


  spotSpace_inquiry_form_departureVerification: 'Departure cannot be empty',
  spotSpace_inquiry_form_destinationVerification: 'Destination cannot be empty',
  spotSpace_inquiry_form_containerVerification: 'Container cannot be empty',
  spotSpace_inquiry_form_quantityVerification: 'Quantity cannot be empty',
  spotSpace_inquiry_form_shippingTermsVerification: 'Please select terms',
  spotSpace_inquiry_form_cargoReadyDateVerification: 'Cargo ready date cannot be empty',
  spotSpace_inquiry_form_commodityVerification: 'Commodity cannot be empty',
  spotSpace_inquiry_form_cargoNetWeightVerification: 'Cargo net weight cannot be empty',
  spotSpace_inquiry_form_cargoVolumeVerification: 'Cargo volume  be empty',
  spotSpace_inquiry_form_acceptTransferVerification: 'Please select whether to accept transfer',
  spotSpace_inquiry_form_containerTypeVerification: 'Please ensure to add at least one container type',
  spotSpace_inquiry_form_containerSizeVerification: 'Please confirm that the corresponding container type has been selected for all sizes',

  spotSpace_full_container_load: 'Full Container Load',
  spotSpace_resource_exp: 'EXP',
  spotSpace_resource_departure: 'Departure',
  spotSpace_resource_destination: 'Destination',
  spotSpace_resource_transitTime: 'Transit Time',
  spotSpace_resource_sailingDate: 'Sailing Date',
  spotSpace_resource_price: 'Freight',
  spotSpace_resource_buy: 'Book',
  spotSpace_resource_viewDetails: 'View details',
  spotSpace_resource_transitType_transit: 'Transit',
  spotSpace_resource_transitType_direct: 'Direct',

  spotSpace_resource_type_coc: 'carrier own container',
  spotSpace_resource_type_um_coc: 'UM container with carrier space',
  spotSpace_resource_type_soc: 'carrier SOC slot only',
  spotSpace_resource_type_empty: 'Empty reposition',
  spotSpace_resource_type_charter: 'Charter',

  spotSpace_resource_detail_title: 'Resource Details',
  spotSpace_resource_detail_resourceNo: 'Resource number',
  spotSpace_resource_detail_exp: 'EXP',
  spotSpace_resource_detail_transportationMode: 'Transportation Mode',
  spotSpace_resource_detail_shippingTerms: 'Terms',
  spotSpace_resource_detail_departure: 'Departure',
  spotSpace_resource_detail_destination: 'Destination',
  spotSpace_resource_detail_shippingLine: 'Trade Route',
  spotSpace_resource_detail_vessel: 'Service Code / Vessel & Voyage',
  spotSpace_resource_detail_carrier: 'Carrier',
  spotSpace_resource_detail_sailingDate: 'Sailing Date',
  spotSpace_resource_detail_transitTime: 'Transit Time',
  spotSpace_resource_detail_shippingSpace: 'Allocation',
  spotSpace_resource_detail_closingDate: 'Closing Date',
  spotSpace_resource_detail_transit: 'Transit',
  spotSpace_resource_detail_transitPort: 'Transit Port',
  spotSpace_resource_detail_pod: 'Discharge Port',
  spotSpace_resource_detail_destinationFreeDays: 'Freetime(days)',
  spotSpace_resource_detail_remark: 'Other Requirements Fm Carrier',
  mb_spotSpace_resource_detail_remark1: 'Remark',
  mb_spotSpace_resource_detail_remark2: '(Other requirements of the transportation company)',
  spotSpace_resource_detail_price: 'Freight',
  spotSpace_resource_detail_additionalCharges: 'Surcharges',
  spotSpace_resource_detail_purchaseButton: 'Book',


  spotSpace_inquiry_order_title: 'My Inquiry',
  spotSpace_inquiry_order_number: 'Number',
  spotSpace_inquiry_order_type: 'Type',
  spotSpace_inquiry_order_departure: 'Departure',
  spotSpace_inquiry_order_destination: 'Destination',
  spotSpace_inquiry_order_container: 'Type/Quantity',
  spotSpace_inquiry_order_quantity: 'Total',
  spotSpace_inquiry_order_details: 'Details',

  spotSpace_inquiry_order_delete_tip_subject: 'Reminder',
  spotSpace_inquiry_order_delete_tip_content: 'Are you sure to delete this inquiry information?',
  spotSpace_inquiry_order_delete_tip_confirm: 'Confirm',
  spotSpace_inquiry_order_delete_tip_cancel: 'Cancel',

  spotSpace_inquiry_order_noOrderTitle: 'No inquiry yet',
  spotSpace_inquiry_order_noOrderInfo: 'You haven\'t added any inquiries yet',
  spotSpace_inquiry_order_toIndex:'Return to homepage',

  spotSpace_inquiry_detail_title: 'Inquiry details',
  spotSpace_inquiry_detail_shippingTerms: 'Terms',
  spotSpace_inquiry_detail_departure: 'Departure',
  spotSpace_inquiry_detail_destination: 'Destination',
  spotSpace_inquiry_detail_type: 'Type',
  spotSpace_inquiry_detail_container: 'Container',
  spotSpace_inquiry_detail_quantity: 'Quantity',
  spotSpace_inquiry_detail_cargoReadyDate: 'Cargo Ready Date',
  spotSpace_inquiry_detail_commodity: 'Commodity',
  spotSpace_inquiry_detail_cargoNetWeight: 'Cargo Net Weight (KG)',
  spotSpace_inquiry_detail_cargoVolume: 'Cargo Volume (m³)',
  spotSpace_inquiry_detail_dangerous: 'Dangerous',
  spotSpace_inquiry_detail_yes: 'Yes',
  spotSpace_inquiry_detail_no: 'No',
  spotSpace_inquiry_detail_whetherToAcceptTransfer: 'Whether to accept transfer',
  spotSpace_inquiry_detail_containerTitle: 'Type/Quantity',
  spotSpace_inquiry_detail_deleteButton: 'Delete',
  spotSpace_inquiry_detail_editButton: 'Edit',



  spotSpace_purchase_detail_title: 'Shipment Confirmation',
  spotSpace_purchase_detail_resourceNumber: 'Resource number',
  spotSpace_purchase_detail_exp: 'EXP',
  spotSpace_purchase_detail_transportationMode: 'Transportation Mode',
  spotSpace_purchase_detail_shippingTerms: 'Terms',
  spotSpace_purchase_detail_departure: 'Departure',
  spotSpace_purchase_detail_destination: 'Destination',
  spotSpace_purchase_detail_shippingLine: 'Trade Route',
  spotSpace_purchase_detail_vessel: 'Service Code / Vessel & Voyage',
  spotSpace_purchase_detail_carrier: 'Carrier',
  spotSpace_purchase_detail_sailingDate: 'Sailing Date',
  spotSpace_purchase_detail_transitTime: 'Transit Time',
  spotSpace_purchase_detail_shippingSpace: 'Allocation',
  spotSpace_purchase_detail_closingDate: 'Closing Date',
  spotSpace_purchase_detail_transit: 'Transit',
  spotSpace_purchase_detail_transitPort: 'Transit Port',
  spotSpace_purchase_detail_pod: 'Discharge Port',
  spotSpace_purchase_detail_destinationFreeDays: 'Freetime(days)',
  spotSpace_purchase_detail_additional: 'Surcharges',
  spotSpace_purchase_detail_doc: 'DOC',
  spotSpace_purchase_detail_thc: 'THC',
  spotSpace_purchase_detail_remark: 'Other Requirements Fm Carrier',
  spotSpace_purchase_detail_hazardous: 'Hazardous Cargo',
  spotSpace_purchase_detail_container: 'Type and Quantity',
  spotSpace_purchase_detail_expenseDetail: 'Expense Detail',
  spotSpace_purchase_detail_price: 'Freight',
  spotSpace_purchase_detail_items: 'items',
  spotSpace_purchase_detail_total: 'Total',
  spotSpace_purchase_detail_confirm: 'Confirm',
  spotSpace_purchase_detail_hazardousYes: 'Yes',
  spotSpace_purchase_detail_hazardousNo: 'No',

  spotSpace_send_confirm_tip_order_subject: 'Email has been sent successfully',
  spotSpace_send_confirm_tip_order_content: 'Thank you for your confirmation, we will contact you by email',

  spotSpace_send_fail_tip_order: 'Order placement failed.',
  spotSpace_purchase_detail_quantityRemind:'Please confirm the quantity you need to purchase',
  spotSpace_purchase_detail_containerRemind:'Please select the type and quantity of containers to purchase',

  spotSpace_purchase_detail_dangerous_tip_info:'Dangerous goods currently do not support placing orders. You can inquire about prices',
  spotSpace_purchase_detail_dangerous_tip_inquiry:'Make an inquiry',

  spotSpace_purchase_detail_tip_confirm_order_title:'Confirm order information',
  spotSpace_purchase_detail_tip_confirm_order_button:'Confirm',
  spotSpace_purchase_detail_tip_cancel_order_title:'Cancel',

  spotSpace_order_title: 'Order Management',
  spotSpace_order_orderDate: 'Order date',
  spotSpace_order_number: 'Order No',
  spotSpace_spot_resource_no: 'Resource No',
  spotSpace_order_departure: 'Departure',
  spotSpace_order_destination: 'Destination',
  spotSpace_order_container: 'Type/Quantity',
  spotSpace_order_price: 'Freight',
  spotSpace_order_quantity: 'Volume',
  spotSpace_order_total: 'Total',
  spotSpace_order_includesSurcharge: '(Includes surcharge)',
  spotSpace_order_operate: 'Operate',
  spotSpace_order_orderFileNotNull: 'The uploaded file cannot be empty',

  spotSpace_order_noOrderTitle: 'No oders yet',
  spotSpace_order_noOrderInfo: 'You haven\'t added any orders yet',
  spotSpace_order_toIndex: 'Return to homepage',


  spotSpace_order_detail_title: 'Order Details',
  spotSpace_order_detail_OrderDate: 'Order date',
  spotSpace_order_detail_number: 'Number',
  spotSpace_order_detail_transportationMode: 'Transportation Mode',
  spotSpace_order_detail_shippingTerms: 'Terms',
  spotSpace_order_detail_departure: 'Departure',
  spotSpace_order_detail_destination: 'Destination',
  spotSpace_order_detail_shippingLine: 'Trade Route',
  spotSpace_order_detail_vessel: 'Service Code / Vessel & Voyage',
  spotSpace_order_detail_carrier: 'Carrier',
  spotSpace_order_detail_sailingDate: 'Sailing Date',
  spotSpace_order_detail_transitTime: 'Transit Time',
  spotSpace_order_detail_shippingSpace: 'Allocation',
  spotSpace_order_detail_closingDate: 'Closing Date',
  spotSpace_order_detail_transit: 'Transit',
  spotSpace_order_detail_transitPort: 'Transit Port',
  spotSpace_order_detail_pod: 'Discharge Port',
  spotSpace_order_detail_destinationFreeDays: 'Freetime(days)',
  spotSpace_order_cancel_send_subject: 'Email has been sent successfully',
  spotSpace_order_cancel_send_context: 'Thank you for your waiting, we will confirm the cancellation soon!',

  spotSpace_order_detail_additionalCharges: 'Surcharges',
  spotSpace_order_detail_doc: 'DOC',
  spotSpace_order_detail_thc: 'THC',
  spotSpace_order_detail_remark: 'Other Requirements Fm Carrier',
  spotSpace_order_detail_hazardousCargo: 'Hazardous Cargo',
  spotSpace_order_detail_isHazardousCargo: 'Yes',
  spotSpace_order_detail_NotHazardousCargo: 'No',
  spotSpace_order_detail_container: 'Type and Quantity',
  spotSpace_order_detail_expenseDetail: 'Expense Detail',
  spotSpace_order_detail_price: 'Freight',
  spotSpace_order_detail_items: 'items',
  spotSpace_order_detail_total: 'Total',

  spotSpace_order_detail_alreadyPaid: 'Paid',
  spotSpace_order_detail_unpaid: 'Unpaid',
  spotSpace_order_detail_cancelOrder: 'Cancel Order',
  spotSpace_order_detail_reorder: 'Re-order',

  spotSpace_order_detail_downloadVoucher:'Download',
  spotSpace_order_detail_viewVoucher:'View',

  spotSpace_cancel_order_subject: 'Reminder',
  spotSpace_cancel_order_msg: 'Are you sure to delete this commission information?',
  spotSpace_cancel_order_cancel: 'Cancel',
  spotSpace_cancel_order_confirm : 'Confirm',

  spotSpace_order_upload_pay_subject: 'Upload Remittance Voucher',
  spotSpace_order_upload_pay_label: 'Screenshot upload',
  spotSpace_order_upload_pay_text: 'Image upload',
  spotSpace_order_upload_pay_cancel: 'Cancel',
  spotSpace_order_upload_pay_confirm: 'Upload',


}
export const l= {
  leasing_search_enter_size: 'Size',
  leasing_search_enter_type: 'Type',
  leasing_search_enter_pickUpLocation: 'Pick Up Location',
  leasing_search_enter_dropOffLocation: 'Drop Off Location',
  leasing_search_button: 'Search',

  leasing_search_empty_text: 'Didn\'t find the container resource you wanted?',
  leasing_search_inquiry_button: ' Ask for More Resource ',
  leasing_mobile_search_empty_text: 'Can\'t find what you need?',
  leasing_mobile_search_inquiry_button: ' Ask for More Resource ',

  leasing_resource_info_pickUpLocation: 'Pickup Location',
  leasing_resource_info_dropOffLocation: 'Dropoff Location',
  leasing_resource_info_containerType: 'Container',
  leasing_resource_info_leaseType: 'Lease Type',
  leasing_resource_info_puc: 'PUC/Unit (USD)',
  leasing_resource_info_credit: 'Credit/Unit(USD)',
  leasing_mobile_resource_info_puc: 'PUC',
  leasing_mobile_resource_info_credit: 'Credit',
  leasing_resource_info_freeDays:'Freedays',
  leasing_resource_info_day:'Days',
  leasing_resource_info_perDiem:'Per Diem(USD/Unit/Day)',
  leasing_mobile_resource_info_perDiem:'Per Diem',
  leasing_resource_info_quantity:'Quantity',
  leasing_resource_sendRequest_button:'Apply',
  leasing_resource_viewDetail_button:'View details',

  leasing_resource_no_data_title: 'No data yet',
  leasing_resource_no_data_text: 'Didn\'t find the container resource you wanted?',
  leasing_resource_no_data_more: 'Ask for more resource',

  leasing_detail_header_title: 'Details',
  leasing_detail_info_number: 'Resource number',
  leasing_detail_info_pickUpLocation: 'Pickup Location',
  leasing_detail_info_dropOffLocation: 'Dropoff Location',
  leasing_detail_info_container: 'Container',
  leasing_detail_info_quantity: 'Quantity',
  leasing_detail_info_leaseType: 'Lease Type',
  leasing_detail_info_condition: 'Condition',
  leasing_detail_info_perDiem:'Per Diem(USD/Unit/Day)',
  leasing_detail_info_puc: 'PUC/Unit (USD)',
  leasing_detail_info_credit: 'Credit/Unit (USD)',
  leasing_detail_info_freeDays:'Freedays',
  leasing_detail_info_dpp: 'DPP(USD/Unit)',
  leasing_detail_info_replacementValue: 'Replacement Value(USD/Unit)',
  leasing_detail_info_validity: 'Validity',
  leasing_detail_info_created: 'Created',

  leasing_detail_info_sendRequest_button:'Apply',
  leasing_detail_info_upgrade_button:'Upgrade For Premium',

  leasing_inquiry_info_title: 'Make An Inquiry',
  leasing_inquiry_info_pickUpLocation: 'Pickup Location',
  leasing_inquiry_info_dropOffLocation: 'Dropoff Location',
  leasing_inquiry_info_size: 'Size',
  leasing_inquiry_info_type: 'Type',
  leasing_inquiry_info_qty: 'Quantity',
  leasing_inquiry_info_condition: 'Condition',
  leasing_inquiry_info_leaseType: 'Lease Type',
  leasing_inquiry_info_LastPickupDate: 'Last pickup date',

  leasing_inquiry_enter_pickUpLocation: 'Enter pickup location',
  leasing_inquiry_enter_dropOffLocation: 'Enter dropoff location',
  leasing_inquiry_enter_size: 'Select size',
  leasing_inquiry_enter_type: 'Select type',
  leasing_inquiry_enter_qty: 'Enter quantity',
  leasing_inquiry_enter_condition: 'Select condition',
  leasing_inquiry_enter_leaseType: 'Select lease type',
  leasing_inquiry_enter_LastPickupDate: 'Select latest pickup date',

  leasing_form_validate_error_pickUpLocation: 'Pickup location cannot be empty',
  leasing_form_validate_error_dropOffLocation: 'Dropoff cannot be empty',
  leasing_form_validate_error_size: 'Size cannot be empty',
  leasing_form_validate_error_type: 'Type cannot be empty',
  leasing_form_validate_error_qty: 'Quantity cannot be empty',
  leasing_form_validate_error_condition: 'Condition cannot be empty',
  leasing_form_validate_error_leaseType: 'Lease type cannot be empty',
  leasing_form_validate_error_lastPickupDate: 'Latest pickup date cannot be empty',

  leasing_inquiry_info_sendRequest_button:'Send Request',
  leasing_inquiry_send_confirm_tip_subject: 'The price inquiry email has been sent successfully',
  leasing_inquiry_send_confirm_tip_content: 'Thank you for your inquiry, we will reply you with the latest price by email',

  leasing_mobile_inquiry_send_confirm_tip_subject: 'Email has been sent successfully',
  leasing_mobile_inquiry_send_confirm_tip_content: 'Thank you, we will contact you by email',

  leasing_upgrade_send_confirm_tip_subject: 'We have received your upgrade membership application',
  leasing_upgrade_send_confirm_tip_content: 'hank you, we will contact you by email',


  leasing_order_title: 'My Inquiry',
  leasing_order_info_number: 'Number',
  leasing_order_info_pickUpLocation: 'Pickup Location',
  leasing_order_info_dropOffLocation: 'Dropoff Location',
  leasing_order_info_container: 'Container',
  leasing_order_info_quantity: 'Quantity',
  leasing_order_info_detail: 'Details',

  leasing_order_empty_title: 'Not Data yet',
  leasing_order_empty_text: 'You haven\'t added any inquiries yet',
  leasing_order_empty_return: 'Return to Homepage',

  leasing_inquiry_order_delete_subject: 'Reminder',
  leasing_inquiry_order_delete_content: 'Are you sure to delete this inquiry information?',
  leasing_inquiry_order_delete_confirm: 'Confirm',
  leasing_inquiry_order_delete_cancel: 'Cancel',

  leasing_inquiry_detail_title: 'Inquiry details',
  leasing_inquiry_detail_info_number: 'Number',
  leasing_inquiry_detail_info_pickUpLocation: 'Pickup Location',
  leasing_inquiry_detail_info_dropOffLocation: 'Dropoff Location',
  leasing_inquiry_detail_info_container: 'Type/Quantity',
  leasing_inquiry_detail_info_leaseType: 'Lease Type',
  leasing_inquiry_detail_info_condition: 'Condition',
  leasing_inquiry_detail_info_lastPickupDate: 'Latest pickup date',
  leasing_inquiry_detail_info_edit_button: 'Edit',
  leasing_inquiry_detail_info_delete_button: 'Delete',

  page_loading_completed_prompt: 'End of the list',
  pull_down_refresh_prompt: 'Pull down to refresh',

  service_chat_umy_notice: 'Hi, this is UMY! What can I do for you？',
  service_chat_send_img_prompt_title: 'Reminder',
  service_chat_send_img_prompt_content: 'Are you sure you want to send this picture?',
  service_chat_send_file_prompt_content: 'Are you sure you want to send this file?',
  service_chat_send_confirm_button: 'Confirm',
  service_chat_send_cancel_button: 'Cancel',
  service_chat_file_download: 'Download',


}

export const d = {
  depot_info_title: 'Global Depot Network',
  depot_search_country: 'Countries',
  depot_search_city: 'Cities',
  depot_search_button: 'Search',

  depot_free_join_title: 'Join for Free',
  depot_free_join_button: 'Join now',
  depot_upgrade_title: 'Upgrade For Premium',
  depot_upgrade_button: 'Upgrade now',

  depot_title_domesticDepot: 'Domestic Depot',
  depot_title_overseasDepot: 'Overseas Depot',
  depot_info_businessHours: 'Business hours',
  depot_info_hours: 'Hours',
  depot_info_stockpilingService: 'Storage service',
  depot_info_repairService: 'Repair and cleaning service',
  depot_mobile_info_stockpilingService: 'Storage',
  depot_mobile_info_repairService: 'Repair and cleaning',
  depot_info_fromPortDistance: 'From port/station',
  depot_info_depotArea: 'Depot Area (M²)',
  depot_info_depotCapacity: 'Depot Capacity (TEU)',
  depot_info_throughput: 'Throughput (CNTR/mth)',
  depot_info_detail_button: 'Details',
  depot_info_consult_button: 'Consult',

  depot_info_price_title: 'Charging Standards',
  depot_info_feet:'feet ',
  depot_info_emptyContainer : 'Empty container',
  depot_info_loadedContainer : 'Loaded container',
  depot_info_price_freeStoragePeriod: 'Free-storage period(day(s))',
  depot_info_price_storageFee: 'Storage fee(container/day)',
  depot_info_price_inLiftingFee: 'Gate-in lifting fee(yuan/container/time)',
  depot_info_price_outLiftingFee: 'Gate-out lifting fee(yuan/container/time)',

  depot_consult_form_title: 'Consult',
  depot_consult_form_companyName: 'Company Name',
  depot_consult_form_contactNumber: 'Contact Number',
  depot_consult_form_contactEmail: 'Contact Email',
  depot_consult_form_otherRequirements: 'Other requirements',
  depot_consult_form_button: 'Send Request',

  depot_consult_form_enter_companyName: 'Enter company name',
  depot_consult_form_enter_contactNumber: 'Enter contact number',
  depot_consult_form_enter_contactEmail: 'Enter contact email',
  depot_consult_form_enter_otherRequirements: 'If you have other requirements, please fill in here…',

  depot_consult_form_success_title: 'The consultation email has been sent successfully',
  depot_consult_form_success_text: 'Thank you for your consultation, we will reply you with the latest price by email',

  depot_upgrade_promotion_title: 'Upgrade For Premium',
  depot_upgrade_promotion_notice: 'Free upgrade during promotion period',
  depot_upgrade_promotion_advantage1_title: '1.Increase exposure,',
  depot_upgrade_promotion_advantage1_content: 'Unit-Match regularly pushes the preferred depot to customers around the world through the global network;',
  depot_upgrade_promotion_advantage2_title: '2.Let the customers be assured, ',
  depot_upgrade_promotion_advantage2_content: 'the upgraded depot with the preferred logo makes customers feel more at ease;',
  depot_upgrade_promotion_advantage3_title: '3.Strengthen cooperation, ',
  depot_upgrade_promotion_advantage3_content: 'Unit-Match will strengthen cooperation with the preferred depot to achieve strong alliances.',
  depot_upgrade_promotion_button: 'Upgrade now',

  depot_no_data_title: 'No data yet',
  depot_no_data_text: 'Didn\'t find the depot you wanted?',
  depot_no_data_return: 'Back to the last page',

  depot_reside_title: 'Join Global Depot Network',
  depot_reside_info_title: 'Depot Information',
  depot_reside_form_companyName: 'Company name',
  depot_reside_form_contactNumber: 'Contact number',
  depot_reside_form_contactEmail: 'Contact email',
  depot_reside_form_companyWebsite: 'Company website',
  depot_reside_form_depotName: 'Depot name',
  depot_reside_form_depotLocation: 'Depot location',
  depot_reside_form_detailedAddressOfTheDepot: 'Detailed address of the depot',
  depot_reside_form_repairAndCleaningService: 'Repair and cleaning service',
  depot_reside_form_fromPort: 'From port/station (KM)',
  depot_reside_form_depotArea: 'Depot area (M²)',
  depot_reside_form_depotCapacity: 'Depot capacity (TEU)',
  depot_reside_form_throughput: 'Throughput (CNTR/mth)',
  depot_reside_form_businessHours: 'Business hours',
  depot_reside_form_uninterruptedHours: '7*24 Hours',
  depot_reside_form_workDay: 'Date',
  depot_reside_form_workTime: 'Time',
  depot_reside_form_uploadDepotImage: 'Upload depot image',
  depot_reside_form_remark: 'Remark',
  depot_reside_form_chargingStandards_title: 'Charging Standards',
  depot_reside_form_emptyContainer: 'Empty container',
  depot_reside_form_loadedContainer: 'Loaded container',
  depot_reside_form_20Feet : '20 feet ',
  depot_reside_form_40Feet : '40 feet ',


  depot_reside_form_freeStoragePeriod: 'Free-storage period',
  depot_reside_form_freeStoragePeriod_unit: '(day(s))',
  depot_reside_form_storageFee: 'Storage fee',
  depot_reside_form_storageFee_unit: '(container/day)',
  depot_reside_form_gateInLiftingFee: 'Gate-in lifting fee',
  depot_reside_form_gateInLiftingFee_unit: '(yuan/container/time)',
  depot_reside_form_gateOutLiftingFee: 'Gate-out lifting fee',
  depot_reside_form_gateOutLiftingFee_unit: '(yuan/container/time)',

  depot_reside_form_freeStoragePeriod_complete: 'Free-storage period(day(s))',
  depot_reside_form_storageFee_complete: 'Storage fee(container/day)',
  depot_reside_form_gateInLiftingFee_complete: 'Gate-in lifting fee(yuan/container/time)',
  depot_reside_form_gateOutLiftingFee_complete: 'Gate-out lifting fee(yuan/container/time)',

  depot_reside_form_enter_companyName: 'Enter company name',
  depot_reside_form_enter_contactNumber: 'Enter company number',
  depot_reside_form_enter_contactEmail: 'Enter contact email',
  depot_reside_form_enter_companyWebsite: 'Enter company website',
  depot_reside_form_enter_depotName: 'Enter depot name',
  depot_reside_form_enter_depotCountry: 'Countries',
  depot_reside_form_enter_depotCity: 'Cities',
  depot_reside_form_enter_detailedAddressOfTheDepot: 'Enter detailed address of the depot',
  depot_reside_form_enter_fromPort: 'Enter distance between the depot and the port/station',
  depot_reside_form_enter_depotArea: 'Enter depot area',
  depot_reside_form_enter_depotCapacity: 'Enter depot capacity',
  depot_reside_form_enter_throughput: 'Enter Throughput',
  depot_reside_form_enter_depotImg: 'Image upload',
  depot_reside_form_enter_remark: 'If you have other remark, please fill in here…',

  depot_reside_form_enter_freeStoragePeriod: 'Free-storage period',
  depot_reside_form_enter_storageFee: 'Storage fee',
  depot_reside_form_enter_gateInLiftingFee: 'Gate-in lifting fee',
  depot_reside_form_enter_gateOutLiftingFee: 'Gate-out lifting fee',

  depot_reside_form_notNull_companyName: 'Company name cannot be empty',
  depot_reside_form_notNull_contactNumber: 'Company number cannot be empty',
  depot_reside_form_notNull_contactEmail: 'Contact email cannot be empty',
  depot_reside_form_notNull_companyWebsite: 'Company website cannot be empty',
  depot_reside_form_notNull_depotName: 'Depot name cannot be empty',
  depot_reside_form_notNull_depotCountry: 'Country cannot be empty',
  depot_reside_form_notNull_depotCity: 'City cannot be empty',
  depot_reside_form_notNull_detailedAddressOfTheDepot: 'Detailed address cannot be empty',
  depot_reside_form_notNull_fromPort: 'From port/station cannot be empty',
  depot_reside_form_notNull_depotArea: 'Depot area cannot be empty',
  depot_reside_form_notNull_depotCapacity: 'Depot capacity cannot be empty',
  depot_reside_form_notNull_throughput: 'Throughput cannot be empty',
  depot_reside_form_notNull_depotImg: 'Depot image cannot be empty',

  depot_reside_form_notNull_freeStoragePeriod: 'Free-storage period cannot be empty',
  depot_reside_form_notNull_storageFee: 'Storage fee cannot be empty',
  depot_reside_form_notNull_gateInLiftingFee: 'Gate-in lifting fee cannot be empty',
  depot_reside_form_notNull_gateOutLiftingFee: 'Gate-out lifting fee cannot be empty',

  depot_reside_form_button_submit: 'Join now',
  depot_reside_form_button_cancel: 'Cancel',

  depot_reside_form_success_text: 'Under Review',
  depot_upgrade_form_success_text: 'Under review,and our colleagues will contact you later.',

  depot_info_depot_title: 'My Depot',
  depot_info_upgrade_depot_title: 'Preferred Depot',
  depot_info_form_title: 'Depot Information',
  depot_info_form_companyName: 'Company name',
  depot_info_form_contactNumber: 'Contact number',
  depot_info_form_contactEmail: 'Contact email',
  depot_info_form_companyWebsite: 'Company website',
  depot_info_form_depotName: 'Depot name',
  depot_info_form_depotLocation: 'Depot location',
  depot_info_form_detailedAddressOfTheDepot: 'Detailed address of the depot',
  depot_info_form_repairAndCleaningService: 'Repair and cleaning service',
  depot_info_form_isRepairAndCleaningService: 'Repair and cleaning service',
  depot_info_form_fromPort: 'From port/station (KM)',
  depot_info_form_depotArea: 'Depot area (M²)',
  depot_info_form_depotCapacity: 'Depot capacity (TEU)',
  depot_info_form_throughput: 'Throughput (CNTR/mth)',
  depot_info_form_businessHours: 'Business hours',
  depot_info_form_uninterruptedHours: '7*24 Hours',
  depot_info_form_workDay: 'Date',
  depot_info_form_workTime: 'Time',
  depot_info_form_uploadDepotImage: 'Upload depot image',
  depot_info_form_depotImage: 'Depot image',
  depot_info_form_depotImage_view: 'View image',
  depot_info_form_remark: 'Remark',
  depot_info_form_chargingStandards_title: 'Charging Standards',
  depot_info_form_emptyContainer: 'Empty container',
  depot_info_form_loadedContainer: 'Loaded container',
  depot_info_form_20Feet : '20 Feet ',
  depot_info_form_40Feet : '40 Feet ',
  depot_info_form_freeStoragePeriod: 'Free-storage period(day(s))',
  depot_info_form_storageFee: 'Storage fee(container/day)',
  depot_info_form_gateInLiftingFee: 'Gate-in lifting fee(yuan/container/time)',
  depot_info_form_gateOutLiftingFee: 'Gate-out lifting fee(yuan/container/time)',

  depot_info_20Feet_title: '20 Feet Charging Standards',
  depot_info_40Feet_title: '40 Feet Charging Standards',

  depot_info_update_confirm: 'Confirm',
  depot_info_update_cancel: 'Cancel',
  depot_info_return_button: 'Return',

  depot_info_form_enter_companyName: 'Enter company name',
  depot_info_form_enter_contactNumber: 'Enter company number',
  depot_info_form_enter_contactEmail: 'Enter contact email',
  depot_info_form_enter_companyWebsite: 'Enter company website',
  depot_info_form_enter_depotName: 'Enter depot name',
  depot_info_form_enter_depotCountry: 'Countries',
  depot_info_form_enter_depotCity: 'Cities',
  depot_info_form_enter_detailedAddressOfTheDepot: 'Enter detailed address of the depot',
  depot_info_form_enter_fromPort: 'Enter distance between the depot and the port/station',
  depot_info_form_enter_depotArea: 'Enter depot area',
  depot_info_form_enter_depotCapacity: 'Enter depot capacity',
  depot_info_form_enter_throughput: 'Enter Throughput',
  depot_info_form_enter_depotImg: 'Image upload',
  depot_info_form_enter_remark: 'If you have other remark, please fill in here…',

  depot_info_form_enter_freeStoragePeriod: 'Free-storage period',
  depot_info_form_enter_storageFee: 'Storage fee',
  depot_info_form_enter_gateInLiftingFee: 'Gate-in lifting fee',
  depot_info_form_enter_gateOutLiftingFee: 'Gate-out lifting fee',

  depot_basic_info_title: 'Depot Information',
  depot_basic_info_repairAndCleaningService: 'Yes',
  depot_basic_info_noRepairAndCleaningService: 'No',
  depot_update_success_text: 'Modification successful',
  depot_no_depot_text: 'Haven\'t joined yet?',
  depot_no_depot_join_text: 'Join now',











}
