//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import umyData from './umyData'

export default {
  props: {
    onBlur: {
      type: Function,
      required: true
    },
    onUmyPicked: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      umyData,
    }
  },
  mounted() {
    const elem = this.$refs.domNode
    elem.style.opacity = 0
    window.requestAnimationFrame(() => {
      elem.style.transition = 'opacity 350ms'
      elem.style.opacity = 1
    })
    this.$refs.domNode.focus()
  },
  methods: {
      umyClicked(umy) {
      this.onUmyPicked(umy)
      this.$refs.domNode.blur()
    }
  }
}
