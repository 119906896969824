//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "profile_info",
  data(){
      const validateCompanyContact = (rule, value, callback) => {
          if (!value) {
              return callback(new Error(this.$t('t.profile_form_contactsNameNotNull')))
          } else {
              return callback()
          }
      }
      const validateCompanyPhone = (rule, value, callback) => {
          const regex = /^1[3456789]\d{9}$/;
          if (!value) {
              return callback(new Error(this.$t('t.profile_form_telephoneNotNull')))
          }
          // else if(!regex.test(value)){
          //     return callback(new Error('手机号格式输入不正确，请重新输入'))
          // }
          else {
              return callback()
          }
      }
      const validateEmail = (rule, value, callback) => {
          if (!value) {
              return callback(new Error(this.$t('t.profile_form_emailNotNull')))
          } else {
              return callback()
          }
      }
      const validateEmailVerifyCode = (rule, value, callback) => {
          if (!value) {
              return callback(new Error(this.$t('t.profile_form_emailVerificationCodeNotNull')))
          } else {
              return callback()
          }
      }
      const validateCertFileContent = (rule, value, callback) => {
          if (!value) {
              return callback(new Error(this.$t('t.profile_form_businessLicenseNotNull')))
          } else {
              return callback()
          }
      }
      return {
        userInfo: {
            id: '',
            companyName: '',
            companyContact: '',
            companyPhone: '',
            email: '',
            companyAddr: '',
            pushMsg: '',
            certUrl: '',
            emailVerifyCode: ''
        },
        tradingChecked: false,
        leasingChecked: false,
        spotChecked: false,
        rules: {
              companyContact: [{ required: true, validator: validateCompanyContact, trigger: 'blur' }],
              companyPhone: [{ required: true, validator: validateCompanyPhone, trigger: 'blur' }],
              email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
              emailVerifyCode: [{ required: true, validator: validateEmailVerifyCode, trigger: 'blur' }],
          },
          fileRules: {
              certFileContent: [{ required: true, validator: validateCertFileContent, trigger: 'blur' }]
          },
        showEditDialog: false,
        showUploadDialog: false,
        isCounting: false,
        countdown: 60,
        certFileContent: '',
        proofImage: '',
        dialogVisible: false,
        dialogImageUrl: '',
        fileList: [],
    }
  },
  methods :{
      async getProfileInfo(){
          const param = {
              username: this.getLocalStoreItem("username")
          }
          try{
              const result = await this.$axios.get('/bk/userSetting/getUserInfo', {
                  params: param
              })
              if(result === 401) {
                  localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
                  this.$router.push({
                      path:'/login'
                  })
              }else if(result.username){
                  this.userInfo = result;
                  let displayCheckList = this.userInfo.pushMsg.split(',');
                  for(let i=0; i< displayCheckList.length; i++){
                      if(displayCheckList[i] === '买卖箱'){
                          this.tradingChecked = true;
                      }
                      if(displayCheckList[i] === '租赁箱'){
                          this.leasingChecked = true;
                      }
                      if(displayCheckList[i] === '舱位通'){
                          this.spotChecked = true;
                      }
                  }
              }else{
                  this.$message.error(this.$t('t.contact_IT'))
              }
          }catch (e) {

          }
      },
      edit(){
          this.showEditDialog = true;
      },
      openUploadDialog(){
          this.showUploadDialog = true;
      },
      async updateProfileInfo (e) {
          try {
              await this.$refs.userInfo.validate()
              this.loading = true
              let pushMsg = '';
              if(this.tradingChecked){
                  pushMsg += '买卖箱,';
              }
              if(this.leasingChecked){
                  pushMsg += '租赁箱,';
              }
              if(this.spotChecked){
                  pushMsg += '舱位通,';
              }
              if (pushMsg.indexOf(',')){
                  pushMsg = pushMsg.substr(0, pushMsg.length-1);
              }
              const params = {
                  ...this.userInfo,
                  pushMsg: pushMsg
              }
              delete params.userInfo;
              console.log(JSON.stringify(params) + 'params')
              const result = await this.$axios.post('/bk/userSetting/updateUserInfo', params)
              this.loading = false
              console.log(result.message + 'status');
              if (result.message === 'update success!') {
                  location.reload();
              }else if(result === 401){
                  localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
                  this.$router.push({
                      path:'/login'
                  })
              }else{
                  this.$message.error(this.$t('t.contact_IT'))
              }
          } catch (e) {
              console.log(e, 'e')
          }
      },
      async  sendEmailVerifyCode(){
          try {
              const params = {
                  email:this.userInfo.email,
                  opType:'updateProfile'
              }
              const res = await this.$axios.get('/bk/auth/sendCode',{
                  params:params
              });
              if(res){
                  console.log(res);
              }
          } catch (e) {
              console.log(e, 'e')
          }

      },
      updateCountdown() {
          if (this.countdown > 0) {
              setTimeout(() => {
                  this.countdown -= 1;
                  this.updateCountdown();
              }, 1000);
          } else {
              this.isCounting = false;
              this.countdown = 60;
          }
      },
      startCountdown() {
          if(!this.userInfo.email){
              this.$message({
                  message: this.$t('t.email_VerificationEmailNotNull'),
                  type: 'warning'
              });
              return;
          }
          const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (!emailRegex.test(this.userInfo.email)) {
              this.$message({
                  message: this.$t('t.email_VerificationEmailFormat'),
                  type: 'warning'
              });
              return;
          }
          if (!this.isCounting) {
              this.isCounting = true;
              this.updateCountdown();
              const button = document.querySelector('.color-change-button');
              button.classList.add('clicked');

              this.sendEmailVerifyCode();
          }
      },
      getFile (file, fileList, num) {
          this.getBase64(file.raw).then(res => {
              const params = res
              console.log(params, 'params')
              this.proofImage = params
              if (num === 1) {
                  this.certFileContent = this.proofImage
              }
          })
      },
      getBase64 (file) {
          return new Promise(function (resolve, reject) {
              const reader = new FileReader()
              let imgResult = ''
              reader.readAsDataURL(file)
              reader.onload = function () {
                  imgResult = reader.result
              }
              reader.onerror = function (error) {
                  reject(error)
              }
              reader.onloadend = function () {
                  resolve(imgResult)
              }
          })
      },
      handleUploadRemove (file, fileList, num) {
          this.proofImage = ''
          if (num === 1) {
              this.certFileContent = this.proofImage
          }
      },
      handlePictureCardPreview (file) {
          this.dialogImageUrl = file.url
          this.dialogVisible = true
      },
      async upload(){
          await this.$refs.certFileContent.validate();
          const params = {
              username: this.getLocalStoreItem("username"),
              certContext: this.certFileContent
          }
          const result = await this.$axios.post('/bk/userSetting/uploadCertPic', params);
          if(result === 401){
              localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
              this.$router.push({
                  path:'/login'
              })
          }else if(result === 'success'){
              this.certFileContent = '';
              this.showUploadDialog = false;
              this.dialogImageUrl = '';
              this.dialogVisible = false;
              location.reload();
          }else{
              this.$message.error(this.$t('t.contact_IT'))
          }
      },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.getProfileInfo();
  },
  computed: {
      buttonText() {
          return this.isCounting ? `${this.countdown}` + this.$t('t.profile_form_emailEmailVerificationCodeResend') : this.$t('t.profile_form_getEmailVerificationCode');
      },
      currentLang () {
          return this.$i18n.locale
      }
  }
}
