//
//
//
//
//
//
//
//
//

export default {
  data(){
    return{
      content: ''
    }
  },
  created(){
      this.content = this.baseURL + '/resource/umy/' + this.message.content;
  },
  props: {
      message: {
      type: Object,
      required: true
    }
  }
}
