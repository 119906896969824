//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Message from './Message.vue'
import chatIcon from '../../assets/chat-icon.svg'

export default {
  components: {
    Message
  },
  data(){
    return{
      isBottomingOut:false,
    }
  },
  props: {
    participants: {
      type: Array,
      required: true
    },
    messages: {
      type: Array,
      required: true
    },
    unreadCount: {
      type: Number,
      required: true
    },
    showTypingIndicator: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    massageOpen(){
      return this.messages;
    },
    defaultChatIcon() {
      return chatIcon
    },
    imgList(){
      let list = this.messages.filter(item => item.type === 'img').map(item => item.fileUrl);
      return list;
    }
  },
  mounted() {
    this.$nextTick(this._scrollDown())
  },
  updated() {
    if (this.shouldScrollToBottom()) this.$nextTick(this._scrollDown())
  },
  methods: {
    _scrollDown() {
      this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight
    },
    handleScroll(e) {
      if (e.target.scrollTop === 0) {
        this.$emit('scrollToTop')
      }
      // console.log(e)
      // console.log(e.target.scrollHeight)
      const {scrollTop, clientHeight, scrollHeight} = e.target
      // console.log(scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight){
        // console.log(this.messages)
        this.isBottomingOut = true;
        // console.log(this.messages)
      }
    },
    shouldScrollToBottom() {
      const scrollTop = this.$refs.scrollList.scrollTop
      const scrollable = scrollTop > this.$refs.scrollList.scrollHeight - 600
      return this.alwaysScrollToBottom || scrollable
    },
    profile(author) {
      const profile = this.participants.find((profile) => profile.id === author)

      // A profile may not be found for system messages or messages by 'me'
      return profile || {imageUrl: '', name: ''}
    },
    unreadMessageListOpen(){
      this.$emit('read-message');
    }
  }
}
