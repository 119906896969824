//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmojiPicker from './../EmojiPicker.vue'

export default {
  components: {
    EmojiPicker
  },
  props: {
    onEmojiPicked: {
      type: Function,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    _openPicker(e) {
      this.isActive = !this.isActive
    },
    _handlePickerBlur() {
      this.isActive = false
    }
  }
}
