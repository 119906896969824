//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_leasing_inquiry",
  data(){
    const validateInquiryPickUpLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_pickUpLocation')))
      } else {
        return callback()
      }
    }
    const validateInquiryDropOffLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_dropOffLocation')))
      } else {
        return callback()
      }
    }
    const validateInquirySize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_size')))
      } else {
        return callback()
      }
    }
    const validateInquiryType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_type')))
      } else {
        return callback()
      }
    }
    const validateInquiryQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_qty')))
      } else {
        return callback()
      }
    }
    const validateInquiryCondition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_condition')))
      } else {
        return callback()
      }
    }
    const validateInquiryLeaseType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_leaseType')))
      } else {
        return callback()
      }
    }
    const validateInquiryLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_lastPickupDate')))
      } else {
        return callback()
      }
    }
    return{
      leasingInquiryOrderList: [],
      isEmpty: false,
      showEditInquiryDialog: false,
      showDeleteInquiryDialog: false,
      pickUpLocationOptions: [],
      dropOffLocationOptions: [],
      currentRecordId: '',
      showTip: false,
      loading: true,
      tipSubject: '',
      tipContent: '',
      inquiryForm:{
        pickUpLocation: '',
        dropOffLocation: '',
        size: '',
        type: '',
        qty: '',
        condition: '',
        leaseType: '',
        latestPickupDate: ''
      },
      inquiryRules:{
        pickUpLocation: [{ required: true, validator: validateInquiryPickUpLocation, trigger: 'blur' }],
        dropOffLocation: [{ required: true, validator: validateInquiryDropOffLocation, trigger: 'blur' }],
        size: [{ required: true, validator: validateInquirySize, trigger: 'blur' }],
        type: [{ required: true, validator: validateInquiryType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateInquiryQty, trigger: 'blur' }],
        condition: [{ required: true, validator: validateInquiryCondition, trigger: 'blur' }],
        leaseType: [{ required: true, validator: validateInquiryLeaseType, trigger: 'blur' }],
        latestPickupDate: [{ required: true, validator: validateInquiryLastPickupDate, trigger: 'blur' }],
      }
    }
  },
  methods:{
    async leasingInquiry(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/leasingConsult/listConsult', {
        params: params
      });
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }
      this.leasingInquiryOrderList = result;
      this.isEmpty = this.leasingInquiryOrderList.length === 0;
    },
    viewInquiryDetail(item){
      try {
        let url = this.baseURL + '/#/containerLeasingInquiryDetail/' + item.consultNo;
        window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    openEditDialog(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.inquiryForm = {
        ...item,
        pickUpLocation: item.pickupLoc,
        dropOffLocation: item.dropoffLoc,
        size: item.sizeType.substring(0,2),
        type: item.sizeType.substring(2,item.sizeType.length),
        condition: item.conditions,
        qty: item.requestQty,
      }
      this.showEditInquiryDialog = true;
    },
    async editInquiryInfo(){
      await this.$refs.inquiryForm.validate()

      const params = {
        ...this.inquiryForm,
        userId: this.getLocalStoreItem('userId'),
        sizeType: this.inquiryForm.size + this.inquiryForm.type,
        pickupLoc: this.inquiryForm.pickUpLocation,
        dropoffLoc: this.inquiryForm.dropOffLocation,
        requestQty: this.inquiryForm.qty,
        latestPickupDate: this.dateFormat(this.inquiryForm.latestPickupDate),
        conditions: this.inquiryForm.condition,
      }
      const result = await this.$axios.post('/bk/leasingConsult/updateConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.$refs['inquiryForm'].resetFields()
        this.showEditInquiryDialog = false;
        this.$message({
          message: 'Update Success',
          type: 'success'
        });
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    openDeleteDialog(item){
      this.showDeleteInquiryDialog = true;
      this.currentRecordId = item.id;
    },
    async deleteInquiryRecord(){
      const params = {
        id: this.currentRecordId,
        userId: this.getLocalStoreItem('userId')
      }
      const result = await this.$axios.post('/bk/leasingConsult/deleteConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
      this.currentRecorId = '';
      this.showDeleteInquiryDialog = false;
    },
    continueBrowsing(){
      this.$router.push({
        path:'/containerLeasing'
      })
    },


    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query, isPickUp){
      try {
        const param = {
          portName: query
        }
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        if(isPickUp){
          this.pickUpLocationOptions = res;
        }else{
          this.dropOffLocationOptions = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterPickUpLocationPort(query){
      this.searchPort(query, true);
    },
    filterDropOffLocationPort(query){
      this.searchPort(query, false);
    },
  },
  created () {
    this.filterPickUpLocationPort('SHA');
    this.filterDropOffLocationPort('Los');
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.leasingInquiry();
  },
}
