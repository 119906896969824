//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_pace_resource_detail",
  data(){
    return{
      item:{}
    }
  },
  methods:{
    toOrder () {
      localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
      if(!this.getLocalStoreItem('token')){
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
          path: '/spotSpacePurchase/' + this.item.resourceNo
      });
    },
    async getDetailInfo () {
      try {
        const param = {
          resourceNo: this.resourceNo
        }
        const res = await this.$axios.get('/bk/spot/getSpotResource', {
          params: param
        })
        if(res.resourceNo){
          this.item = res;
        }
        console.log(res);
      } catch (e) {
        console.log(e, 'e')
      }
    },

  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.resourceNo = this.$route.params.resourceNo;
    this.getDetailInfo();
  },
}
