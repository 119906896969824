//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LegalNotice from './one_stop_trading_legalNotices'
import OneStopTradingServiceAgreement from './one_stop_trading_serviceagreement.vue'
import OneStopTradingPricarypolicy from './one_stop_trading_privarypolicy'
import pdf from 'vue-pdf'

export default {
  name: "registertrading",
  components: {
    LegalNotice,
    OneStopTradingServiceAgreement,
    OneStopTradingPricarypolicy,
    pdf
  },
  data () {


    const validatePassword = (rule, value, callback) => {
      const regex = /^(?=.*[a-zA-Z])(?=.*\d).{8,16}$/;
      if (!value) {
        return callback(new Error(this.$t('t.register_passwordNotNull')))
      }
      if(value.length < 8 || value.length > 16){
        return callback(new Error(this.$t('t.register_passwordLongVerification')))
      }
      if(!regex.test(value)){
        return callback(new Error(this.$t('t.register_passwordFormatVerification')))
      }
      return callback()
    }
    const validateCompanyName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.register_companyNameNotNull')))
      } else {
        return callback()
      }
    }
    const validateCompanyContact = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.register_companyContactNotNull')))
      } else {
        return callback()
      }
    }
    const validateCompanyPhone = (rule, value, callback) => {
      // const regex = /^1[3456789]\d{9}$/;
      if (!value) {
        return callback(new Error(this.$t('t.register_companyContactNotNull')))
      }
      // else if(!regex.test(value)){
      //   return callback(new Error('手机号格式输入不正确，请重新输入'))
      // }
      else {
        return callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.register_companyContactEmailNotNull')))
      } else {
        return callback()
      }
    }
    const validateEmailVerifyCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.register_emailVerificationCodeNotNull')))
      } else {
        return callback()
      }
    }
    const validatePasswordConfirmation = (rule, value, callback) => {

      if (!value) {
        return callback(new Error(this.$t('t.register_passwordConfirmNotNull')))
      } else if(value !== this.infoForm.password){
          return callback(new Error(this.$t('t.register_passwordVerification')))
      }
      else {
        return callback()
      }
    }

    const validateTradingList = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.register_tradingResourceVerification')))
      } else {
        return callback()
      }
    }

    return {
      infoForm: {
        username: '',
        password: '',
        secondPassword: '',
        companyName: '',
        companyContact: '',
        companyPhone: '',
        countryCode: '86',
        certContext: '',
        companyAddr: '',
        email: '',
        emailVerifyCode: '',
        tradingList: [],
        role: [],
        tradingChecked: false,
        leasingChecked: false,
        spotChecked: false,
        fromStaff: 'Other',
      },
      vipProtocolPdfSrc: this.baseURL + '/resource/protocol/vip_protocol.png',
      systemLawPdfSrc: this.baseURL + '/resource/protocol/system_law.png',
      registerServiceProtocolPdfSrc: this.baseURL + '/resource/protocol/register_service_protocol.png',
      privacyProtocolPdfSrc: this.baseURL + '/resource/protocol/privacy_protocol.png',
      rules: {
        password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        companyName: [{ required: true, validator: validateCompanyName, trigger: 'blur' }],
        companyContact: [{ required: true, validator: validateCompanyContact, trigger: 'blur' }],
        companyPhone: [{ required: true, validator: validateCompanyPhone, trigger: 'blur' }],
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        emailVerifyCode: [{ required: true, validator: validateEmailVerifyCode, trigger: 'blur' }],
        secondPassword: [{ required: true, validator: validatePasswordConfirmation, trigger: 'blur' }],
        tradingList: [{ required: true, validator: validateTradingList, trigger: 'blur' }],
      },
      currentCategoary: 'free',
      vipLevel: 'Free',
      checkedInfo: false,
      loading: false,
      fileList: [],
      proofImage: '',
      dialogImageUrl: '',
      dialogVisible: false,
      protocolDialog: false,
      countNum: 30,
      timId: '',
      hasReading: false,
      activeName: '1',
      isCounting: false,
      countdown: 60,
      showTip: false
    }
  },
  created () {
    if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.currentCategoary = this.$route.query.type;
    this.vipLevel = this.$route.query.vipLevel;
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
    checkedProtocol: {
      get: function () {
        console.log('running get function')
        return this.hasReading
      },
      set: function (value) {
        console.log('running set function')
        this.hasReading = value
      }
    },
    buttonText() {
        return this.isCounting ? `${this.countdown}` + this.$t('t.register_emailEmailVerificationCodeResend') : this.$t('t.register_getEmailVerificationCode');
    },
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          this.$refs.infoForm.fields.forEach(item => {
            if (item.validateState === 'error') {
              this.$refs.infoForm.validateField(item.labelFor)
            }
          })
        })
      },
      immediate: true
    }
  },
  methods: {
    handleClose(){
      this.showTip = false;
    },
    confirmReadHandler () {
      this.hasReading = true
      this.protocolDialog = false
    },
    protocalHandler (val) {
      console.log(val)
      if (val) {
        this.countNum = 30
        this.protocolDialog = true
        this.countdownFile()
      }
    },
    countdownFile () {
      this.timId = setInterval(() => {
        if (this.countNum < 1) {
          clearInterval(this.timId)
          return
        }
        this.countNum--
      }, 1000)
    },
    async submitInfo (e) {
      try {
        await this.$refs.infoForm.validate()

        if (!this.checkedInfo) {
          this.$message.error(this.$t('m.agreeColleFirst'))
          return
        }
        if (!this.checkedProtocol) {
          this.$message.error(this.$t('m.agreeColleFirst'))
          return
        }
        this.loading = true

        let pushMsgStr = '';
        if(this.infoForm.tradingChecked){
            pushMsgStr += '买卖箱,';
        }
        if(this.infoForm.leasingChecked){
            pushMsgStr += '租赁箱,';
        }
        if(this.infoForm.spotChecked){
            pushMsgStr += '舱位通,';
        }
        if (pushMsgStr.indexOf(',')){
            pushMsgStr = pushMsgStr.substr(0, pushMsgStr.length-1);
        }

        const params = {
          ...this.infoForm,
          role: ['user'],
          username: this.infoForm.email,
          pushMsg: pushMsgStr
        }
        delete params.tradingList;

        console.log(JSON.stringify(params) + 'params')

        const addResult = await this.$axios.post('/bk/auth/signup', params)
        this.loading = false
        console.log(addResult.message + 'status');

        // if (addResult.status === null) {
        //   this.$message.error(addResult.message)
        // }

        if(addResult.message === 'Error： 验证码不正确！'){
          this.$message({
            message: 'Verification code is incorrect',
            type: 'error'
          })
        }
        if(addResult.message === 'Error: 用户名已存在！'){
          this.$message({
            message: 'The username already exists',
            type: 'error'
          })
        }
        if(addResult.message === 'Error: 邮箱地址已存在！'){
          this.$message({
            message: 'The email address already exists',
            type: 'error'
          })
        }
        if (addResult.message === 'User registered successfully!') {
          this.infoForm = {
              username: '',
              password: '',
              secondPassword: '',
              companyName: '',
              companyContact: '',
              companyPhone: '',
              certContext: '',
              companyAddr: '',
              email: '',
              emailVerifyCode: '',
              pushMsg: [],
              role: [],
              tradingList: [],
              fromStaff: 'Other'
          }

          const registerBox = document.querySelector('.register-info-box');
          const successBox = document.querySelector('.register-success-box');
          registerBox.style.display = 'none';
          successBox.style.display = 'block';

        }

      } catch (e) {
        console.log(e, 'e')
      }
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        console.log(params, 'params')
        this.proofImage = params
        if (num === 1) {
          this.infoForm.certContext = this.proofImage
        }

      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.infoForm.certContext = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleSuccess (res, file, num) {
      if (num === 1) {
        this.infoForm.certContext = this.proofImage
      }
    },
    async startCountdown() {
      try {
        if(!this.infoForm.email){
          this.$message({
            message: this.$t('t.email_VerificationEmailNotNull'),
            type: 'warning'
          });
          return;
        }

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(this.infoForm.email)) {
          this.$message({
            message: this.$t('t.email_VerificationEmailFormat'),
            type: 'warning'
          });
          return;
        }

        const params = {
          email:this.infoForm.email
        }
        const res = await this.$axios.get('/bk/auth/existUser',{
          params:params
        });
        if(res.message == 'Yes'){
          this.showTip = true;
        }else {
          if (!this.isCounting) {
            this.isCounting = true;
            this.updateCountdown();
            const button = document.querySelector('.color-change-button');
            button.classList.add('clicked');
            this.sendEmailVerifyCode();
          }
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    updateCountdown() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.updateCountdown();
        }, 1000);
      } else {
        this.isCounting = false;
        this.countdown = 60;
      }
    },
    async  sendEmailVerifyCode(){
      try {
        const params = {
          email:this.infoForm.email,
          opType:'register'
        }
        const res = await this.$axios.get('/bk/auth/sendCode',{
          params:params
        });
        if(res){
          console.log(res);
        }
      } catch (e) {
        console.log(e, 'e')
      }

    },
    jumptoLogin () {
      this.$router.push('/login')
    },
  }
}
