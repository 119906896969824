//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_container_leasing",
  data(){
    const validateInquiryPickUpLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_pickUpLocation')))
      } else {
        return callback()
      }
    }
    const validateInquiryDropOffLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_dropOffLocation')))
      } else {
        return callback()
      }
    }
    const validateInquirySize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_size')))
      } else {
        return callback()
      }
    }
    const validateInquiryType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_type')))
      } else {
        return callback()
      }
    }
    const validateInquiryQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_qty')))
      } else {
        return callback()
      }
    }
    const validateInquiryCondition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_condition')))
      } else {
        return callback()
      }
    }
    const validateInquiryLeaseType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_leaseType')))
      } else {
        return callback()
      }
    }
    const validateInquiryLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_lastPickupDate')))
      } else {
        return callback()
      }
    }
    return{
      searchLeasingForm:{
        size: '',
        type: '',
        sizeType: '',
        pickUpLocation: '',
        dropOffLocation: ''
      },
      pickUpLocationOptions: [],
      dropOffLocationOptions: [],
      leasingResourceList: [],
      isEmpty: false,
      showInquiryDialog: false,
      showTip: false,
      loading: false,
      tipSubject: '',
      tipContent: '',
      inquiryForm:{
        pickUpLocation: '',
        dropOffLocation: '',
        size: '',
        type: '',
        qty: '',
        condition: '',
        leaseType: '',
        latestPickupDate: ''
      },
      inquiryRules:{
        pickUpLocation: [{ required: true, validator: validateInquiryPickUpLocation, trigger: 'blur' }],
        dropOffLocation: [{ required: true, validator: validateInquiryDropOffLocation, trigger: 'blur' }],
        size: [{ required: true, validator: validateInquirySize, trigger: 'blur' }],
        type: [{ required: true, validator: validateInquiryType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateInquiryQty, trigger: 'blur' }],
        condition: [{ required: true, validator: validateInquiryCondition, trigger: 'blur' }],
        leaseType: [{ required: true, validator: validateInquiryLeaseType, trigger: 'blur' }],
        latestPickupDate: [{ required: true, validator: validateInquiryLastPickupDate, trigger: 'blur' }],
      },
      totalItems: 0,// 总条目数
      currentPage: 1, // 当前页码
      pageSize: 8,// 每页显示的条目数量
      offset: 0,
    }
  },
  methods:{
    async onRefresh() {
      this.currentPage = 1;
      await this.searchLeasingResource(true);
      this.$nextTick(() => {
        this.$refs.my_scroller.finishPullToRefresh();// 关闭底部加载转圈
      })
    },
    // 触底加载
    async onScroll() {
      this.currentPage ++;
      await  this.searchLeasingResource(false);
      this.$nextTick(() => {
        this.$refs.my_scroller.finishInfinite(true); // 关闭底部加载转圈
      })
    },
    async searchLeasingResource (isRefresh) {
      try {
        if(isRefresh){
          this.offset = 0;
        }else{
          this.offset = (this.currentPage - 1) * this.pageSize;
        }
        const params = {
          sizeType: (this.searchLeasingForm.size || this.searchLeasingForm.type)? this.searchLeasingForm.size + this.searchLeasingForm.type : null,
          pickupLoc: (this.searchLeasingForm.pickUpLocation)? encodeURI(this.searchLeasingForm.pickUpLocation) : null,
          dropoffLoc: (this.searchLeasingForm.dropOffLocation)? encodeURI(this.searchLeasingForm.dropOffLocation) : null,
          offset: this.offset,
          pageSize: this.pageSize
        }
        console.log( params)
        const res = await this.$axios.get('/bk/leasing/search', {
          params: params
        })

        if(isRefresh){
          this.leasingResourceList = res.resultList;
        }else{
          if(res.resultList.length > 0){
            for(let i = 0; i < res.resultList.length; i ++){
              this.leasingResourceList.push(res.resultList[i]);
            }
          }
        }
        this.isEmpty = this.leasingResourceList.length === 0;
        this.totalItems = res.total;
        console.log( this.leasingResourceList)
      } catch (e) {
        console.log(e, 'e')
      }
    },
    searchLeasing(){
      this.searchLeasingResource(true);
    },
    openInquiryDialog(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.showInquiryDialog = true;
    },
    async leasingInquirySend(){
      try {
        await this.$refs.inquiryForm.validate();
        const params = {
          ...this.inquiryForm,
          userId: this.getLocalStoreItem('userId'),
          sizeType: this.inquiryForm.size + this.inquiryForm.type,
          pickupLoc: this.inquiryForm.pickUpLocation,
          dropoffLoc: this.inquiryForm.dropOffLocation,
          requestQty: this.inquiryForm.qty,
          latestPickupDate: this.dateFormat(this.inquiryForm.latestPickupDate),
          conditions: this.inquiryForm.condition,
        }
        const result = await this.$axios.post('/bk/leasingConsult/consult', params);
        if(result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }else if(result === 'success'){
          this.$refs['inquiryForm'].resetFields()
          this.showInquiryDialog= false;
          this.tipSubject = this.$t('l.leasing_inquiry_send_confirm_tip_subject');
          this.tipContent = this.$t('l.leasing_inquiry_send_confirm_tip_content');
          this.showTip = true;
        }else{
          this.$message.error(this.$t('t.contact_IT'))
        }
      }catch (e) {
        console.log(e, 'e')
      }
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    sendLeasingRequest(){

    },
    viewLeasingDetail(resourceNo){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path: '/containerLeasingDetail/' + resourceNo
      });
    },
    handleClose(){
      this.showTip = false;
    },
    async searchPort(query, isPickUp){
      try {
        const param = {
          portName: query
        }
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        if(isPickUp){
          this.pickUpLocationOptions = res;
        }else{
          this.dropOffLocationOptions = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterPickUpLocationPort(query){
      this.searchPort(query, true);
    },
    filterDropOffLocationPort(query){
      this.searchPort(query, false);
    },
    changeLocation(){
      let temp = this.searchLeasingForm.pickUpLocation;
      this.searchLeasingForm.pickUpLocation = this.searchLeasingForm.dropOffLocation;
      this.searchLeasingForm.dropOffLocation = temp;
      let tempList = this.departureOptions;
      this.departureOptions = this.destinationOptions;
      this.destinationOptions = tempList;
    },
  },
  created () {
    this.searchLeasingResource(true);
    this.filterPickUpLocationPort('SHA');
    this.filterDropOffLocationPort('Los');
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          if(this.$refs.consultForm){
            this.$refs.consultForm.fields.forEach(item => {
              if (item.validateState === 'error') {
                this.$refs.consultForm.validateField(item.labelFor)
              }
            })
          }
        })
      },
      immediate: true
    }
  },
}
