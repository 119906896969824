//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_pucchase",
  data() {
    return {
      num: 1,
      dialogFormVisible: false,
      purchaseInfo: {},
      orderNo:'',
      resourceNo: '',
      currentNum: '',
      showTip: false,
      tipSubject: '',
      tipContent: '',
    };
  },
  methods: {
    handleClose() {
        this.showTip = false;
        this.$router.push('/orderManage')
    },
    async purchase(resourceNo){
        this.loading = true;
      const Result = await this.$axios.get('/bk/tradingOrder/getOrderNo');
      if(Result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
          path:'/login'
        })
      }
      console.log(Result);
      this.orderNo = Result;
      const params = {
          resourceNo: resourceNo
      }
      const res = await this.$axios.get('/bk/trading/getContainerTradingResource', {
      params: params
      });
      console.log(res);
      this.purchaseInfo = res;
      this.purchaseInfo.containerPhotoUrl1 = !this.purchaseInfo.containerPhotoUrl1 ? this.baseURL + '/resource/img/container_03.png' : this.purchaseInfo.containerPhotoUrl1;
    },

    async confirmPurchase(){

      const params = {
        orderNo: this.orderNo,
        qty:this.currentNum,
        userId:this.getLocalStoreItem("userId"),
        resourceNo:this.purchaseInfo.resourceNo,
        sizeType: this.purchaseInfo.sizeType,
        productionYear : this.purchaseInfo.productionYear,
        containerStatus : this.purchaseInfo.containerStatus,
        pickupLoc: this.purchaseInfo.pickupLoc,
        price: (this.purchaseInfo.price * this.currentNum)
      }

      console.log(params);
      const Result = await this.$axios.post('/bk/tradingOrder/submitOrder', params);

      console.log(Result+'purchase1');

      if(Result === 'success' ){
        this.tipSubject = this.$t('t.send_confirm_tip_order_subject');
        this.tipContent = this.$t('t.send_confirm_tip_order_content');
        this.showTip = true;
      }else {
        this.$message.error(this.$t('t.send_fail_tip_order'))
      }
    }

  },
  created () {
    if(localStorage.getItem('lang')){
        this.$i18n.locale = localStorage.getItem('lang');
    }
    this.resourceNo = this.$route.params.resourceNo;
    this.purchase(this.$route.params.resourceNo);

  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
