//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_demand",
  data(){
    const validateDemandSize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.demand_form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateDemandType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.demand_form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateDemandQuantity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.demand_form_validate_error_tip_quantity')))
      } else {
        return callback()
      }
    }
    const validateDemandCondition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.demand_form_validate_error_tip_condition')))
      } else {
        return callback()
      }
    }
    const validateDemandLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.demand_form_validate_error_lastPickUpDate')))
      } else {
        return callback()
      }
    }
    const validateDemandPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.demand_form_validate_error_tip_pickUpLocation')))
      } else {
        return callback()
      }
    }
    return{
      demandForm:{
        size: '',
        type: '',
        qty: '',
        containerStatus: '',
        forklift: 'yes',
        lockBox: 'yes',
        pickupDate: '',
        pickupLoc: '',
        remark: ''
      },
      demandRules: {
        size: [{ required: true, validator: validateDemandSize, trigger: 'blur' }],
        type: [{ required: true, validator: validateDemandType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateDemandQuantity, trigger: 'blur' }],
        containerStatus: [{ required: true, validator: validateDemandCondition, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validateDemandPickupLoc, trigger: 'blur' }],
        pickupDate: [{ required: true, validator: validateDemandLastPickupDate, trigger: 'blur' }],
      },
      demandInfoList:[],
      showAddDemandDialog: false,
      isEmpty: false,
      showTip: false,
      currentRecordId:'',
      portNameOptions: [],
      loading: false,
    }
  },
  methods:{
    async getAllDemandInfo(){
      const result = await this.$axios.get('/bk/demand/demandList');
      console.log('1')
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }
      if(result.resultList != null ){
        this.demandInfoList = result.resultList;
      }
      this.isEmpty = this.demandInfoList.length === 0;
    },
    continueBrowsing(){
      this.$router.push({
        path:'/containerTrading'
      })
    },
    async addDemandInfo(){
      await this.$refs.demandForm.validate()
      this.demandForm.sizeType = this.demandForm.size + this.demandForm.type;
      this.demandForm.pickupDate = this.dateFormat(this.demandForm.pickupDate);
      const params = {
        ...this.demandForm
        // userId: this.getLocalStoreItem('userId')
      }
      const result = await this.$axios.post('/bk/demand/addDemandDetail', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.showTip = true;
        this.showAddDemandDialog = false
        this.$refs.demandForm.resetFields()
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    handleClose(){
      this.showTip = false
      this.getAllDemandInfo();
    },
    viewDemandDetail(demandNo){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return;
      }
      let url = this.baseURL + '/#/containerDemandDetail/' + demandNo;
      window.open(url);
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.getAllDemandInfo();
    this.searchPort('SHA');
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
}
