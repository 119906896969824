import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/index.vue'
import Home from '../components/home.vue'
import Price from '../components/price.vue'
import Transaction from '../components/transaction.vue'
import About from '../components/about.vue'
import Contact from '../components/contact.vue'
import Register from '../components/register.vue'
import RegisterForm from '../components/register_form.vue'
import RegisterResult from '../components/register_result.vue'
import Login from '../components/platform/login.vue'
import RegisterTrading from '../components/platform/register_trading.vue'
import ContainerTrading from '../components/platform/container_trading.vue'
import ContainerPurchase from '../components/platform/container_purchase'
import oneStopTrading from '../components/platform/one_stop_trading.vue'
import PassReset from '../components/platform/pass_reset'
import PassManage from '../components/platform/pass_manage'
import OrderManage from '../components/platform/order_manage'
import ContainerDelegation from '../components/platform/container_delegation.vue'
import ContainerInquiry from '../components/platform/container_inquiry.vue'
import ContractCustomerService from '../components/platform/contract_customer_service.vue'
import ContainerDetail from '../components/platform/container_detail.vue'
import MobileIndex from '../components/mobile/mobile_index.vue'
import MobileHome from '../components/mobile/mobile_home.vue'
import ContainerDelegationDetail from '../components/platform/container_delegation_detail.vue'
import OrderDetail from '../components/platform/order_detail.vue'
import ProfileInfo from '../components/platform/profile_info.vue'
import MobileAbout from '../components/mobile/mobile_about.vue'
import ContainerFactoryPurchase from '../components/platform/container_factory_purchase.vue'
import FactoryOrderManage from '../components/platform/factory_order_manage.vue'
import FactoryOrderDetail from '../components/platform/factory_order_detail.vue'


import SpotSpace from '../components/platform/spot_space.vue'
import SpotSpaceResourceDetail from '../components/platform/spot_space_resource_detail.vue'
import SpotSpaceInquiryOrder from '../components/platform/spot_space_inquiry_order.vue'
import SpotSpaceInquiryDetail from '../components/platform/spot_space_inquiry_detail.vue'
import SpotSpacePurchase from '../components/platform/spot_space_purchase.vue'
import SpotSpaceOrder from '../components/platform/spot_space_order.vue'
import SpotSpaceOrderDetail from '../components/platform/spot_space_order_detail'
import MobileContact from '../components/mobile/mobile_contact.vue'

import ContainerDepot from '../components/platform/container_depot.vue'
import ContainerDepotDetail from '../components/platform/container_depot_detail.vue'
import ContainerDepotReside from '../components/platform/container_depot_reside.vue'
import DepotInfo from '../components/platform/depot_info.vue'
import ContainerDemand from '../components/platform/container_demand.vue'
import ContainerDemandDetail from '../components/platform/container_demand_detail.vue'
import DemandInfo from '../components/platform/demand_info.vue'


import ContainerLeasing from '../components/platform/container_leasing.vue'
import ContainerLeasingDetail from '../components/platform/container_leasing_detail.vue'
import ContainerLeasingInquiryOrder from '../components/platform/container_leasing_inquiry_order.vue'
import ContainerLeasingInquiryDetail from '../components/platform/container_leasing_inquiry_detail.vue'


import MobileRegister from '../components/mobile/mobile_register.vue'
import MobileRegisterForm from '../components/mobile/mobile_register_form.vue'
import MobileRegisterResult from '../components/mobile/mobile_register_result.vue'
import MobileRegisterTrading from '../components/mobile/platform/mobile_trading_register.vue'
import MobileLoginTrading from '../components/mobile/platform/mobile_trading_login.vue'
import MobileTrading from '../components/mobile/platform/mobile_trading.vue'
import MobileTradingDetail from '../components/mobile/platform/mobile_container_detail.vue'
import MobileOneStopTrading from '../components/mobile/platform/mobile_one_stop_trading.vue'
import MobilePassManage from '../components/mobile/platform/mobile_pass_manage.vue'
import MobilePassReset from '../components/mobile/platform/mobile_pass_reset.vue'
import MobileContainerPurchase from '../components/mobile/platform/mobile_container_purchase.vue'
import MobileOrderManage from '../components/mobile/platform/mobile_order_manage.vue'
import MobileContainerDelegation from '../components/mobile/platform/mobile_delegation_manage.vue'
import MobileDelegationDetail from '../components/mobile/platform/mobile_delegation_detail.vue'
import MobileContainerInquiry from '../components/mobile/platform/mobile_consult_manage.vue'
import MobileConsultDetail from '../components/mobile/platform/mobile_consult_detail.vue'
import MobileContractCustomerService from '../components/mobile/platform/mobile_contract_customer_service.vue'
import MobileOrderDetail from '../components/mobile/platform/mobile_order_detail.vue'
import MobileProfileInfo from '../components/mobile/platform/mobile_profile_info.vue'
import MobileContainerFactory from '../components/mobile/platform/mobile_container_factory_purchase'
import MobileFactoryOrderManage from '../components/mobile/platform/mobile_factory_order_manage.vue'
import MobileFactoryOrderDetail from '../components/mobile/platform/mobile_factory_order_detail.vue'


import MobileSpotSpace from '../components/mobile/platform/mobile_spot.vue'
import MobileSpotResourceDetail from '../components/mobile/platform/mobile_spot_resource_detail.vue'
import MobileSpotSpacePurchase from '../components/mobile/platform/mobile_spot_resource_order.vue'
import MobileSpotSpaceOrder from '../components/mobile/platform/mobile_spot_order_manage.vue'
import MobileSpotSpaceOrderDetail from '../components/mobile/platform/mobile_spot_resource_order_detail.vue'
import MobileSpotSpaceInquiryManage from '../components/mobile/platform/mobile_spot_inquiry_manage.vue'
import MobileSpotSpaceInquiryDetail from '../components/mobile/platform/mobile_spot_inquiry_detail.vue'

import MobileContainerLeasing from '../components/mobile/platform/mobile_container_leasing.vue'
import MobileContainerLeasingDetail from '../components/mobile/platform/mobile_container_leasing_detail.vue'
import MobileLeasingInquiryOrder from '../components/mobile/platform/mobile_leasing_inquiry_order.vue'
import MobileLeasingInquiryDetail from '../components/mobile/platform/mobile_leasing_inquiry_detail.vue'

import MobileContainerDepot from '../components/mobile/platform/mobile_container_depot.vue'
import MobileContainerDepotDetail from '../components/mobile/platform/mobile_container_depot_detail.vue'
import MobileContainerDepotReside from '../components/mobile/platform/mobile_container_depot_reside.vue'
import MobileDepotInfo from '../components/mobile/platform/mobile_depot_info.vue'
import MobileMemberInfo from '../components/mobile/platform/mobile_members_info.vue'

import MobileContainerDemand from '../components/mobile/platform/mobile_container_demand.vue'
import MobileContainerDemandDetail from '../components/mobile/platform/mobile_container_demand_detail.vue'
import MobileDemandInfo from '../components/mobile/platform/mobile_demand_info.vue'
import MobileDemandDetail from '../components/mobile/platform/mobile_demand_detail.vue'


Vue.use(VueRouter)

const pcRoutes = [
  { path: '/', redirect: '/home' },
  { path: '/member', redirect: '/home' },
  { name: 'login', path: '/login', component: Login },
  { path: '/registertrading', component: RegisterTrading },
  { path: '/passReset', component: PassReset },
  {
    path: '/index',
    name: 'index',
    component: Index,
    children: [
      { path: '/home', component: Home },
      { path: '/transaction', component: Transaction },
      { path: '/price', component: Price },
      { path: '/about', component: About },
      { path: '/contact', component: Contact,
        meta: {needLogin: false}
      },
      { path: '/register', component: Register },
      { path: '/registerform', component: RegisterForm },
      { path: '/registerresult', component: RegisterResult },
    ]
  },
  {
    path: '/oneStopTrading',
    name: 'oneStopTrading',
    component: oneStopTrading,
    children: [
      { path: '/containerTrading', component: ContainerTrading},
      { path: '/passManage', component: PassManage ,
        meta: {needLogin: true}
      },
      { name: 'containerPurchase', path: '/containerPurchase/:resourceNo', component: ContainerPurchase,
        meta: {needLogin: true}
      },
      { path: '/orderManage', component: OrderManage ,
        meta: {needLogin: true}
      },
      { path: '/containerDelegation', component: ContainerDelegation,
        meta: {needLogin: true}
      },
      { path: '/containerInquiry', component: ContainerInquiry,
        meta: {needLogin: true}
      },
      { path: '/contractCustomerService', component: ContractCustomerService},
      { path: '/containerDetail/:resourceNo', name: 'containerDetail',component: ContainerDetail},
      { path: '/delegationDetail/:entrustNo', name: 'delegationDetail',component: ContainerDelegationDetail},

      { path: '/orderDetail/:orderNo', name: 'orderDetail',component: OrderDetail},

      { path: '/profileInfo', name: 'profileInfo',component: ProfileInfo},
      { path: '/containerFactoryPurchase', name: 'containerFactoryPurchase',component: ContainerFactoryPurchase},
      { path: '/factoryOrderManage', name: 'factoryOrderManage',component: FactoryOrderManage,
        meta: {needLogin: true}
      },
      { path: '/factoryOrderDetail/:orderNo', name: 'factoryOrderDetail',component: FactoryOrderDetail,
        meta: {needLogin: true}
      },

      { path: '/spotSpace', name: 'spotSpace', component: SpotSpace},
      { path: '/spotSpaceResourceDetail/:resourceNo', component: SpotSpaceResourceDetail},
      { path: '/spotSpaceInquiryOrder', component: SpotSpaceInquiryOrder},
      { path: '/spotSpaceInquiryDetail/:consultNo', component: SpotSpaceInquiryDetail},
      { path: '/spotSpacePurchase/:resourceNo', component: SpotSpacePurchase},
      { path: '/spotSpaceOrder', component: SpotSpaceOrder},
      { path: '/spotSpaceOrderDetail/:orderNo', component: SpotSpaceOrderDetail},

      { path: '/containerDepot', component: ContainerDepot},
      { path: '/containerDepotDetail/:resourceNo', component: ContainerDepotDetail},
      { path: '/containerDepotReside', component: ContainerDepotReside},
      { path: '/depotInfo', component: DepotInfo},
      { path: '/containerDemand', component: ContainerDemand},
      { path: '/containerDemandDetail/:demandNo', component: ContainerDemandDetail},
      { path: '/demandInfo', component: DemandInfo},
      { path: '/containerLeasing', component: ContainerLeasing},
      { path: '/containerLeasingDetail/:resourceNo', component: ContainerLeasingDetail},
      { path: '/containerLeasingInquiryOrder', component: ContainerLeasingInquiryOrder},
      { path: '/containerLeasingInquiryDetail/:consultNo', component: ContainerLeasingInquiryDetail},

    ]
  }
]
const mobileRoutes = [
  { path: '/', redirect: '/home' },
  { path: '/member', redirect: '/home' },
  { path: '/registertrading', component: MobileRegisterTrading },
  { name: 'login',  path: '/login', component: MobileLoginTrading },
  { path: '/passReset', component: MobilePassReset },
  {
    path: '/index',
    name: 'index',
    component: MobileIndex,
    children: [
      { path: '/home', component: MobileHome },
      { path: '/about', component: MobileAbout },
      { path: '/contact', component: MobileContact },
      { path: '/register', component: MobileRegister },
      { path: '/registerform', component: MobileRegisterForm },
      { path: '/registerresult', component: MobileRegisterResult }

    ]
  },
  {
    path: '/oneStopTrading',
    name: 'oneStopTrading',
    component: MobileOneStopTrading,
    children: [
      { path: '/containerTrading', component: MobileTrading },
      { name: 'containerDetail', path: '/containerDetail/:resourceNo', component: MobileTradingDetail,
        meta: {needLogin: true}
      },
      { path: '/passManage', component: MobilePassManage ,
        meta: {needLogin: true}
      },
      { name: 'containerPurchase', path: '/containerPurchase/:resourceNo', component: MobileContainerPurchase ,
        meta: {needLogin: true}
      },
      { path: '/orderManage', component: MobileOrderManage ,
        meta: {needLogin: true}
      },
      { path: '/containerDelegation', component: MobileContainerDelegation,
        meta: {needLogin: true}
      },
      { name: 'delegationDetail', path: '/delegationDetail/:entrustNo', component: MobileDelegationDetail,
        meta: {needLogin: true}
      },
      { path: '/containerInquiry', component: MobileContainerInquiry,
        meta: {needLogin: true}
      },
      { name: 'consultDetail', path: '/consultDetail/:consultNo', component: MobileConsultDetail},
      { path: '/contractCustomerService', component: MobileContractCustomerService},
      { path: '/orderDetail/:orderNo', name: 'orderDetail',component: MobileOrderDetail},
      { path: '/profileInfo', name: 'profileInfo',component: MobileProfileInfo},
      { path: '/containerFactoryPurchase', name: 'profileInfo',component: MobileContainerFactory},
      { path: '/factoryOrderManage', name: 'factoryOrderManage',component: MobileFactoryOrderManage,
        meta: {needLogin: true}
      },
      { path: '/factoryOrderDetail/:orderNo', name: 'factoryOrderDetail',component: MobileFactoryOrderDetail,
        meta: {needLogin: true}
      },
      { path: '/containerDepot', component: MobileContainerDepot},
      { path: '/containerDepotDetail/:resourceNo', component: MobileContainerDepotDetail},
      { path: '/containerDepotReside', component: MobileContainerDepotReside},
      { path: '/depotInfo', component: MobileDepotInfo},
      { path: '/containerDemand', component: MobileContainerDemand},
      { path: '/containerDemandDetail/:demandNo', component: MobileContainerDemandDetail},
      { path: '/demandInfo', component: MobileDemandInfo},
      { path: '/demandDetail/:demandNo', component: MobileDemandDetail},
      { path: '/spotSpace',  name: 'spotSpace', component: MobileSpotSpace},
      { path: '/spotSpaceResourceDetail/:resourceNo', component: MobileSpotResourceDetail},
      { path: '/spotSpacePurchase/:resourceNo', component: MobileSpotSpacePurchase},
      { path: '/spotSpaceOrder', component: MobileSpotSpaceOrder},
      { path: '/spotSpaceOrderDetail/:orderNo', component: MobileSpotSpaceOrderDetail},
      { path: '/spotSpaceInquiryOrder', component: MobileSpotSpaceInquiryManage},
      { path: '/spotSpaceInquiryDetail/:consultNo', component: MobileSpotSpaceInquiryDetail},

      { path: '/containerLeasing', component: MobileContainerLeasing},
      { path: '/containerLeasingDetail/:resourceNo', component: MobileContainerLeasingDetail},
      { path: '/containerLeasingInquiryOrder', component: MobileLeasingInquiryOrder},
      { path: '/containerLeasingInquiryDetail/:consultNo', component: MobileLeasingInquiryDetail},
      { path: '/memberInfo/', component: MobileMemberInfo},
    ]
  }

]
const ISMOBILE = function () {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

const router = new VueRouter({
  routes: ISMOBILE() ? mobileRoutes : pcRoutes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const isLogin = token && token.indexOf('um-code-cookie') >-1
  if (to.meta.needLogin){ // 判断是否需要登录
    if (isLogin) {
      next();
    }else{
      next({
        name: 'login' // 跳转到登录页面name:
      });
    }
  }else{
    next();
  }
});

export default router
