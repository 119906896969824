//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'register',
  data () {
    return {
      quarterMember: true,
      halfYearMember: false,
      yearMember: false,
      showUpgradeButton: true,
      renewVipMenu: '季度',
      showRenewConfirmDialog: false,
      showUpgradeConfirmDialog: false,
      showRenewSuccessDialog: false
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.showUpgradeButton = this.$route.query.type === 'upgrade';
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  methods: {
      applyRegister (val, vipLevel) {
        this.$router.push({ path: `/registerform?type=${val}&vipLevel=${vipLevel}&vipMenu=${this.renewVipMenu}` })
      },
      async renewMember(){
        try {
          const params = {
            username: this.getLocalStoreItem('username'),
            userId: this.getLocalStoreItem('userId'),
            vipMenu: this.renewVipMenu,
          }
          const result = await this.$axios.post('/bk/vipAudit/vipRenew', params);
          if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
              path:'/login'
            })
          }
          if(result === 'success'){
            this.showRenewSuccessDialog = true;
          }else {
            this.$message.error(this.$t('t.contact_IT'))
          }

        }catch (e){
          console.log(e, 'e')
        }
      },
      handleClose(){
        this.$router.push({
          path:'/containerTrading'
        })
      },
      selectQuarterMember(){
        this.quarterMember = true;
        this.halfYearMember = false;
        this.yearMember = false;
        this.renewVipMenu = '季度';
      },
      selectHalfYearMember(){
        this.quarterMember = false;
        this.halfYearMember = true;
        this.yearMember = false;
        this.renewVipMenu = '半年';
      },
      selectYearMember(){
        this.quarterMember = false;
        this.halfYearMember = false;
        this.yearMember = true;
        this.renewVipMenu = '一年';
      }
  }
}
