//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_inquiry",
  data(){
    const validateInquirySize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateInquiryType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateInquiryContainerStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_condition')))
      } else {
        return callback()
      }
    }
    const validateInquiryRequestQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateInquiryPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateInquiryLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_latestPickupDate')))
      } else {
        return callback()
      }
    }
    return{
      editForm:{
        size: '',
        Type: '',
        containerStatus: '',
        requestQty: '',
        pickupLoc: '',
        lastPickupDate: '',
      },
      inquiryRules: {
        size: [{ required: true, validator: validateInquirySize, trigger: 'blur' }],
        type: [{ required: true, validator: validateInquiryType, trigger: 'blur' }],
        requestQty: [{ required: true, validator: validateInquiryContainerStatus, trigger: 'blur' }],
        containerStatus: [{ required: true, validator: validateInquiryRequestQty, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validateInquiryPickupLoc, trigger: 'blur' }],
        lastPickupDate: [{ required: true, validator: validateInquiryLastPickupDate, trigger: 'blur' }],
      },
      inquiryListInfo:[],
      isEmpty: true,
      showInquiryDialog: false,
      deleteInfoDialog: false,
      currentRecordId:'',
      portNameOptions: [],
      loading: false,
    }
  },
  methods:{
    async consultContainer(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/tradingConsult/listContainerConsult', {
        params: params
      });
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }

      this.inquiryListInfo = result;
      this.isEmpty = this.inquiryListInfo.length === 0;
    },
    continueBrowsing(){
      this.$router.push({
        path:'/containerTrading'
      })
    },
    openInquiryDialog(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.editForm = {
        ...item,
        size: item.sizeType.substring(0,2),
        type: item.sizeType.substring(2,4)
      }
      this.showInquiryDialog = true;
    },
    async updateInquiryRecord(){
      await this.$refs.editForm.validate()

      this.editForm.sizeType = this.editForm.size + this.editForm.type;
      this.editForm.lastPickupDate = this.dateFormat(this.editForm.lastPickupDate);
      const params = {
        ...this.editForm,
        userId: this.getLocalStoreItem('userId')
      }
      const result = await this.$axios.post('/bk/tradingConsult/updateConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.item = this.editForm;
        this.showInquiryDialog = false;
        this.$message({
          message: 'Update Success',
          type: 'success'
        });
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    openDeleteDialog(item){
      this.currentRecordId = item.id;
      this.deleteInfoDialog = true;
    },
    async deleteInquiryRecord(){

      const params = {
        id: this.currentRecordId,
        userId: this.getLocalStoreItem('userId')
      }
      const result = await this.$axios.post('/bk/tradingConsult/deleteConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
      this.currentRecordId = '';
      this.deleteInfoDialog = false;
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.consultContainer();
    this.searchPort('SHA');
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  },
  watch: {

  }
}
