//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ServiceType",
  data(){
    return{
      currentModule: 'Trading'
    }
  },
  methods:{
    changeCurrentModule(module){
      this.currentModule = module;
      this.$emit('tabchange', this.currentModule)
    }
  },
  props: {
    unreadCountList: {
      type: Array,
      required: true
    }
  },
  computed: {
    unreadTrading(){
      return this.unreadCountList[0];
    },
    unreadSpot(){
      return this.unreadCountList[1];
    },
    unreadLeasing(){
      return this.unreadCountList[2];
    }
  }
}
