//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LegalNotice from './mobile_legal_notices.vue'
import PrivacyPolicy from './mobile_privacy_policy.vue'
import ServiceAgreement from './mobile_service_agreement.vue'
export default {
  components: {
    LegalNotice,
    PrivacyPolicy,
    ServiceAgreement
  },
  data () {
    const validateCompanyName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.companyNameNotNull')))
      } else {
        return callback()
      }
    }
    const validateLegalRepresentative = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.legalRepresentativeNotNull')))
      } else {
        return callback()
      }
    }
    const validateCompanyAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.companyAddressNotNull')))
      } else {
        return callback()
      }
    }
    const validateCity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.cityNotNull')))
      } else {
        return callback()
      }
    }
    const validateCountry = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.countryNotNull')))
      } else {
        return callback()
      }
    }
    const validateCompanyPhoto = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.companyBusinessLicenseNotNull')))
      } else {
        return callback()
      }
    }
    const validateUnifieDcreditCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.uniformCreditCodeNotNull')))
      } else {
        return callback()
      }
    }
    // const validateCompanyAccount = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error(this.$t('m.companyBankAccountNotNull')))
    //   } else {
    //     return callback()
    //   }
    // }
    // const validateOpeningBank = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error(this.$t('m.bankNameOfAccountNotNull')))
    //   } else {
    //     return callback()
    //   }
    // }
    const validateEmailAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.companyContactEmailNotNull')))
      } else {
        return callback()
      }
    }
    const validateAccountName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.userNameNotNull')))
      } else {
        return callback()
      }
    }
    const validatePosition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.positionNotNull')))
      } else {
        return callback()
      }
    }

    const validateIdCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.IDNumberNotNull')))
      } else {
        return callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.contactEmailNotNull')))
      } else {
        return callback()
      }
    }
    const validateIdCardPhotoFront = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.IDCardFrontSideNotNull')))
      } else {
        return callback()
      }
    }
    const validateIdCardPhotoBack = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('m.IDCardBackSideilNotNull')))
      } else {
        return callback()
      }
    }

    return {
      infoForm: {
        companyName: '',
        legalRepresentative: '',
        companyAddress: '',
        companyPhoto: '',
        unifieDcreditCode: '',
        companyAccount: '',
        openingBank: '',
        emailAddress: '',
        memberType: '',
        memberAccount: [],
        city: '',
        country: '',
        fromStaff: 'Other'
      },
      userForm: {
        accountName: '',
        position: '',
        idCard: '',
        idCardPhotoFront: '',
        idCardPhotoBack: '',
        email: ''
      },
      rules: {
        companyName: [{ required: true, validator: validateCompanyName, trigger: 'blur' }],
        legalRepresentative: [{ required: true, validator: validateLegalRepresentative, trigger: 'blur' }],
        companyAddress: [{ required: true, validator: validateCompanyAddress, trigger: 'blur' }],
        city: [{ required: true, validator: validateCity, trigger: 'blur' }],
        country: [{ required: true, validator: validateCountry, trigger: 'blur' }],
        companyPhoto: [{ required: true, validator: validateCompanyPhoto, trigger: 'blur' }],
        unifieDcreditCode: [{ required: true, validator: validateUnifieDcreditCode, trigger: 'blur' }],
        // companyAccount: [{ required: true, validator: validateCompanyAccount, trigger: 'blur' }],
        // openingBank: [{ required: true, validator: validateOpeningBank, trigger: 'blur' }],
        emailAddress: [{ required: true, validator: validateEmailAddress, trigger: 'blur' }]
      },
      tollFormRules: {
        accountName: [{ required: true, validator: validateAccountName, trigger: 'blur' }],
        position: [{ required: true, validator: validatePosition, trigger: 'blur' }],
        idCard: [{ required: true, validator: validateIdCard, trigger: 'blur' }],
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        idCardPhotoFront: [{ required: true, validator: validateIdCardPhotoFront, trigger: 'blur' }],
        idCardPhotoBack: [{ required: true, validator: validateIdCardPhotoBack, trigger: 'blur' }]
      },
      currentCategoary: 'free',
      vipLevel: 'Free',
      checkedInfo: false,
      // checkedProtocol: false,
      loading: false,
      fileList: [],
      proofImage: '',
      dialogImageUrl: '',
      dialogVisible: false,
      protocolDialog: false,
      countNum: 30,
      timId: '',
      hasReading: false,
      activeName: '1'
    }
  },
  created () {
    this.currentCategoary = this.$route.query.type;
      this.vipLevel = this.$route.query.vipLevel;
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
    tollForm () {
      // 收费
      return this.currentCategoary && this.currentCategoary === 'toll'
    },
    checkedProtocol: {
      get: function () {
        return this.hasReading
      },
      set: function (value) {
        this.hasReading = value
      }
    }
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          this.$refs.infoForm.fields.forEach(item => {
            if (item.validateState === 'error') {
              this.$refs.infoForm.validateField(item.labelFor)
            }
          })
          this.$refs.userForm.fields.forEach(item => {
            if (item.validateState === 'error') {
              this.$refs.userForm.validateField(item.labelFor)
            }
          })
        })
      },
      immediate: true
    }
  },
  methods: {
    confirmReadHandler () {
      this.hasReading = true
      this.protocolDialog = false
    },
    protocalHandler (val) {
      if (val) {
        this.countNum = 30
        this.protocolDialog = true
        this.countdown()
      }
    },
    countdown () {
      this.timId = setInterval(() => {
        if (this.countNum < 1) {
          clearInterval(this.timId)
          return
        }
        this.countNum--
      }, 1000)
    },
    async submitInfo (e) {
      try {
        await this.$refs.infoForm.validate()

        if (this.tollForm) {
          await this.$refs.userForm.validate()
        }

        if (!this.checkedInfo) {
          this.$message.error(this.$t('m.agreeColleFirst'))
          return
        }
        if (!this.checkedProtocol) {
          this.$message.error(this.$t('m.agreeColleFirst'))
          return
        }
        this.loading = true
        // 校验账号是否重复
        const datas = {
          companyEmail: this.userForm.email,
          idCard: this.userForm.idCard
        }
        const validataRes = await this.$axios.post('/api/member/validation', datas)
        // console.log(validataRes, 'validataRes')

        if (validataRes.errcode === '400' && validataRes.status === '1') {
          this.$message({
            message: validataRes.message,
            type: 'error'
          })
          this.loading = false
          return
        }

        this.infoForm.memberType = this.currentCategoary
        this.infoForm.memberAccount.push(this.userForm)
          this.infoForm.vipLevel = this.vipLevel;
        const params = {
          ...this.infoForm,
          username: this.getLocalStoreItem('username'),
          userId: this.getLocalStoreItem('userId')
        }
        const addResult = await this.$axios.post('/api/member/registration', params)
        this.loading = false
        if (addResult.status === null) {
          this.$message.error(addResult.message)
        }
        if (addResult.status === '0') {
          this.$message({
            message: this.$t('m.registeredMemberSuccess'),
            type: 'success'
          })
          this.infoForm = {
            companyName: '',
            legalRepresentative: '',
            companyAddress: '',
            companyPhoto: '',
            unifieDcreditCode: '',
            companyAccount: '',
            openingBank: '',
            emailAddress: '',
            memberType: '',
            memberAccount: [],
            city: '',
            country: '',
            fromStaff: 'Other'
          }
          this.userForm = {
            accountName: '',
            position: '',
            idCard: '',
            idCardPhotoFront: '',
            idCardPhotoBack: '',
            email: ''
          }

          this.$router.push({ path: `/registerresult?type=${this.currentCategoary}` })
        }
        // if (this.Public === true) {
        //   this.infoForm.memberType = 'Public'
        // }
        // if (this.LeasingPass === true) {
        //   this.infoForm.memberType = 'Leasing Pass'
        //   if (this.userForm.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm)
        //   }
        // }
        // if (this.LeasingPro === true) {
        //   this.infoForm.memberType = 'Leasing Pro'
        //   if (this.userForm.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm)
        //   }
        //   if (this.userForm2.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm2)
        //   }
        //   if (this.userForm3.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm3)
        //   }
        // }
        // if (this.LeasingPlatinum === true) {
        //   this.infoForm.memberType = 'Leasing Platinum'
        //   if (this.userForm.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm)
        //   }
        //   if (this.userForm2.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm2)
        //   }
        //   if (this.userForm3.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm3)
        //   }
        //   if (this.userForm4.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm4)
        //   }
        //   if (this.userForm5.accountName !== '') {
        //     this.infoForm.memberAccount.push(this.userForm5)
        //   }
        // }
        // console.log(this.infoForm, 'infoForm')
        // if (con) {
        //   this.loading = true
        //   const res = await this.$refs.infoForm.validate()
        //   if (res) {
        //     const params = {
        //       ...this.infoForm
        //     }
        //     const addResult = await this.$axios.post('/api/member/registration', params)
        //     console.log(addResult, 'addResult')
        //     this.loading = false
        //     if (addResult.status === null) {
        //       this.$message.error(addResult.message)
        //     }
        //     if (addResult.status === '0') {
        //       this.$message({
        //         message: '会员申请提交成功',
        //         type: 'success'
        //       })
        //       this.tips = true
        //       this.infoForm = {
        //         firstName: '',
        //         lastName: '',
        //         emailAddress: '',
        //         company: '',
        //         phone: '',
        //         companyName: '',
        //         legalRepresentative: '',
        //         companyAddress: '',
        //         companyPhoto: '',
        //         unifieDcreditCode: '',
        //         companyAccount: '',
        //         openingBank: '',
        //         countryCity: '',
        //         check: false,
        //         memberType: '',
        //         memberAccount: []
        //       }
        //       this.userForm = {
        //         accountName: '',
        //         position: '',
        //         idCard: '',
        //         idCardPhotoFront: '',
        //         idCardPhotoBack: '',
        //         handheldIdCardPhoto: '',
        //         email: ''
        //       }
        //       this.userForm2 = {
        //         accountName: '',
        //         position: '',
        //         idCard: '',
        //         idCardPhotoFront: '',
        //         idCardPhotoBack: '',
        //         handheldIdCardPhoto: '',
        //         email: ''
        //       }
        //       this.userForm3 = {
        //         accountName: '',
        //         position: '',
        //         idCard: '',
        //         idCardPhotoFront: '',
        //         idCardPhotoBack: '',
        //         handheldIdCardPhoto: '',
        //         email: ''
        //       }
        //       this.userForm4 = {
        //         accountName: '',
        //         position: '',
        //         idCard: '',
        //         idCardPhotoFront: '',
        //         idCardPhotoBack: '',
        //         handheldIdCardPhoto: '',
        //         email: ''
        //       }
        //       this.userForm5 = {
        //         accountName: '',
        //         position: '',
        //         idCard: '',
        //         idCardPhotoFront: '',
        //         idCardPhotoBack: '',
        //         handheldIdCardPhoto: '',
        //         email: ''
        //       }
        //       this.$refs.infoForm.resetFields()
        //     }
        //   }
        // }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        this.proofImage = params
        if (num === 1) {
          this.infoForm.companyPhoto = this.proofImage
        }
        if (num === 2) {
          this.userForm.idCardPhotoFront = this.proofImage
        }
        if (num === 3) {
          this.userForm.idCardPhotoBack = this.proofImage
        }
      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.infoForm.companyPhoto = this.proofImage
      }
      if (num === 2) {
        this.userForm.idCardPhotoFront = this.proofImage
      }
      if (num === 3) {
        this.userForm.idCardPhotoBack = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleSuccess (res, file, num) {
      if (num === 1) {
        this.infoForm.companyPhoto = this.proofImage
      }
      if (num === 2) {
        this.imageUrl2 = URL.createObjectURL(file.raw)
      }
      if (num === 3) {
        this.imageUrl3 = URL.createObjectURL(file.raw)
      }
    }
  }
}
