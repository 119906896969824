//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data(){
    return{
      srcList: [
        this.message.fileUrl
      ]
    }
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    }
  }
}
