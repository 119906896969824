//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_space_order",
  data(){
    const validatePaidVoucherFileContent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_order_orderFileNotNull')))
      } else {
        return callback()
      }
    }
    return{
      isEmpty: true,
      spotSpaceOrderList: [],
      orderForm: {
        paidVoucherFileContent: ''
      },
      rules: {
        paidVoucherFileContent: [{ required: true, validator: validatePaidVoucherFileContent, trigger: 'blur' }],
      },
      fileList: [],
      uploadId: '',
      cancelId: '',
      showUploadDialog: false,
      showCancelOrderDialog : false,
      dialogVisible: false,
      dialogImageUrl: '',
      showCancelTip: false,
      tipSubject: '',
      tipContent: ''
    }
  },
  methods:{
    async spotSpaceOrder(){

      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/spotOrder/listOrder', {
        params: params
      });
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }

      this.spotSpaceOrderList = result;
      console.log(result);
      this.isEmpty = this.spotSpaceOrderList.length === 0;
    },
    orderDetailClicked(item){
      console.log(item)
      try {
        let url = this.baseURL + '/#/spotSpaceOrderDetail/' + item.orderNo;
        window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    uploadpaidVoucherFile(item){
      this.uploadId = item.id;
      this.showUploadDialog = true;
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        console.log(params, 'params')
        this.proofImage = params
        if (num === 1) {
          this.orderForm.paidVoucherFileContent = this.proofImage
        }

      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.orderForm.paidVoucherFileContent = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload(){
      await this.$refs.orderForm.validate();
      const params = {
        id: this.uploadId,
        paidVoucherFileContent: this.orderForm.paidVoucherFileContent
      }
      const result = await this.$axios.post('/bk/spotOrder/uploadPaidVoucher', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.orderForm = {
          paidVoucherFileContent: '',
        }
        this.showUploadDialog = false;
        this.dialogImageUrl = '';
        this.dialogVisible = false;
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    async cancelOrder(){
      try {
        const params ={
          id: this.cancelId
        }
        const Result = await this.$axios.post('/bk/spotOrder/cancelOrder', params);
        if(Result === 401){
          await this.$router.push({
            path: '/login'
          })
        }else if(Result === 'success' ){
            this.showCancelOrderDialog= false;
            this.tipSubject = this.$t('s.spotSpace_order_cancel_send_subject');
            this.tipContent = this.$t('s.spotSpace_order_cancel_send_context');
            this.showCancelTip = true;
        }else {
          this.$message.error('Cancel Order Fail')
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleClose() {
        this.showCancelTip = false;
        this.spotSpaceOrder();
    },
    toReOrder(){
      this.$router.push({
        path:'/spotSpace'
      })
    },
    openCancelOrderDialog(item){
      this.showCancelOrderDialog = true;
      this.cancelId = item.id;
    },
    continueBrowsing(){
      this.$router.push({
        path:'/spotSpace'
      })
    },

  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.resourceNo = this.$route.params.resourceNo;
    this.spotSpaceOrder();
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
