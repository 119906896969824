export default [
  {
    name: 'UMY1',
    umys: [
      {fileName: 'UMY1/01hi.gif', imgUrl: require("../../assets/umy/UMY1/01hi.gif")},
      {fileName: 'UMY1/02hao.gif', imgUrl: require("../../assets/umy/UMY1/02hao.gif")},
      {fileName: 'UMY1/03hehe.gif', imgUrl: require("../../assets/umy/UMY1/03hehe.gif")},
      {fileName: 'UMY1/04weiqu.gif', imgUrl: require("../../assets/umy/UMY1/04weiqu.gif")},
      {fileName: 'UMY1/05touxiao.gif', imgUrl: require("../../assets/umy/UMY1/05touxiao.gif")},
      {fileName: 'UMY1/06ye.gif', imgUrl: require("../../assets/umy/UMY1/06ye.gif")},
      {fileName: 'UMY1/07shangxin.gif', imgUrl: require("../../assets/umy/UMY1/07shangxin.gif")},
      {fileName: 'UMY1/08jingya.gif', imgUrl: require("../../assets/umy/UMY1/08jingya.gif")},
      {fileName: 'UMY1/09qinqin.gif', imgUrl: require("../../assets/umy/UMY1/09qinqin.gif")},
      {fileName: 'UMY1/10zhidaole.gif', imgUrl: require("../../assets/umy/UMY1/10zhidaole.gif")},
      {fileName: 'UMY1/11youxiu.gif', imgUrl: require("../../assets/umy/UMY1/11youxiu.gif")},
      {fileName: 'UMY1/12deyi.gif', imgUrl: require("../../assets/umy/UMY1/12deyi.gif")},
      {fileName: 'UMY1/13suanwoyige.gif', imgUrl: require("../../assets/umy/UMY1/13suanwoyige.gif")},
      {fileName: 'UMY1/14gejuyaoda.gif', imgUrl: require("../../assets/umy/UMY1/14gejuyaoda.gif")},
      {fileName: 'UMY1/15wokankan.gif', imgUrl: require("../../assets/umy/UMY1/15wokankan.gif")},
      {fileName: 'UMY1/16jixuqianjin.gif', imgUrl: require("../../assets/umy/UMY1/16jixuqianjin.gif")},
    ]
  },
  {
    name: 'UMY2',
    umys: [
      {fileName: 'UMY2/01zai.gif', imgUrl: require("../../assets/umy/UMY2/01zai.gif")},
      {fileName: 'UMY2/02yun.gif', imgUrl: require("../../assets/umy/UMY2/02yun.gif")},
      {fileName: 'UMY2/03xiexie.gif', imgUrl: require("../../assets/umy/UMY2/03xiexie.gif")},
      {fileName: 'UMY2/04hen.gif', imgUrl: require("../../assets/umy/UMY2/04hen.gif")},
      {fileName: 'UMY2/05Hi.gif', imgUrl: require("../../assets/umy/UMY2/05Hi.gif")},
      {fileName: 'UMY2/06OK.gif', imgUrl: require("../../assets/umy/UMY2/06OK.gif")},
      {fileName: 'UMY2/07niu.gif', imgUrl: require("../../assets/umy/UMY2/07niu.gif")},
      {fileName: 'UMY2/08shenme.gif', imgUrl: require("../../assets/umy/UMY2/08shenme.gif")},
      {fileName: 'UMY2/09shoudao.gif', imgUrl: require("../../assets/umy/UMY2/09shoudao.gif")},
      {fileName: 'UMY2/10xindong.gif', imgUrl: require("../../assets/umy/UMY2/10xindong.gif")},
      {fileName: 'UMY2/11huanying.gif', imgUrl: require("../../assets/umy/UMY2/11huanying.gif")},
      {fileName: 'UMY2/12ai.gif', imgUrl: require("../../assets/umy/UMY2/12ai.gif")},
      {fileName: 'UMY2/13bixin.gif', imgUrl: require("../../assets/umy/UMY2/13bixin.gif")},
      {fileName: 'UMY2/14xiangxiang.gif', imgUrl: require("../../assets/umy/UMY2/14xiangxiang.gif")},
      {fileName: 'UMY2/15baibai.gif', imgUrl: require("../../assets/umy/UMY2/15baibai.gif")},
      {fileName: 'UMY2/17jiayou.gif', imgUrl: require("../../assets/umy/UMY2/17jiayou.gif")},
      {fileName: 'UMY2/18mashang.gif', imgUrl: require("../../assets/umy/UMY2/18mashang.gif")},
      {fileName: 'UMY2/19ha.gif', imgUrl: require("../../assets/umy/UMY2/19ha.gif")},
      {fileName: 'UMY2/20wanan.gif', imgUrl: require("../../assets/umy/UMY2/20wanan.gif")},
      {fileName: 'UMY2/21nuli.gif', imgUrl: require("../../assets/umy/UMY2/21nuli.gif")},
      {fileName: 'UMY2/22hongbao.gif', imgUrl: require("../../assets/umy/UMY2/22hongbao.gif")},
      {fileName: 'UMY2/23xinku.gif', imgUrl: require("../../assets/umy/UMY2/23xinku.gif")},
      {fileName: 'UMY2/24zaoan.gif', imgUrl: require("../../assets/umy/UMY2/24zaoan.gif")},
      {fileName: 'UMY2/266.gif', imgUrl: require("../../assets/umy/UMY2/266.gif")},
    ]
  },
  {
    name: 'UMY3',
    umys: [

      {fileName: 'UMY3/01huanbao.gif', imgUrl: require("../../assets/umy/UMY3/01huanbao.gif")},
      {fileName: 'UMY3/02huanbaodai.gif', imgUrl: require("../../assets/umy/UMY3/02huanbaodai.gif")},
      {fileName: 'UMY3/03guandeng.gif', imgUrl: require("../../assets/umy/UMY3/03guandeng.gif")},
      {fileName: 'UMY3/04guangpan.gif', imgUrl: require("../../assets/umy/UMY3/04guangpan.gif")},
      {fileName: 'UMY3/05yongzidaibei.gif', imgUrl: require("../../assets/umy/UMY3/05yongzidaibei.gif")},
      {fileName: 'UMY3/06zidaicanju.gif', imgUrl: require("../../assets/umy/UMY3/06zidaicanju.gif")},
      {fileName: 'UMY3/07dabao.gif', imgUrl: require("../../assets/umy/UMY3/07dabao.gif")},
      {fileName: 'UMY3/08zhongshu.gif', imgUrl: require("../../assets/umy/UMY3/08zhongshu.gif")},
      {fileName: 'UMY3/09lajifeilei.gif', imgUrl: require("../../assets/umy/UMY3/09lajifeilei.gif")},
      {fileName: 'UMY3//10baohuhuanjing.gif', imgUrl: require("../../assets/umy/UMY3/10baohuhuanjing.gif")},
      {fileName: 'UMY3/11lizhixiaofei.gif', imgUrl: require("../../assets/umy/UMY3/11lizhixiaofei.gif")},
      {fileName: 'UMY3/12jujueshuliao.gif', imgUrl: require("../../assets/umy/UMY3/12jujueshuliao.gif")},
      {fileName: 'UMY3/13xunhuanliyong.gif', imgUrl: require("../../assets/umy/UMY3/13xunhuanliyong.gif")},
      {fileName: 'UMY3/14baohuhaiyang.gif', imgUrl: require("../../assets/umy/UMY3/14baohuhaiyang.gif")},
      {fileName: 'UMY3/15baohudiqiu.gif', imgUrl: require("../../assets/umy/UMY3/15baohudiqiu.gif")},
      {fileName: 'UMY3/16gongjiaochuxing.gif', imgUrl: require("../../assets/umy/UMY3/16gongjiaochuxing.gif")},

    ]
  },
]
