//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'home',
  data () {
    return {
      menuList: [],
      // localStorage.getItem('lang') ||
      currentLang:  localStorage.getItem('lang')? localStorage.getItem('lang') : 'zh-CN',
      showMenuList: false
    }
  },
  created () {
      this.$i18n.locale = this.currentLang
      this.menuList = [
          { name: this.$t('m.home'), path: '/home' },
          // { name: this.$t('m.blog'), path: '' }, // transaction
          // { name: '服务与价格', path: '/price' },
          { name: this.$t('m.aboutUs'), path: '/about' },
          { name: this.$t('m.contactUs'), path: '/contact' },
          // { name: this.$t('m.register'), path: '/register' }
          // { name: '会员中心', path: '/member' }
          // https://dev-unit-match.cs76.force.com/csmarket/login
      ];
  },
  computed: {
    showCurrentLanguage () {
      if (this.currentLang === 'en-US') return 'EN'
      return 'ZH'
    }
    // currentPath () {
    //   console.log(this.$route.path)
    //   // 通过获取到动态路由的参数进行设置
    //   return this.$route.path
    // }
  },
  methods: {
    navigatorPage (path) {
      console.log(path, 'path')
      if (path) {
        this.$router.push(path)
        this.showMenuList = false
      } else {
        window.open('http://www.unit-match.cn/?page_id=57537')
      }
    },
    switchMenuList () {
      this.showMenuList = !this.showMenuList
    },
    closeMenuList () {
      this.showMenuList = false
    },
    // showlangTab (value) {
    //   return this.currentLang === value ? 'current-language-tab' : 'language-tab'
    // },
    changeLang () {
      this.currentLang = this.currentLang === 'en-US' ? 'zh-CN' : 'en-US'
      // this.currentLang = value
      this.$i18n.locale = this.currentLang
      localStorage.setItem('lang', this.currentLang)
      // // location.reload()
      // console.log(this.$i18n.locale)
      this.updateMenuList()
    },
    jumptoMember () {
      // https:// unit-match.force.com/membermarket/s/login/s
      // window.open('https://unit-match.force.com/membermarket/s')
      window.open('https://unit-match.force.com/marketplace/s/leasing')
    },
    updateMenuList () {
      this.menuList = [
        { name: this.$t('m.home'), path: '/home' },
        // { name: this.$t('m.blog'), path: '' }, // transaction
        // { name: '服务与价格', path: '/price' },
        { name: this.$t('m.aboutUs'), path: '/about' },
        { name: this.$t('m.contactUs'), path: '/contact' },
        // { name: this.$t('m.register'), path: '/register' }
        // { name: '会员中心', path: '/member' }
        // https://dev-unit-match.cs76.force.com/csmarket/login
      ]
    }
  }
}
