//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_container_leasing_detail",
  data(){
    return{
      resourceNo: '',
      item:{},
      showTip: false,
      isVip: false
    }
  },
  methods:{
    async getLeasingResourceDetail () {
      try {
        const params = {
          resourceNo: this.resourceNo
        }
        const res = await this.$axios.get('/bk/leasing/getLeasingResource', {
          params: params
        });
        if(res.resourceNo){
          this.item = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async upgradeMembership () {
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/register?type=upgrade'
      })
    },
    sendLeasingRequest(){
      window.open('https://unit-match.force.com/marketplace/s/leasing')
    },
    handleClose(){
      this.showTip = false;
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.resourceNo = this.$route.params.resourceNo;
    this.getLeasingResourceDetail();
    this.isVip = this.getLocalStoreItem("um-vip") === '1'
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
