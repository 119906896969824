//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Footer from '../components/footer'
export default {
  name: 'home',
  components:{
    Footer
  },
  data () {
    return {
      menuList: [],
      currentLang: localStorage.getItem('lang')? localStorage.getItem('lang') : 'zh-CN',
      // localStorage.getItem('lang') ||
      enzn: [
        { register: this.$t('m.register') }
      ]
    }
  },
  created () {
      this.$i18n.locale = this.currentLang
      this.menuList=[
          { name: this.$t('m.home'), path: '/home' },
          // { name: this.$t('m.blog'), path: '' },
          { name: this.$t('m.aboutUs'), path: '/about' },
          { name: this.$t('m.product'),  path: '' ,
              children: [
                  { id:1, name: this.$t('m.trading_purchase_label'),img:require("@/assets/purchase_container.png") , path: '/containerTrading'},
                  { id:2, name: this.$t('m.trading_space_label'),img:require("@/assets/space_container.png")  },
                  { id:3, name: this.$t('m.trading_lease_label'),img:require("@/assets/lease_container.png") },
                  { id:4, name: this.$t('m.trading_management_label'),img:require("@/assets/management_container.png") }
              ] },
          { name: this.$t('m.contactUs'), path: '/contact' },
          // { name: this.$t('m.home'), path: '/home' },
          // { name: this.$t('m.blog'), path: '' }, // transaction
          // { name: '服务与价格', path: '/price' },
          // { name: this.$t('m.aboutUs'), path: '/about' },
          // { name: this.$t('m.blog'), path: '/contact' },
          // { name: this.$t('m.contactUs'), path: '/about' },
          // { name: this.$t('m.product'), path: '/contact' }
          // { name: '会员中心', path: '/member' }
          // https://dev-unit-match.cs76.force.com/csmarket/login
      ];
  },
  computed: {
    currentPath () {
      console.log(this.$route.path)
      // 通过获取到动态路由的参数进行设置
      return this.$route.path
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key);
      // window.open('https://unit-match.force.com/marketplace/s/leasing')
      // if (key === 1) {
      //   window.open('https://unit-match.force.com/marketplace/s/leasing')
      // }
      // if (key===2) {
      //   window.open('https://baidu.com')
      // }
    },
    showlangTab (value) {
      return this.currentLang === value ? 'current-language-tab' : 'language-tab'
    },
    changeLang (value) {
      this.currentLang = value
      this.$i18n.locale = value
      localStorage.setItem('lang', value)
      // location.reload()
      console.log(this.$i18n.locale)
      this.updateMenuList()
    },
    jumptoMember () {
      // https:// unit-match.force.com/membermarket/s/login/s
      // window.open('https://unit-match.force.com/membermarket/s')
      window.open('https://unit-match.force.com/marketplace/s/leasing')
      // this.$router.push({
      //   path:'/login'
      // })
    },
    jumptoRegister () {
      // https:// unit-match.force.com/membermarket/s/login/s
      // window.open('https://unit-match.force.com/membermarket/s')
      this.$router.push('/register')
    },
    loginMarketplace (key) {

      if(key === 1 ){
        let url = this.baseURL + '/#/containerTrading';
        window.open(url)
      }
      if(key === 2 ){
        let url = this.baseURL + '/#/spotSpace';
        window.open(url)
      }
      if(key === 3 ){
        let url = this.baseURL + '/#/containerLeasing';
        window.open(url)
      }
      if(key === 4){
        window.open('https://unit-match.my.salesforce.com/')
      }
    },

    updateMenuList () {
      this.menuList = [
        { name: this.$t('m.home'), path: '/home' },
        // { name: this.$t('m.blog'), path: '' },
        { name: this.$t('m.aboutUs'), path: '/about' },
        { name: this.$t('m.product'),  path: '' ,
          children: [
            { id:1, name: this.$t('m.trading_purchase_label'),img:require("@/assets/purchase_container.png")},
            { id:2, name: this.$t('m.trading_space_label'),img:require("@/assets/space_container.png")  },
            { id:3, name: this.$t('m.trading_lease_label'),img:require("@/assets/lease_container.png") },
            { id:4, name: this.$t('m.trading_management_label'),img:require("@/assets/management_container.png") }
          ] },
        { name: this.$t('m.contactUs'), path: '/contact' },
        // { name: this.$t('m.home'), path: '/home' },
        // { name: this.$t('m.blog'), path: '' }, // transaction
        // { name: '服务与价格', path: '/price' },
        // { name: this.$t('m.aboutUs'), path: '/about' },
        // { name: this.$t('m.contactUs'), path: '/contact' }
        // { name: '会员中心', path: '/member' }
        // https://dev-unit-match.cs76.force.com/csmarket/login
      ]
    }
  }
}
