//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_depot_info",
  data(){
    const validateCompanyName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_companyName')))
      } else {
        return callback()
      }
    }
    const validateContactNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_contactNumber')))
      } else {
        return callback()
      }
    }
    const validateContactEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_contactEmail')))
      } else {
        return callback()
      }
    }
    const validateCompanyWebsite = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_companyWebsite')))
      } else {
        return callback()
      }
    }
    const validateDepotName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotName')))
      } else {
        return callback()
      }
    }
    const validateDepotCountry = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotCountry')))
      } else {
        return callback()
      }
    }
    const validateDepotCity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotCity')))
      } else {
        return callback()
      }
    }
    const validateDetailedAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_detailedAddressOfTheDepot')))
      } else {
        return callback()
      }
    }
    const validateFromPort = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_fromPort')))
      } else {
        return callback()
      }
    }
    const validateDepotArea = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotArea')))
      } else {
        return callback()
      }
    }
    const validateDepotCapacity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotCapacity')))
      } else {
        return callback()
      }
    }
    const validateThroughput = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_throughput')))
      } else {
        return callback()
      }
    }
    const validateDepotImg = (rule, value, callback) => {
      if (this.editDepotForm.picContent === '') {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotImg')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyFreeStoragePeriod = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_freeStoragePeriod')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyStorageFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_storageFee')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyGateInLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateInLiftingFee')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyGateOutLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateOutLiftingFee')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedFreeStoragePeriod = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_freeStoragePeriod')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedStorageFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_storageFee')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedGateInLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateInLiftingFee')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedGateOutLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateOutLiftingFee')))
      } else {
        return callback()
      }
    }
    return{
      showDepotList: true,
      showDepotInfo: false,
      showEditDepotInfo: false,
      showEditTwentyPrice: false,
      showEditFortyPrice: false,
      showUpdateSuccessDialog: false,
      depotInfoList: [],
      isEmpty: false,
      depotTotal: 0,
      selectedItemIndex: 0,
      editDepotForm: {
        companyName: '',
        depotScope:'',
        contactNumber: '',
        contactEmail: '',
        companyWebsite: '',
        depotName: '',
        country: '',
        city: '',
        detailAddress: '',
        repairService: true,
        hasRepairService: 'Yes',
        nearestPortDistance: '',
        depotArea: '',
        depotCapacity: '',
        throughput: '',
        businessHour: '',
        uninterruptedHours: true,
        workingHours: false,
        startWorkTime: '',
        endWorkTime: '',
        startWorkWeek: '',
        endWorkWeek: '',
        depotPic: '',
        remark: '',
        picContent: '',
        priceMap: {
          20:{},
          40:{}
        }
      },
      editDepotPricesForm:{
        id: '',
        twentyId: '',
        fortyId: '',
        size: '',
        emptyFreeStoragePeriod: '',
        emptyStoragePrice: '',
        emptyGateInPrice: '',
        emptyGateOutPrice: '',
        loadedFreeStoragePeriod: '',
        loadedStoragePrice: '',
        loadedGateInPrice: '',
        loadedGateOutPrice: '',
        twentyFeetEmptyFreeStoragePeriod: '',
        twentyFeetEmptyStorageFee: '',
        twentyFeetEmptyGateInLiftingFee: '',
        twentyFeetEmptyGateOutLiftingFee: '',
        twentyFeetLoadedFreeStoragePeriod: '',
        twentyFeetLoadedStorageFee: '',
        twentyFeetLoadedGateInLiftingFee: '',
        twentyFeetLoadedGateOutLiftingFee: '',
        fortyFeetEmptyFreeStoragePeriod: '',
        fortyFeetEmptyStorageFee: '',
        fortyFeetEmptyGateInLiftingFee: '',
        fortyFeetEmptyGateOutLiftingFee: '',
        fortyFeetLoadedFreeStoragePeriod: '',
        fortyFeetLoadedStorageFee: '',
        fortyFeetLoadedGateInLiftingFee: '',
        fortyFeetLoadedGateOutLiftingFee: '',
      },
      depotRules:{
        companyName: [{ required: true, validator: validateCompanyName, trigger: 'blur' }],
        contactNumber: [{ required: true, validator: validateContactNumber, trigger: 'blur' }],
        contactEmail: [{ required: true, validator: validateContactEmail, trigger: 'blur' }],
        companyWebsite: [{ required: true, validator: validateCompanyWebsite, trigger: 'blur' }],
        depotName: [{ required: true, validator: validateDepotName, trigger: 'blur' }],
        country: [{ required: true, validator: validateDepotCountry, trigger: 'blur' }],
        city: [{ required: true, validator: validateDepotCity, trigger: 'blur' }],
        detailAddress: [{ required: true, validator: validateDetailedAddress, trigger: 'blur' }],
        nearestPortDistance: [{ required: true, validator: validateFromPort, trigger: 'blur' }],
        depotArea: [{ required: true, validator: validateDepotArea, trigger: 'blur' }],
        depotCapacity: [{ required: true, validator: validateDepotCapacity, trigger: 'blur' }],
        throughput: [{ required: true, validator: validateThroughput, trigger: 'blur' }],
        depotImage: [{ required: true, validator: validateDepotImg, trigger: 'blur' }]
      },
      depotPriceRules:{
        twentyFeetEmptyFreeStoragePeriod: [{ required: true, validator: validateFeetEmptyFreeStoragePeriod, trigger: 'blur' }],
        twentyFeetEmptyStorageFee: [{ required: true, validator: validateFeetEmptyStorageFee, trigger: 'blur' }],
        twentyFeetEmptyGateInLiftingFee: [{ required: true, validator: validateFeetEmptyGateInLiftingFee, trigger: 'blur' }],
        twentyFeetEmptyGateOutLiftingFee: [{ required: true, validator: validateFeetEmptyGateOutLiftingFee, trigger: 'blur' }],
        twentyFeetLoadedFreeStoragePeriod: [{ required: true, validator: validateFeetLoadedFreeStoragePeriod, trigger: 'blur' }],
        twentyFeetLoadedStorageFee: [{ required: true, validator: validateFeetLoadedStorageFee, trigger: 'blur' }],
        twentyFeetLoadedGateInLiftingFee: [{ required: true, validator: validateFeetLoadedGateInLiftingFee, trigger: 'blur' }],
        twentyFeetLoadedGateOutLiftingFee: [{ required: true, validator: validateFeetLoadedGateOutLiftingFee, trigger: 'blur' }],
        fortyFeetEmptyFreeStoragePeriod: [{ required: true, validator: validateFeetEmptyFreeStoragePeriod, trigger: 'blur' }],
        fortyFeetEmptyStorageFee: [{ required: true, validator: validateFeetEmptyStorageFee, trigger: 'blur' }],
        fortyFeetEmptyGateInLiftingFee: [{ required: true, validator: validateFeetEmptyGateInLiftingFee, trigger: 'blur' }],
        fortyFeetEmptyGateOutLiftingFee: [{ required: true, validator: validateFeetEmptyGateOutLiftingFee, trigger: 'blur' }],
        fortyFeetLoadedFreeStoragePeriod: [{ required: true, validator: validateFeetLoadedFreeStoragePeriod, trigger: 'blur' }],
        fortyFeetLoadedStorageFee: [{ required: true, validator: validateFeetLoadedStorageFee, trigger: 'blur' }],
        fortyFeetLoadedGateInLiftingFee: [{ required: true, validator: validateFeetLoadedGateInLiftingFee, trigger: 'blur' }],
        fortyFeetLoadedGateOutLiftingFee: [{ required: true, validator: validateFeetLoadedGateOutLiftingFee, trigger: 'blur' }],
      },
      fileList: [],
      proofImage: '',
      dialogImageUrl: '',
      dialogVisible: false,
      countryOptions: [],
      cityOptions: [],
      loading: false,
      currentViewDepotNo: '',
    }
  },
  created() {
    this.getAllDepotInfo()
  },
  methods:{
    async getAllDepotInfo () {
      try {
        this.loading = true;
        const res = await this.$axios.get('/bk/depot/myDepot')
        if(res === 401) {
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }
        if(res.resultList.length > 0){
          this.depotInfoList = res.resultList
          this.depotTotal = res.resultList.length
          this.depotInfoList = [];
          if(res.resultList.length > 0){
            this.depotInfoList = res.resultList
          }
          this.currentViewDepotNo = this.depotInfoList[0].depotNo
          await this.getCurrentDepotInfo()
        }else if (res.resultList.length <= 0) {
          this.isEmpty = true
        }

      } catch (e) {
        console.log(e, 'e')
      }
    },
    async getCurrentDepotInfo () {
      try {
        const param = {
          depotNo: this.currentViewDepotNo
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/depot/getDepotInfo', {
          params: param
        })
        if(res === 401) {
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }
        if(res.depotNo){

          this.editDepotForm = {
            ...res
          }
          if(this.editDepotForm.businessHour === '7*24'){
            this.editDepotForm.uninterruptedHours = true
          }else {
            this.editDepotForm.workingHours = true
            const dateObj = this.extractedTimes(this.editDepotForm.businessHour)
            this.editDepotForm.startWorkWeek = dateObj.startDay
            this.editDepotForm.endWorkWeek = dateObj.endDay
            this.editDepotForm.startWorkTime = dateObj.startTime
            this.editDepotForm.endWorkTime = dateObj.endTime
          }
          this.editDepotForm.hasRepairService = this.editDepotForm.repairService ? 'Yes' : 'No'
          this.editDepotPricesForm.twentyId = this.editDepotForm.priceMap['20'].id
          this.editDepotPricesForm.fortyId = this.editDepotForm.priceMap['40'].id
          this.editDepotPricesForm.twentyFeetEmptyFreeStoragePeriod = this.editDepotForm.priceMap['20'].emptyFreeStoragePeriod
          this.editDepotPricesForm.twentyFeetEmptyFreeStoragePeriod = this.editDepotForm.priceMap['20'].emptyFreeStoragePeriod
          this.editDepotPricesForm.twentyFeetEmptyStorageFee = this.editDepotForm.priceMap['20'].emptyStoragePrice
          this.editDepotPricesForm.twentyFeetEmptyGateInLiftingFee = this.editDepotForm.priceMap['20'].emptyGateInPrice
          this.editDepotPricesForm.twentyFeetEmptyGateOutLiftingFee = this.editDepotForm.priceMap['20'].emptyGateOutPrice
          this.editDepotPricesForm.twentyFeetLoadedFreeStoragePeriod = this.editDepotForm.priceMap['20'].loadedFreeStoragePeriod
          this.editDepotPricesForm.twentyFeetLoadedStorageFee = this.editDepotForm.priceMap['20'].loadedStoragePrice
          this.editDepotPricesForm.twentyFeetLoadedGateInLiftingFee = this.editDepotForm.priceMap['20'].loadedGateInPrice
          this.editDepotPricesForm.twentyFeetLoadedGateOutLiftingFee = this.editDepotForm.priceMap['20'].loadedGateOutPrice

          this.editDepotPricesForm.fortyFeetEmptyFreeStoragePeriod = this.editDepotForm.priceMap['40'].emptyFreeStoragePeriod
          this.editDepotPricesForm.fortyFeetEmptyStorageFee = this.editDepotForm.priceMap['40'].emptyStoragePrice
          this.editDepotPricesForm.fortyFeetEmptyGateInLiftingFee = this.editDepotForm.priceMap['40'].emptyGateInPrice
          this.editDepotPricesForm.fortyFeetEmptyGateOutLiftingFee = this.editDepotForm.priceMap['40'].emptyGateOutPrice
          this.editDepotPricesForm.fortyFeetLoadedFreeStoragePeriod = this.editDepotForm.priceMap['40'].loadedFreeStoragePeriod
          this.editDepotPricesForm.fortyFeetLoadedStorageFee = this.editDepotForm.priceMap['40'].loadedStoragePrice
          this.editDepotPricesForm.fortyFeetLoadedGateInLiftingFee = this.editDepotForm.priceMap['40'].loadedGateInPrice
          this.editDepotPricesForm.fortyFeetLoadedGateOutLiftingFee = this.editDepotForm.priceMap['40'].loadedGateOutPrice

        }

      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleSelectDepot (index) {
      this.selectedItemIndex = index
      this.currentViewDepotNo = this.depotInfoList[index].depotNo
      this.getCurrentDepotInfo()
      this.showDepotList = false
      this.showDepotInfo = true
    },
    extractedTimes(workDateStr) {
      const [weekdays, time] = workDateStr.split(' ');
      const [startTime, endTime] = time.split('-');
      const [startDay, endDay] = weekdays.split('-');
      return {
        startDay: startDay,
        endDay: endDay,
        startTime: startTime,
        endTime: endTime
      };
    },
    handleEditDepotBasicInfo (){
      this.showDepotInfo = false
      this.showEditDepotInfo = true
    },
    async editDepotBasicInfo () {
      try {
        await this.$refs.editDepotForm.validate()
        this.editDepotForm.depotScope = this.editDepotForm.country === 'China' ? 'Domestic Depot' : 'Overseas Depot'
        this.editDepotForm.businessHour =  this.editDepotForm.uninterruptedHours ? '7*24' : this.editDepotForm.startWorkWeek +'-'+ this.editDepotForm.endWorkWeek + ' ' + this.editDepotForm.startWorkTime  +'-'+  this.editDepotForm.endWorkTime;
        const params = {
          ...this.editDepotForm
        }

        const result = await this.$axios.post('/bk/depot/updateDepotInfo', params);
        if (result === 'success') {
          this.showUpdateSuccessDialog = true
        }else if(result === 401) {
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })

        }else{
          this.$message.error(this.$t('t.contact_IT'))
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleEditDepotPriceInfo (size) {
      this.showDepotInfo = false
      if(size === '20'){
        this.showEditTwentyPrice = true
      }
      if(size === '40'){
        this.showEditFortyPriceDialog = true
      }
    },
    async editDepotPriceInfo (size) {
      try {
        await this.$refs.editDepotPricesForm.validate()
        this.editDepotPricesForm.size = size
        if(size === '20'){
          this.editDepotPricesForm.id = this.editDepotPricesForm.twentyId
          this.editDepotPricesForm.emptyFreeStoragePeriod = this.editDepotPricesForm.twentyFeetEmptyFreeStoragePeriod
          this.editDepotPricesForm.emptyStoragePrice = this.editDepotPricesForm.twentyFeetEmptyStorageFee
          this.editDepotPricesForm.emptyGateInPrice = this.editDepotPricesForm.twentyFeetEmptyGateInLiftingFee
          this.editDepotPricesForm.emptyGateOutPrice = this.editDepotPricesForm.twentyFeetEmptyGateOutLiftingFee
          this.editDepotPricesForm.loadedFreeStoragePeriod = this.editDepotPricesForm. twentyFeetLoadedFreeStoragePeriod
          this.editDepotPricesForm.loadedStoragePrice = this.editDepotPricesForm.twentyFeetLoadedStorageFee
          this.editDepotPricesForm.loadedGateInPrice = this.editDepotPricesForm.twentyFeetLoadedGateInLiftingFee
          this.editDepotPricesForm.loadedGateOutPrice = this.editDepotPricesForm. twentyFeetLoadedGateOutLiftingFee
        }else if(size === '40'){
          this.editDepotPricesForm.id = this.editDepotPricesForm.fortyId
          this.editDepotPricesForm.emptyFreeStoragePeriod = this.editDepotPricesForm.fortyFeetEmptyFreeStoragePeriod
          this.editDepotPricesForm.emptyStoragePrice = this.editDepotPricesForm.fortyFeetEmptyStorageFee
          this.editDepotPricesForm.emptyGateInPrice = this.editDepotPricesForm.fortyFeetEmptyGateInLiftingFee
          this.editDepotPricesForm.emptyGateOutPrice = this.editDepotPricesForm.fortyFeetEmptyGateOutLiftingFee
          this.editDepotPricesForm.loadedFreeStoragePeriod = this.editDepotPricesForm. fortyFeetLoadedFreeStoragePeriod
          this.editDepotPricesForm.loadedStoragePrice = this.editDepotPricesForm.fortyFeetLoadedStorageFee
          this.editDepotPricesForm.loadedGateInPrice = this.editDepotPricesForm.fortyFeetLoadedGateInLiftingFee
          this.editDepotPricesForm.loadedGateOutPrice = this.editDepotPricesForm. fortyFeetLoadedGateOutLiftingFee
        }
        const params = {
          ...this.editDepotPricesForm
        }

        const result = await this.$axios.post('/bk/depot/updateDepotPrice', params);
        if (result === 'success') {
          if(size === '20'){
            this.showEditTwentyPriceDialog = false
          }else if(size === '40'){
            this.showEditFortyPriceDialog = false
          }
          this.showUpdateSuccessDialog = true
          this.getCurrentDepotInfo()
        }else if(result === 401) {
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })

        }else{
          this.$message.error(this.$t('t.contact_IT'))
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleClose() {
      console.log('1')
      this.showUpdateSuccessDialog = false;
      if(this.showEditDepotInfo){
        this.showEditDepotInfo = false
      }
      if(this.showEditTwentyPrice){
        this.showEditTwentyPrice = false
      }
      if(this.showEditFortyPrice){
        this.showEditFortyPrice = false
      }
      this.showDepotInfo = true

    },
    depotJoin(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerDepotReside'
      })
    },
    workingHoursChange(e){
      if(this.editDepotForm.uninterruptedHours){
        this.editDepotForm.uninterruptedHours = false;
      }else {
        this.editDepotForm.workingHours = true;
      }
    },
    businessHourChange(e){
      if(this.editDepotForm.workingHours){
        this.editDepotForm.workingHours = false;
      }else {
        this.editDepotForm.uninterruptedHours = true;
      }
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        this.proofImage = params
        if (num === 1) {
          this.editDepotForm.picContent = this.proofImage
        }
      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.infoForm.companyPhoto = this.proofImage
      }
      if (num === 2) {
        this.userForm.idCardPhotoFront = this.proofImage
      }
      if (num === 3) {
        this.userForm.idCardPhotoBack = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    filterCountry(query){
      this.searchCountry(query,);
    },
    filterCity(query){
      this.searchCity(query);
    },
    async searchCountry(query){
      try {
        const param = {
          countryName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/filterCountry', {
          params: param
        })
        this.loading = false;

        this.countryOptions = res;

      } catch (e) {
        console.log(e, 'e')
      }
    },
    async searchCity(query){
      try {
        const param = {
          countryName: this.editDepotForm.country,
          cityName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/filterCity', {
          params: param
        })
        this.loading = false;

        this.cityOptions = res;


      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleReturnDepotList (){
      this.showDepotInfo = false
      this.showDepotList = true
    },
    editDepotBasicCancel(){
      this.showEditDepotInfo = false
      this.showDepotInfo = true
    },
    editDepotPriceCancel(){
      this.showDepotInfo = true
      if(size === '20'){
        this.showEditTwentyPrice = false
      }
      if(size === '40'){
        this.showEditFortyPriceDialog = false
      }
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
