//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_delegation_detail",
  data(){
    return{
      item: {},
      entrustNo: ''
    }
  },
  methods:{
    async getDetail () {
        try {
            const param = {
                entrustNo: this.entrustNo
            }
            this.loading = true;
            const res = await this.$axios.get('/bk/tradingEntrust/getDetail', {
                params: param
            })
            this.loading = false;
            if(res.entrustNo){
                this.item = res;
                if(!this.item.containerPhotoUrl){
                    this.item.containerPhotoUrl = this.baseURL + '/resource/img/container_03.png'
                }
            }
        } catch (e) {
            console.log(e, 'e')
        }
    },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.entrustNo = this.$route.params.entrustNo;
    this.getDetail();
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
