//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_detail",
  data(){
    return {
      item: {},
      firstUrl:'',
      secondUrl:'',
      lastUrl:'',
      srcList: [],
      resourceNo: ''
    }
  },
  methods:{
    jumpToPurchase () {
        this.$router.push({
            path: '/containerPurchase/' + this.item.resourceNo
        });
    },
    async getDetail () {
          try {
              const param = {
                  resourceNo: this.resourceNo
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/trading/getContainerTradingResource', {
                  params: param
              })
              this.loading = false;
              if(res.resourceNo){
                  this.item = res;
                  this.firstUrl = !this.item.containerPhotoUrl1 ? this.baseURL + '/resource/img/container_03.png'  : this.item.containerPhotoUrl1;
                  this.secondUrl = !this.item.containerPhotoUrl2 ? this.baseURL + '/resource/img/container_03.png' : this.item.containerPhotoUrl2;
                  this.lastUrl = !this.item.containerPhotoUrl3 ? this.baseURL + '/resource/img/container_03.png' : this.item.containerPhotoUrl3;
                  this.srcList = [this.firstUrl,this.secondUrl,this.lastUrl];
              }
          } catch (e) {
              console.log(e, 'e')
          }
      },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.resourceNo = this.$route.params.resourceNo;
      this.getDetail();

  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
