//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "pass_reset",
  data(){
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.passReset_form_emailNotNull')))
      } else {
        return callback()
      }
    }
    const validateEmailVerifyCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.passReset_form_emailVerificationCodeNotNull')))
      } else {
        return callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.passReset_form_newPasswordNotNull')))
      } else {
        return callback()
      }
    }
    const validatePasswordConfirmation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.passReset_form_confirmNewPasswordNotNull')))
      } else if(value !== this.resetPassForm.password){
        return callback(new Error(this.$t('t.passReset_form_confirmPassWordInconsistent')))
      }
      else {
        return callback()
      }
    }
    return{
      resetPassForm:{
        email: '',
        emailVerifyCode: '',
        password: '',
        confirmPassword: '',
      },
      rules: {
        email: [{ required: true, validator: validateEmail, trigger: 'blur' }],
        emailVerifyCode: [{ required: true, validator: validateEmailVerifyCode, trigger: 'blur' }],
        password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: validatePasswordConfirmation, trigger: 'blur' }]
      },
      showErrorMessage: false,
      isCounting: false,
      countdown: 60
    }
  },
  computed: {
    buttonText() {
      return this.isCounting ? `${this.countdown}` + this.$t('t.passReset_form_emailEmailVerificationCodeResend') : this.$t('t.passReset_form_getEmailVerificationCode');
    },
    currentLang () {
        return this.$i18n.locale
    }
  },
  methods:{
    startCountdown() {
      if(!this.resetPassForm.email){
        this.$message({
          message: this.$t('t.email_VerificationEmailNotNull'),
          type: 'warning'
        });
        return;
      }
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(this.resetPassForm.email)) {
        this.$message({
          message: this.$t('t.email_VerificationEmailFormat'),
          type: 'warning'
        });
        return;
      }
      if (!this.isCounting) {
        const button = document.querySelector('.color-change-button');
        button.classList.add('clicked');
        var me = this;
        this.sendEmailVerifyCode().then(function (result) {
            if(result){
                me.isCounting = true;
                me.updateCountdown();
            }
        });
      }
    },
    updateCountdown() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.updateCountdown();
        }, 1000);
      } else {
        this.isCounting = false;
        this.countdown = 60;
      }
    },
    async  sendEmailVerifyCode(){
      try {
        const params = {
          email:this.resetPassForm.email,
          opType:'resetPwd'
        }
        console.log(params);
        const res = await this.$axios.get('/bk/auth/sendCode',{
          params:params
        });
        if (res === 'User not exist.') {
            this.$message.error('User not exist.')
            return false;
        }
      } catch (e) {
        console.log(e, 'e')
      }
        return true;
    },
    async submitInfo (e) {
      try {
        await this.$refs.resetPassForm.validate()

        const params = {
          ...this.resetPassForm,
          username: this.resetPassForm.email

        }
        delete params.confirmPassword;

        console.log(JSON.stringify(params) + 'params')

        const addResult = await this.$axios.post('/bk/auth/resetPwd', params)
        this.loading = false
        console.log(addResult.message + 'status');

        if (addResult.message.indexOf('Error') >-1) {
          this.$message.error(addResult.message)
        }else if (addResult.message === 'update success') {
            localStorage.removeItem('token');
            localStorage.removeItem("username");
            localStorage.removeItem("userId");
            localStorage.removeItem("um-vip");
            localStorage.removeItem("depotVip");
            localStorage.removeItem("role");
            localStorage.removeItem("lastRefreshTime");
            this.$message({
              message: 'Reset Password Success',
              type: 'success'
            });
            this.$router.push({
                path:'/login'
            })
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
  }
}
