//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_order_detail",
  data(){
    const validatePaidVoucherFileContent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('上传文件不能为空'))
      } else {
        return callback()
      }
    }
    return {
      item: {},
      uploadId: '',
      showUploadDialog: false,
      orderForm: {
        paidVoucherFileContent: ''
      },
      proofImage: '',
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        paidVoucherFileContent: [{ required: true, validator: validatePaidVoucherFileContent, trigger: 'blur' }],
      },
      fileList: [],
      showTip: false,
      cancelItem: {},
      orderNo: '',
      showCancelTip: false,
      tipSubject: '',
      tipContent: '',
      showPaidVoucher: false,
      paidVoucherUrl: ''
    }
  },
  methods:{
    async cancelOrder() {
      console.log(this.cancelItem)
      try {
        const params ={
          id: this.cancelItem.id
        }
        const Result = await this.$axios.post('/bk/tradingOrder/cancelOrder', params);
        if(Result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }else if(Result === 'success' ){
          this.showTip= false;
          this.cancelItem = {};
          this.tipSubject = this.$t('t.trading_order_cancel_send_subject');
          this.tipContent = this.$t('t.trading_order_cancel_send_context');
          this.showCancelTip = true;
        }else {
          this.$message.error('Cancel Order Fail')
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    viewPaidVoucher(url){
      this.showPaidVoucher = true;
      this.paidVoucherUrl = url;
    },
    paidVoucherHandleClose(){
      this.showPaidVoucher = false;
      this.paidVoucherUrl = '';
    },
    handleClose() {
        this.showCancelTip = false;
        this.$router.push({
            path:'/orderManage'
        })
    },
    toOrder(){
      this.$router.push({
        path:'/containerTrading'
      })
    },
    uploadPaidVoucher(item){
      this.uploadId = item.id;
      this.showUploadDialog = true;
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        console.log(params, 'params')
        this.proofImage = params
        if (num === 1) {
          this.orderForm.paidVoucherFileContent = this.proofImage
        }

      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.orderForm.paidVoucherFileContent = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async upload(){
      await this.$refs.orderForm.validate();
      const params = {
        id: this.uploadId,
        paidVoucherFileContent: this.orderForm.paidVoucherFileContent
      }
      const result = await this.$axios.post('/bk/tradingOrder/uploadPaidVoucher', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.orderForm = {
          paidVoucherFileContent: '',
        }
        this.showUploadDialog = false;
        this.dialogImageUrl = '';
        this.dialogVisible = false;
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    cancel(item){
      if(item.orderStatus !== 'Cancelled' ||  item.orderStatus !== 'Completed' ){
        this.showTip= true;
        this.cancelItem = item;
      }

    },
    async getDetail () {
          try {
              const param = {
                  orderNo: this.orderNo
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/tradingOrder/getDetail', {
                  params: param
              })
              this.loading = false;
              if(res.orderNo){
                  this.item = res;
                  this.item.containerPhotoUrl1 = !this.item.containerPhotoUrl1 ? this.baseURL + '/resource/img/container_03.png' : this.item.containerPhotoUrl1;
                  this.item.containerPhotoUrl2 = !this.item.containerPhotoUrl2 ? this.baseURL + '/resource/img/container_03.png' : this.item.containerPhotoUrl2;
                  this.item.containerPhotoUrl3 = !this.item.containerPhotoUrl3 ? this.baseURL + '/resource/img/container_03.png' : this.item.containerPhotoUrl3;
              }
          } catch (e) {
              console.log(e, 'e')
          }
    },
    downloadVoucher(url){
      window.open(url);
    }
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.orderNo = this.$route.params.orderNo;
      this.getDetail();
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
