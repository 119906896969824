//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data(){
    return{
    }
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    },
    srcList: {
      type: Array,
      required: true
    }
  }
}
