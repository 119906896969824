//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'contact',
  data () {
    return {
      infoForm: {
        name: '',
        email: '',
        phoneNumber: '',
        message: ''
      },
      rules: {
        name: [{ required: true, message: this.$t('m.nameNotEmpty'), trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('m.emailNotEmpty'), trigger: 'blur' },
          { type: 'email', message: this.$t('m.errorEmailAddress'), trigger: ['blur', 'change'] }
        ],
        phoneNumber: [{ required: true, message: this.$t('m.phoneNotEmpty'), trigger: 'blur' }]
      },
      menuList: [
        { contacttitle: this.$t('c.contacttitle') },
        { addresstitle: this.$t('c.addresstitle') },
        { addresstitle1: this.$t('c.addresstitle1') },
        { addresstinfo1: this.$t('c.addresstinfo1') },
        { addresstitle2: this.$t('c.addresstitle2') },
        { addresstinfo2: this.$t('c.addresstinfo2') },
        { addresstitle3: this.$t('c.addresstitle3') },
        { addresstinfo3: this.$t('c.addresstinfo3') },
        { addresstitle4: this.$t('c.addresstitle4') },
        { addresstinfo4: this.$t('c.addresstinfo4') },
        { name: this.$t('c.name') },
        { email: this.$t('c.email') },
        { tel: this.$t('c.tel') },
        { message: this.$t('c.message') },
        { button: this.$t('c.button') }
      ]
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  methods: {
    async submitInfo () {
      try {
        this.loading = true
        const res = await this.$refs.infoForm.validate()
        if (res) {
          console.log(this.infoForm)
          const params = {
            ...this.infoForm
          }
          const addResult = await this.$axios.post('/api/visitor/add', params)
          console.log(addResult, 'addResult')
          this.loading = false
          if (addResult.status === 'FAIL') {
            this.$message.error(addResult.message)
          }
          if (addResult.status === 'SUCCESS') {
            this.$message({
              message: 'Information has been submitted',
              type: 'success'
            })
            this.dialogInfo = false
            this.infoForm = {
              name: '',
              message: '',
              email: '',
              phoneNumber: ''
            }
            this.$refs.infoForm.resetFields()
          }
        }
      } catch (e) {
        console.log(e, 'e')
      }
    }
  }
}
