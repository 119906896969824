//
//
//
//
//
//
//
//
//

import OpenIcon from "../../../../assets/logo-no-bg.svg";
import CloseIcon from "../../../../assets/close-icon.png";
import FileIcon from "../../../../assets/file.svg";
import CloseIconSvg from "../../../../assets/close.svg";

export default {
  data(){
    return{
      content: ''
    }
  },
  created(){
      this.content = this.baseURL + '/resource/umy/' + this.message.content;
  },
  props: {
      message: {
      type: Object,
      required: true
    }
  }
}
