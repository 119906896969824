import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import moment from 'moment'
import i18n from './i18n/index'
import '../src/assets/css/font.css'
import animated from 'animate.css'
import 'video.js/dist/video-js.css'
import VnCountryIntl from 'vue-country-intl'
import 'vue-country-intl/lib/vue-country-intl.css'
import VueScroller from "vue-scroller";
import VTooltip from 'v-tooltip'
Vue.use(ElementUI)
Vue.use(animated)
Vue.use(VueScroller)
Vue.use(VTooltip)
Vue.component(VnCountryIntl.name, VnCountryIntl);

// 定义全局过滤器
Vue.filter('data', function (value) {
  return moment(value).format('YYYY-MM-DD')
  // HH: mm: ss
})

Vue.prototype.baseURL = process.env.VUE_APP_BASE_API
Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.prototype.getLocalStoreItem = function(key){
  if(key === 'token' || key === 'username' || key === 'userId' || key === 'role'){
    if(localStorage.getItem('lastRefreshTime') && (new Date().getTime()) - localStorage.getItem('lastRefreshTime') < 1000 * 60 * 30){
      return localStorage.getItem(key);
    }else{
      localStorage.removeItem('token');
      localStorage.removeItem("username");
      localStorage.removeItem("userId");
      localStorage.removeItem("um-vip");
      localStorage.removeItem("depotVip");
      localStorage.removeItem("role");
      localStorage.removeItem("lastRefreshTime");
      return null;
    }
  }
  return localStorage.getItem(key);
}

// 设置全局地址
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

// 设置全局请求拦截
axios.interceptors.request.use(function (config) {
  console.log(process.env.VUE_APP_BASE_API, 'env')
  // if(config.url.indexOf('bk') && config.url.indexOf('bk') != -1 && localStorage.getItem('token')){
  //   config.headers.token = localStorage.getItem('token');
  // }
  if(localStorage.getItem('token')){
    config.headers.token = localStorage.getItem('token');
    config.headers.userName = localStorage.getItem('username');
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.status === 200 && response.headers["token"]){
    let array = response.headers["token"].split(';');
    localStorage.setItem('token', array[0]);
    localStorage.setItem('lastRefreshTime', (new Date()).getTime());
  }
  return response.data
}, function (error) {
  // 对响应错误做点什么
  if(error.response.status === 401){
    localStorage.removeItem('token');
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("um-vip");
    localStorage.removeItem("depotVip");
    localStorage.removeItem("role");
    localStorage.removeItem("lastRefreshTime");
  }
  return error.response.status;
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
