//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from './store/'
import CloseIcon from '../../../assets/close-icon-big.png'

export default {
  props: {
    icons: {
      type: Object,
      default: function () {
        return {
          close: {
            img: CloseIcon,
            name: 'default'
          }
        }
      }
    },
    title: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inUserList: false
    }
  },
  computed: {
    ...mapState(['disableUserListToggle', 'titleImageUrl', 'showCloseButton'])
  },
  methods: {
    toggleUserList() {
      // this.inUserList = !this.inUserList
      // this.$emit('userList', this.inUserList)
    },
    closeChatWindow(){
      this.$emit('close-chat')
    }
  }
}
