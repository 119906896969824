//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_depot",
  data () {
    return{
      tabs: [
        {
          title: this.$t('d.depot_title_domesticDepot'),
          value: "domesticDepot",
          id: 1
        },
        {
          title: this.$t('d.depot_title_overseasDepot'),
          value: "overseasDepot",
          id: 2
        }
      ],
      searchDepotForm: {
        country: '',
        city: '',
        depotScope: 'Domestic Depot'
      },
      activeName: 'domesticDepot',
      isEmpty: true,
      showCurrentDepotPrice: false,
      activeIndex: -1,
      showTip: false,
      consultForm:{
        companyName: '',
        contactNumber: '',
        contactEmail: '',
        otherRequirements: ''
      },
      consultRules:[

      ],
      tipSubject: '',
      tipContent: '',
      depotResourceList: [],
      countryOptions: [],
      cityOptions: [],
      loading: false,
      currentPage: 1, // 当前页码
      pageSize: 50,// 每页显示的条目数量
      totalItems: 0, // 总条目数
      totalPages: 0,// 总页数
      showReviewDialog: false,
      isDepotVip: false,
    }
  },
  methods:{
    viewDepotPrice(resourceNo){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      let url = this.baseURL + '/#/containerDepotDetail/'+ resourceNo;
      window.open(url);
    },
    returnPrevious(){
      this.$router.push({
        path:'/containerTrading'
      })
    },
    closeUpgradeTip(){
      this.showTip = false;
    },
    searchDepot(){
      this.initPaging();
      this.searchDepotResource();
    },
    initPaging(){
      this.currentPage =  1;// 当前页码
    },
    switchClick(tab, event){
      this.activeName = tab.name;

      if(tab.name === 'domesticDepot'){
        this.searchDepotForm.depotScope = 'Domestic Depot';
      }
      if(tab.name === 'overseasDepot'){
        this.searchDepotForm.depotScope = 'Overseas Depot';
      }

      this.initPaging();
      this.searchDepotResource();
    },
    async searchDepotResource () {
      try {
        if(!this.getLocalStoreItem('token')){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
          return ;
        }
        this.offset = (this.currentPage - 1) * this.pageSize;
        const params = {
          ...this.searchDepotForm,
          offset: this.offset,
          pageSize: this.pageSize
        }
        const res = await this.$axios.get('/bk/depot/search', {
          params: params
        })

        console.log(res);
        this.depotResourceList = [];
        if(res.resultList.length > 0){
          this.depotResourceList = res.resultList;
        }
        this.totalItems = res.total;
        this.totalPages=  (this.totalItems  / this.pageSize) + this.totalItems < this.pageSize ? 1:0;
        this.isEmpty = this.depotResourceList.length === 0;
      } catch (e) {
        console.log(e, 'e')
      }
    },
    depotJoin(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerDepotReside'
      })
    },
    depotUpgrade(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.showTip = true
    },
    async handleDepotUpgrade(){
      try {
        if(!this.getLocalStoreItem('token')){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
          return;
        }
        const result = await this.$axios.post('/bk/userSetting/upgradeDepotVipApply')
        if(result === 'success'){
          this.showTip = false
          this.showReviewDialog = true;
        }
      }catch (e) {
        console.log(e, 'e')
      }
    },
    handleClose(){
      this.showReviewDialog = false
    },
    filterCountry(query){
      this.searchCountry(query,);
    },
    filterCity(query){
      this.searchCity(query);
    },
    async searchCountry(query){
      try {
        const param = {
          countryName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/filterCountry', {
          params: param
        })
        this.loading = false;

        this.countryOptions = res;

      } catch (e) {
        console.log(e, 'e')
      }
    },
    async searchCity(query){
      try {
        const param = {
          countryName: this.searchDepotForm.country,
          cityName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/filterCity', {
          params: param
        })
        this.loading = false;

        this.cityOptions = res;


      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created () {
    this.filterCountry();
    this.filterCity();
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    if(this.getLocalStoreItem("depotVip") === '1'){
      this.isDepotVip = true;
    }
    this.searchDepotResource();
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
}
