//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_container_demand_detail",
  data(){
    return{
      demandNo: '',
      item:{},
      showTip: false
    }
  },
  methods:{
    async getDemandInfoDetail () {
      try {
        const params = {
          demandNo: this.demandNo
        }
        const res = await this.$axios.get('/bk/demand/getDemandDetail', {
          params: params
        });
        if(res === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }
        if(res.demandNo){
          this.item = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async supplyDemand () {
      const params = {
        demandNo: this.demandNo
      }
      const result = await this.$axios.post('/bk/demand/addApplyRecord', params)
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }
      if(result === 'success'){
        this.showTip = true
      }
    },
    handleCancelSupply(){
      this.$router.push('/containerDemand')
    },
    handleClose(){
      this.showTip = false;
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.demandNo = this.$route.params.demandNo;
    console.log('1')
    this.getDemandInfoDetail();
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
