//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "factory_order_detail",
  data(){
    return{
      item:{},
      orderNo: ''
    }
  },
  methods:{
    async getDetail () {
          try {
              const param = {
                  orderNo: this.orderNo
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/tradingFactoryOrder/getDetail', {
                  params: param
              })
              this.loading = false;
              if(res.orderNo){
                  this.item = res;
              }
          } catch (e) {
              console.log(e, 'e')
          }
      },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.orderNo = this.$route.params.orderNo;
    this.getDetail();
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
