//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      ruleForm: {
        from: '',
        to: '',
        type: ''
      },
      infoForm: {
        name: '',
        companyName: '',
        email: '',
        phoneNumber: ''
      },
      portOptions: [],
      typeOptions: [],
      resourceList: [],
      showSupply: true,
      keywords: '',
      dialogInfo: false,
      rules: {
        name: [{ required: true, message: 'Name cannot be empty', trigger: 'blur' }],
        email: [
          { required: true, message: 'E-mail cannot be empty', trigger: 'blur' },
          { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
        ],
        phoneNumber: [{ required: true, message: 'phone cannot be empty', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.queryResource()
    this.initType()
    // this.initPort()
  },
  methods: {
    initPortHandle () {
      console.log('初始化数据')
      this.keywords = ''
      this.initPort()
    },
    async initPort () {
      try {
        const ports = await this.$axios.get('/api/port/query', {
          params: {
            keywords: this.keywords
          }
        })
        console.log(ports, 'ports')
        if (ports && ports.length) {
          this.portOptions = ports.map(po => {
            return {
              ...po,
              label: po.codeCountry,
              value: po.sfid
            }
          })
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async initType () {
      try {
        const types = await this.$axios.get('/api/type/all')
        console.log(types, 'types')
        if (types && types.length) {
          console.log(types, 'types')
          this.typeOptions = types
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterPort (value) {
      this.keywords = value
      this.initPort()
      console.log(value, 'filter')
    },
    async queryResource () {
      try {
        const res = await this.$axios.get('/api/eqtresources/search', {
          params: { ...this.ruleForm }
        })
        this.resourceList = res
        console.log(res, 'requirmenst')
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async queryNeed () {
      try {
        const res = await this.$axios.get('/api/eqtneed/search', {
          params: { ...this.ruleForm }
        })
        this.resourceList = res
        console.log(res, 'requirmenst')
      } catch (e) {
        console.log(e, 'e')
      }
    },
    changeTabStatus (value) {
      // console.log(params, 'params')
      this.ruleForm = {
        from: '',
        to: '',
        type: ''
      }
      this.showSupply = (value === 'supply')
      if (value === 'supply') {
        this.queryResource()
      } else {
        this.queryNeed()
      }
    },
    searchRequirments () {
      console.log(this.ruleForm, 'ruleForm')
      if (this.showSupply) {
        this.queryResource()
      } else {
        this.queryNeed()
      }
    },
    async submitInfo () {
      try {
        const res = await this.$refs.infoForm.validate()
        if (res) {
          console.log(this.infoForm)
          const params = {
            ...this.infoForm
          }
          const addResult = await this.$axios.post('/api/visitor/add', params)
          console.log(addResult, 'addResult')
          if (addResult.status === 'FAIL') {
            this.$message.error(addResult.message)
          }
          if (addResult.status === 'SUCCESS') {
            this.$message({
              message: 'Information has been submitted',
              type: 'success'
            })
            this.dialogInfo = false
            this.infoForm = {
              name: '',
              companyName: '',
              email: '',
              phoneNumber: ''
            }
          }
        }
      } catch (e) {
        console.log(e, 'e')
      }
    }
  }
}
