//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const Base64 = require('js-base64').Base64
export default {
  data(){
    const validateuserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账户名不能为空'))
      } else {
        return callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('密码不能为空'))
      } else {
        return callback()
      }
    }
    return {
      loginForm:{
        username: '',
        password: '',
        loginRole: 'ROLE_USER'
      },
      rules: {
        username: [{ required: true, validator: validateuserName, trigger: 'blur' }],
        password: [{ required: true, validator: validatePassword, trigger: 'blur' }],
      },
      rememberMe: localStorage.getItem('rememberMe') === 'true',
      checked: false,
      radio: localStorage.getItem('lang') === 'zh-CN'? '1' : '2'
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      if(localStorage.getItem('rememberMe') === 'true'){
          this.loginForm.username = localStorage.getItem('rememberLoginName');
          this.loginForm.password = Base64.decode(localStorage.getItem('rememberPwd'));
          this.rememberMe = true
      }
  },
  methods :{
    handleRememberMe(){
        if(this.rememberMe){
            let pwd = Base64.encode(this.loginForm.password);
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('rememberLoginName', this.loginForm.username);
            localStorage.setItem('rememberPwd', pwd);
        }else{
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('rememberLoginName');
            localStorage.removeItem('rememberPwd');
        }
    },
    async login(e){
      try {
        const params = {
          ...this.loginForm
        }
        console.log(JSON.stringify(params) + 'params')
        const addResult = await this.$axios.post('/bk/auth/signin', params);
        this.loading = false
        if(addResult === 401){
            this.$message.error('用户名密码不正确！')
        }else if(addResult.username){
          localStorage.setItem("username", addResult.username);
          localStorage.setItem("userId", addResult.id);
          localStorage.setItem("um-vip", addResult.vip);
          localStorage.setItem("depotVip", addResult.depotVip);
          localStorage.setItem("role", addResult.roles[0]);
          if(localStorage.getItem('rememberMe') === 'true'){
              let pwd = Base64.encode(this.loginForm.password);
              localStorage.setItem('rememberLoginName', this.loginForm.username);
              localStorage.setItem('rememberPwd', pwd);
          }
          let prePath = localStorage.getItem('prePath');
          if(prePath){
              localStorage.removeItem('prePath');
              this.$router.push({
                  path: prePath
              })
          }else{
              this.$router.push({
                  path:'/containerTrading'
              })
          }
        } else{
          this.$message.error(addResult)
        }
      } catch (e) {
        console.log(e, 'e')
          if(e.response.status === 401){
              this.$message.error('用户名密码不正确！')
          }
      }
    },
    tradingRegister () {
      this.$router.push('/registertrading')
    },
    forgotPassword(){
      this.$router.push('/passReset')
    },
    changeLangToUS(){
        if(this.$i18n.locale === 'zh-CN'){
            this.$i18n.locale = 'en-US';
            localStorage.setItem('lang', this.$i18n.locale);
        }
    },
    changeLangToCN(){
        if(this.$i18n.locale === 'en-US'){
            this.$i18n.locale = 'zh-CN';
            localStorage.setItem('lang', this.$i18n.locale)
        }
    }
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
