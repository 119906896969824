//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_purchase",
  data() {
    return {
      num: 1,
      dialogFormVisible: false,
      purchaseInfo: {

      },
      orderNo:'',
      currentId: '',
      currentNum: '',
      resourceNo: '',
      showTip: false,
      tipSubject: '',
      tipContent: ''
    };
  },
  methods: {
    handleClose() {
        this.showTip = false;
        this.$router.push('/orderManage')
    },
    handleChange(value) {
      console.log(value);
    },
      async purchase(resourceNo){
          this.loading = true;
          const Result = await this.$axios.get('/bk/tradingOrder/getOrderNo');
          if(Result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
              path:'/login'
            })
          }
          console.log(Result);
          this.orderNo = Result;
          const params = {
              resourceNo: resourceNo
          }
          const res = await this.$axios.get('/bk/trading/getContainerTradingResource', {
              params: params
          });
          console.log(res);
          this.purchaseInfo = res;
          this.resourceNo = this.purchaseInfo.resourceNo;
      },

    async confirmPurchase(){

      const params = {
        orderNo: this.orderNo,
        qty:this.currentNum,
        userId:this.getLocalStoreItem("userId"),
        resourceNo:this.purchaseInfo.resourceNo,
        sizeType: this.purchaseInfo.sizeType,
        productionYear : this.purchaseInfo.productionYear,
        containerStatus : this.purchaseInfo.containerStatus,
        pickupLoc: this.purchaseInfo.pickupLoc,
        price: this.purchaseInfo.price

      }

      console.log(params);
      const Result = await this.$axios.post('/bk/tradingOrder/submitOrder', params);

        if(Result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(Result === 'success' ){
            this.tipSubject = this.$t('t.send_confirm_tip_order_subject');
            this.tipContent = this.$t('t.send_confirm_tip_order_content');
            this.showTip = true;
        }else {
            this.$message.error('Order Fail')
        }
    }

  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.purchase(this.$route.params.resourceNo);
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
