//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_depot_reside",
  data(){
    const validateCompanyName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_companyName')))
      } else {
        return callback()
      }
    }
    const validateContactNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_contactNumber')))
      } else {
        return callback()
      }
    }
    const validateContactEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_contactEmail')))
      } else {
        return callback()
      }
    }
    const validateCompanyWebsite = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_companyWebsite')))
      } else {
        return callback()
      }
    }
    const validateDepotName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotName')))
      } else {
        return callback()
      }
    }
    const validateDepotCountry = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotCountry')))
      } else {
        return callback()
      }
    }
    const validateDepotCity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotCity')))
      } else {
        return callback()
      }
    }
    const validateDetailedAddress = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_detailedAddressOfTheDepot')))
      } else {
        return callback()
      }
    }
    const validateFromPort = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_fromPort')))
      } else {
        return callback()
      }
    }
    const validateDepotArea = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotArea')))
      } else {
        return callback()
      }
    }
    const validateDepotCapacity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotCapacity')))
      } else {
        return callback()
      }
    }
    const validateThroughput = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_throughput')))
      } else {
        return callback()
      }
    }
    const validateDepotImg = (rule, value, callback) => {
      if (this.depotForm.picContent === '') {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_depotImg')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyFreeStoragePeriod = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_freeStoragePeriod')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyStorageFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_storageFee')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyGateInLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateInLiftingFee')))
      } else {
        return callback()
      }
    }
    const validateFeetEmptyGateOutLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateOutLiftingFee')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedFreeStoragePeriod = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_freeStoragePeriod')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedStorageFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_storageFee')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedGateInLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateInLiftingFee')))
      } else {
        return callback()
      }
    }
    const validateFeetLoadedGateOutLiftingFee = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_gateOutLiftingFee')))
      } else {
        return callback()
      }
    }
    return{
      depotForm:{
        companyName: '',
        depotScope:'',
        contactNumber: '',
        contactEmail: '',
        companyWebsite: '',
        depotName: '',
        country: '',
        city: '',
        detailAddress: '',
        repairService: true,
        nearestPortDistance: '',
        depotArea: '',
        depotCapacity: '',
        throughput: '',
        businessHour: '',
        uninterruptedHours: true,
        workingHours: false,
        startWorkTime: '9:00',
        endWorkTime: '18:00',
        startWorkWeek: 'Monday',
        endWorkWeek: 'Friday',
        depotPic: '',
        remark: '',
        picContent: '',
        twentyFeetEmptyFreeStoragePeriod: '',
        twentyFeetEmptyStorageFee: '',
        twentyFeetEmptyGateInLiftingFee: '',
        twentyFeetEmptyGateOutLiftingFee: '',
        twentyFeetLoadedFreeStoragePeriod: '',
        twentyFeetLoadedStorageFee: '',
        twentyFeetLoadedGateInLiftingFee: '',
        twentyFeetLoadedGateOutLiftingFee: '',
        fortyFeetEmptyFreeStoragePeriod: '',
        fortyFeetEmptyStorageFee: '',
        fortyFeetEmptyGateInLiftingFee: '',
        fortyFeetEmptyGateOutLiftingFee: '',
        fortyFeetLoadedFreeStoragePeriod: '',
        fortyFeetLoadedStorageFee: '',
        fortyFeetLoadedGateInLiftingFee: '',
        fortyFeetLoadedGateOutLiftingFee: '',
        depotPrices:[
          {
            size: '20',
            emptyFreeStoragePeriod: '',
            emptyStoragePrice: '',
            emptyGateInPrice: '',
            emptyGateOutPrice: '',
            loadedFreeStoragePeriod: '',
            loadedStoragePrice: '',
            loadedGateInPrice: '',
            loadedGateOutPrice: '',
          },
          {
            size: '40',
            emptyFreeStoragePeriod: '',
            emptyStoragePrice: '',
            emptyGateInPrice: '',
            emptyGateOutPrice: '',
            loadedFreeStoragePeriod: '',
            loadedStoragePrice: '',
            loadedGateInPrice: '',
            loadedGateOutPrice: '',
          },
        ]
      },
      depotRules:{
        companyName: [{ required: true, validator: validateCompanyName, trigger: 'blur' }],
        contactNumber: [{ required: true, validator: validateContactNumber, trigger: 'blur' }],
        contactEmail: [{ required: true, validator: validateContactEmail, trigger: 'blur' }],
        companyWebsite: [{ required: true, validator: validateCompanyWebsite, trigger: 'blur' }],
        depotName: [{ required: true, validator: validateDepotName, trigger: 'blur' }],
        country: [{ required: true, validator: validateDepotCountry, trigger: 'blur' }],
        city: [{ required: true, validator: validateDepotCity, trigger: 'blur' }],
        detailAddress: [{ required: true, validator: validateDetailedAddress, trigger: 'blur' }],
        nearestPortDistance: [{ required: true, validator: validateFromPort, trigger: 'blur' }],
        depotArea: [{ required: true, validator: validateDepotArea, trigger: 'blur' }],
        depotCapacity: [{ required: true, validator: validateDepotCapacity, trigger: 'blur' }],
        throughput: [{ required: true, validator: validateThroughput, trigger: 'blur' }],
        depotImage: [{ required: true, validator: validateDepotImg, trigger: 'blur' }],
        twentyFeetEmptyFreeStoragePeriod: [{ required: true, validator: validateFeetEmptyFreeStoragePeriod, trigger: 'blur' }],
        twentyFeetEmptyStorageFee: [{ required: true, validator: validateFeetEmptyStorageFee, trigger: 'blur' }],
        twentyFeetEmptyGateInLiftingFee: [{ required: true, validator: validateFeetEmptyGateInLiftingFee, trigger: 'blur' }],
        twentyFeetEmptyGateOutLiftingFee: [{ required: true, validator: validateFeetEmptyGateOutLiftingFee, trigger: 'blur' }],
        twentyFeetLoadedFreeStoragePeriod: [{ required: true, validator: validateFeetLoadedFreeStoragePeriod, trigger: 'blur' }],
        twentyFeetLoadedStorageFee: [{ required: true, validator: validateFeetLoadedStorageFee, trigger: 'blur' }],
        twentyFeetLoadedGateInLiftingFee: [{ required: true, validator: validateFeetLoadedGateInLiftingFee, trigger: 'blur' }],
        twentyFeetLoadedGateOutLiftingFee: [{ required: true, validator: validateFeetLoadedGateOutLiftingFee, trigger: 'blur' }],
        fortyFeetEmptyFreeStoragePeriod: [{ required: true, validator: validateFeetEmptyFreeStoragePeriod, trigger: 'blur' }],
        fortyFeetEmptyStorageFee: [{ required: true, validator: validateFeetEmptyStorageFee, trigger: 'blur' }],
        fortyFeetEmptyGateInLiftingFee: [{ required: true, validator: validateFeetEmptyGateInLiftingFee, trigger: 'blur' }],
        fortyFeetEmptyGateOutLiftingFee: [{ required: true, validator: validateFeetEmptyGateOutLiftingFee, trigger: 'blur' }],
        fortyFeetLoadedFreeStoragePeriod: [{ required: true, validator: validateFeetLoadedFreeStoragePeriod, trigger: 'blur' }],
        fortyFeetLoadedStorageFee: [{ required: true, validator: validateFeetLoadedStorageFee, trigger: 'blur' }],
        fortyFeetLoadedGateInLiftingFee: [{ required: true, validator: validateFeetLoadedGateInLiftingFee, trigger: 'blur' }],
        fortyFeetLoadedGateOutLiftingFee: [{ required: true, validator: validateFeetLoadedGateOutLiftingFee, trigger: 'blur' }],
      },
      fileList: [],
      proofImage: '',
      dialogImageUrl: '',
      dialogVisible: false,
      showReviewPage: false,
      countryOptions: [],
      cityOptions: [],
      loading: false,
    }
  },
  methods:{
    async joinDepot(){
      try {
        if(!this.getLocalStoreItem('token')){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
          return ;
        }
        await this.$refs.depotForm.validate()
        this.depotForm.depotScope = this.depotForm.country === 'China' ? 'Domestic Depot' : 'Overseas Depot'
        this.depotForm.businessHour =  this.depotForm.uninterruptedHours ? '7*24' : this.depotForm.startWorkWeek +'-'+ this.depotForm.endWorkWeek + ' ' + this.depotForm.startWorkTime  +'-'+  this.depotForm.endWorkTime;
        this.depotForm.depotPrices[0].emptyFreeStoragePeriod = this.depotForm.twentyFeetEmptyFreeStoragePeriod;
        this.depotForm.depotPrices[0].emptyStoragePrice = this.depotForm.twentyFeetEmptyStorageFee;
        this.depotForm.depotPrices[0].emptyGateInPrice = this.depotForm.twentyFeetEmptyGateInLiftingFee;
        this.depotForm.depotPrices[0].emptyGateOutPrice = this.depotForm.twentyFeetEmptyGateOutLiftingFee;
        this.depotForm.depotPrices[0].loadedFreeStoragePeriod = this.depotForm.twentyFeetLoadedFreeStoragePeriod;
        this.depotForm.depotPrices[0].loadedStoragePrice = this.depotForm.twentyFeetLoadedStorageFee;
        this.depotForm.depotPrices[0].loadedGateInPrice = this.depotForm.twentyFeetLoadedGateInLiftingFee;
        this.depotForm.depotPrices[0].loadedGateOutPrice = this.depotForm.twentyFeetLoadedGateOutLiftingFee;
        this.depotForm.depotPrices[1].emptyFreeStoragePeriod = this.depotForm.fortyFeetEmptyFreeStoragePeriod;
        this.depotForm.depotPrices[1].emptyStoragePrice = this.depotForm.fortyFeetEmptyStorageFee;
        this.depotForm.depotPrices[1].emptyGateInPrice = this.depotForm.fortyFeetEmptyGateInLiftingFee;
        this.depotForm.depotPrices[1].emptyGateOutPrice = this.depotForm.fortyFeetEmptyGateOutLiftingFee;
        this.depotForm.depotPrices[1].loadedFreeStoragePeriod = this.depotForm.fortyFeetLoadedFreeStoragePeriod;
        this.depotForm.depotPrices[1].loadedStoragePrice = this.depotForm.fortyFeetLoadedStorageFee;
        this.depotForm.depotPrices[1].loadedGateInPrice = this.depotForm.fortyFeetLoadedGateInLiftingFee;
        this.depotForm.depotPrices[1].loadedGateOutPrice = this.depotForm.fortyFeetLoadedGateOutLiftingFee;
        const params = {
          ...this.depotForm,
        }

        console.log(JSON.stringify(params) + 'params')


        const addResult = await this.$axios.post('/bk/depot/addDepotInfo', params)
        console.log(addResult)
        if(addResult === 'success'){
          this.showReviewPage = true;
          this.$refs['depotForm'].resetFields()
        }


      } catch (e) {
        console.log(e, 'e')
      }
    },
    cancelJoin(){

    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        console.log(params, 'params')
        this.proofImage = params
        if (num === 1) {
          this.depotForm.picContent = this.proofImage
        }
      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.infoForm.companyPhoto = this.proofImage
      }
      if (num === 2) {
        this.userForm.idCardPhotoFront = this.proofImage
      }
      if (num === 3) {
        this.userForm.idCardPhotoBack = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    workingHoursChange(e){
      if(this.depotForm.uninterruptedHours){
        this.depotForm.uninterruptedHours = false;
      }else {
        this.depotForm.workingHours = true;
      }
    },
    businessHourChange(e){
      if(this.depotForm.workingHours){
        this.depotForm.workingHours = false;
      }else {
        this.depotForm.uninterruptedHours = true;
      }
    },
    handleClose(){
      this.showReviewPage = false;
      this.$router.push({
        path:'/containerDepot'
      })
    },
    filterCountry(query){
      this.searchCountry(query,);
    },
    filterCity(query){
      this.searchCity(query);
    },
    async searchCountry(query){
      try {
        const param = {
          countryName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/filterCountry', {
          params: param
        })
        this.loading = false;

        this.countryOptions = res;

      } catch (e) {
        console.log(e, 'e')
      }
    },
    async searchCity(query){
      try {
        const param = {
          countryName: this.depotForm.country,
          cityName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/filterCity', {
          params: param
        })
        this.loading = false;

        this.cityOptions = res;


      } catch (e) {
        console.log(e, 'e')
      }
    }
  },
  created () {
    this.filterCountry();
    this.filterCity();
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
