//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_space_inquiry_order",
  data(){
    const validatesDeparture = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_departureVerification')))
      } else {
        return callback()
      }
    }
    const validatesDestination = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_destinationVerification')))
      } else {
        return callback()
      }
    }
    const validatesContainer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_containerVerification')))
      } else {
        return callback()
      }
    }
    const validatesQuantity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_quantityVerification')))
      } else {
        return callback()
      }
    }
    const validatesShippingTerms = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_shippingTermsVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoReadyDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoReadyDateVerification')))
      } else {
        return callback()
      }
    }
    const validatesCommodity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_commodityVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoNetWeight = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoNetWeightVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoVolume = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoVolumeVerification')))
      } else {
        return callback()
      }
    }
    const validatesAcceptTransfer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_acceptTransferVerification')))
      } else {
        return callback()
      }
    }
    return{
      searchInfoForm:{
        type:'COC',
        departure: '',
        destination: ''
      },
      inquiryFormVisible : false,
      inquiryForm:{
        slotType:'',
        isDangerous: '',
        departure: '',
        destination: '',
        sizeType: '',
        qty: '',
        shippingTerms : '',
        cargoReadyDate: '',
        commodity: '',
        weight: '',
        volume: '',
        acceptTransit: '',
        sizeType1: '',
        size1: '',
        type1: '',
        qty1: 0,
        sizeType2: '',
        size2: '',
        type2: '',
        qty2: 0,
        sizeType3: '',
        size3: '',
        type3: '',
        qty3: 0,
        sizeType4: '',
        size4: '',
        type4: '',
        qty4: 0,

      },
      showTip: false,
      tipSubject: '',
      tipContent: '',
      deleteInfoDialog: false,
      isEmpty: true,
      showEditInquiryDialog: false,
      inquiryFormRules:{
        departure: [{ required: true, validator: validatesDeparture, trigger: 'blur' }],
        destination: [{ required: true, validator: validatesDestination, trigger: 'blur' }],
        sizeType: [{ required: true, validator: validatesContainer, trigger: 'blur' }],
        // qty: [{ required: true, validator: validatesQuantity, trigger: 'blur' }],
        shippingTerms: [{ required: true, validator: validatesShippingTerms, trigger: 'blur' }],
        cargoReadyDate: [{ required: true, validator: validatesCargoReadyDate, trigger: 'blur' }],
        commodity: [{ required: true, validator: validatesCommodity, trigger: 'blur' }],
        // weight: [{ required: true, validator: validatesCargoNetWeight, trigger: 'blur' }],
        // volume: [{ required: true, validator: validatesCargoVolume, trigger: 'blur' }],
        acceptTransit: [{ required: true, validator: validatesAcceptTransfer, trigger: 'blur' }],
      },
      spotSpaceInquiryOrderList : [],
      currentRecordId: '',
      departureOptions: [],
      destinationOptions: []
    }
  },
  methods:{
    viewInquiryDetail(item){
      try {
        let url = this.baseURL + '/#/spotSpaceInquiryDetail/' + item.consultNo;
        window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    openEditDialog(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.inquiryForm = {
        ...item,
        isDangerous: item.dangerous ? 'Yes' : 'No',
        acceptTransit: item.acceptTransit ? 'Yes' : 'No',
        size1: item.sizeType1 ? item.sizeType1.substr(0, 2) : '',
        type1: item.sizeType1 ? item.sizeType1.substr(2, item.sizeType1.length -2) : '',
        size2: item.sizeType2 ? item.sizeType2.substr(0, 2) : '',
        type2: item.sizeType2 ? item.sizeType2.substr(2, item.sizeType2.length -2) : '',
        size3: item.sizeType3 ? item.sizeType3.substr(0, 2) : '',
        type3: item.sizeType3 ? item.sizeType3.substr(2, item.sizeType3.length -2) : '',
        size4: item.sizeType4 ? item.sizeType4.substr(0, 2) : '',
        type4: item.sizeType4 ? item.sizeType4.substr(2, item.sizeType4.length -2) : '',
        qty1: item.qty1 ? item.qty1 : 0,
        qty2: item.qty2 ? item.qty2 : 0,
        qty3: item.qty3 ? item.qty3 : 0,
        qty4: item.qty4 ? item.qty4 : 0,
      }
      this.showEditInquiryDialog = true;
    },
    async editInquiryInfo(){
      await this.$refs.inquiryForm.validate()
      if((this.inquiryForm.size1 === '' && this.inquiryForm.size2 === '' && this.inquiryForm.size3 === '' && this.inquiryForm.size4 === '') &&
        (this.inquiryForm.type1 === '' && this.inquiryForm.type2 === '' && this.inquiryForm.type3 === '' && this.inquiryForm.type4 === '')
      ){
        this.$message.error(this.$t('s.spotSpace_inquiry_form_containerTypeVerification'))
        return
      }
      if(
        ((this.inquiryForm.size1 === '' && this.inquiryForm.type1 !== '') || (this.inquiryForm.size1 !== '' && this.inquiryForm.type1 === '')) ||
        ((this.inquiryForm.size2 === '' && this.inquiryForm.type2 !== '') || (this.inquiryForm.size2 !== '' && this.inquiryForm.type2 === '')) ||
        ((this.inquiryForm.size3 === '' && this.inquiryForm.type3 !== '') || (this.inquiryForm.size3 !== '' && this.inquiryForm.type3 === '')) ||
        ((this.inquiryForm.size4 === '' && this.inquiryForm.type4 !== '') || (this.inquiryForm.size4 !== '' && this.inquiryForm.type4 === ''))
      ){
        this.$message.error(this.$t('s.spotSpace_inquiry_form_containerSizeVerification'))
        return
      }
      this.inquiryForm.cargoReadyDate = this.dateFormat(this.inquiryForm.cargoReadyDate);
      const params = {
        ...this.inquiryForm,
        userId: this.getLocalStoreItem('userId'),
        acceptTransit: this.inquiryForm.acceptTransit === 'Yes',
        dangerous: this.inquiryForm.isDangerous === 'Yes',
        sizeType1: this.inquiryForm.size1 + this.inquiryForm.type1,
        sizeType2: this.inquiryForm.size2 + this.inquiryForm.type2,
        sizeType3: this.inquiryForm.size3 + this.inquiryForm.type3,
        sizeType4: this.inquiryForm.size4 + this.inquiryForm.type4,
      }
      const result = await this.$axios.post('/bk/spotConsult/updateConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.item = this.editForm;
        this.showInquiryDialog = false;
        this.$message({
          message: 'Update Success',
          type: 'success'
        });
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    openDeleteDialog(item){
      this.deleteInfoDialog = true;
      this.currentRecordId = item.id;
    },
    continueBrowsing(){
      this.$router.push({
        path:'/spotSpace'
      })
    },
    async spotSpanceInquiry(){

      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/spotConsult/listSpotConsult', {
        params: params
      });
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }

      this.spotSpaceInquiryOrderList = result;

      for(let i = 0; i<this.spotSpaceInquiryOrderList.length; i++){
        let containerStr = '';
        let totalQty = 0 ;
        if(this.spotSpaceInquiryOrderList[i].sizeType1){
          containerStr += (this.spotSpaceInquiryOrderList[i].sizeType1 + 'x' + this.spotSpaceInquiryOrderList[i].qty1 +' / ');
          totalQty += this.spotSpaceInquiryOrderList[i].qty1;
        }
        if(this.spotSpaceInquiryOrderList[i].sizeType2){
          containerStr += (this.spotSpaceInquiryOrderList[i].sizeType2 + 'x' + this.spotSpaceInquiryOrderList[i].qty2 +' / ');
          totalQty += this.spotSpaceInquiryOrderList[i].qty2;
        }
        if(this.spotSpaceInquiryOrderList[i].sizeType3){
          containerStr += (this.spotSpaceInquiryOrderList[i].sizeType3 + 'x' + this.spotSpaceInquiryOrderList[i].qty3 +' / ');
          totalQty += this.spotSpaceInquiryOrderList[i].qty3;
        }
        if(this.spotSpaceInquiryOrderList[i].sizeType4){
          containerStr += this.spotSpaceInquiryOrderList[i] + 'x' + this.spotSpaceInquiryOrderList[i].qty4 +' / ';
          totalQty += this.spotSpaceInquiryOrderList[i].qty4;
        }
        this.spotSpaceInquiryOrderList[i].containerStr = containerStr.slice(0,containerStr.length - 2);
        this.spotSpaceInquiryOrderList[i].totalQty = totalQty;
      }

      console.log(result);
      this.isEmpty = this.spotSpaceInquiryOrderList.length === 0;
    },
    async deleteInquiryRecord(){
      const params = {
        id: this.currentRecordId,
        userId: this.getLocalStoreItem('userId')
      }
      const result = await this.$axios.post('/bk/spotConsult/deleteConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
      this.currentRecorId = '';
      this.deleteInfoDialog = false;
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query, isDeparture){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        let temp =[];
        if(isDeparture){
          this.departureOptions = res;
        }else{
          this.destinationOptions = res;
        }

      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterDeparturePort(query){
      this.searchPort(query, true);
    },
    filterDestinationPort(query){
      this.searchPort(query, false);
    },
  },
  created() {
    this.filterDeparturePort('SHA');
    this.filterDestinationPort('Los');
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.spotSpanceInquiry();
  }
}
