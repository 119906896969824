//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_pace_order_detail",
  data(){
    const validatePaidVoucherFileContent = (rule, value, callback) => {
          if (!value) {
              return callback(new Error('上传文件不能为空'))
          } else {
              return callback()
          }
      }
    return{
        item:{
            spotResourceInfo: {
                resourceNo: '',
                slotType: '',
            },
            orderItems: []
        },
        totalFee: 0,
        totalQty: 0,
        orderNo: '',
        showCancelTip: false,
        showTip: false,
        tipSubject: '',
        tipContent: '',
        showUploadDialog: false,
        orderForm: {
            paidVoucherFileContent: ''
        },
        proofImage: '',
        dialogImageUrl: '',
        dialogVisible: false,
        rules: {
            paidVoucherFileContent: [{ required: true, validator: validatePaidVoucherFileContent, trigger: 'blur' }],
        },
        fileList: [],
        showPaidVoucher: false,
        paidVoucherUrl: ''
    }
  },
  methods:{
    viewPaidVoucher(url){
      this.showPaidVoucher = true;
      this.paidVoucherUrl = url;
    },
    async getDetailInfo () {
      try {
        const param = {
            orderNo: this.orderNo
        }
        const res = await this.$axios.get('/bk/spotOrder/getDetail', {
          params: param
        })
        if(res === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(res.orderNo){
          this.item = res;
          for(let i=0; i < this.item.orderItems.length; i ++){
              let orderItem = this.item.orderItems[i];
              this.totalQty += orderItem.qty;
              this.totalFee += (orderItem.freight + orderItem.thc) * orderItem.qty
          }
            this.totalFee += this.item.docFee;
        }
        console.log(res);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleClose() {
        this.showTip = false;
        this.$router.push('/spotSpaceOrder')
    },
    paidVoucherHandleClose(){
      this.showPaidVoucher = false;
      this.paidVoucherUrl = '';
    },
    toOrder(){
        this.$router.push({
            path:'/spotSpace'
        })
    },
    cancel(){
        this.showCancelTip = true;
    },
    async cancelOrder() {
          try {
              const params ={
                  id: this.item.id
              }
              const Result = await this.$axios.post('/bk/spotOrder/cancelOrder', params);
              if(Result === 401){
                  localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
                  this.$router.push({
                      path:'/login'
                  })
              }else if(Result === 'success' ){
                  this.showCancelTip= false;
                  this.tipSubject = this.$t('s.spotSpace_order_cancel_send_subject');
                  this.tipContent = this.$t('s.spotSpace_order_cancel_send_context');
                  this.showTip = true;
              }else {
                  this.$message.error('Cancel Fail!')
              }
          } catch (e) {
              console.log(e, 'e')
          }

      },
    uploadPaidVoucher(){
        this.showUploadDialog = true;
    },
    getFile (file, fileList, num) {
        this.getBase64(file.raw).then(res => {
            const params = res
            console.log(params, 'params')
            this.proofImage = params
            if (num === 1) {
                this.orderForm.paidVoucherFileContent = this.proofImage
            }

        })
    },
    getBase64 (file) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader()
            let imgResult = ''
            reader.readAsDataURL(file)
            reader.onload = function () {
                imgResult = reader.result
            }
            reader.onerror = function (error) {
                reject(error)
            }
            reader.onloadend = function () {
                resolve(imgResult)
            }
        })
    },
    handleUploadRemove (file, fileList, num) {
        this.proofImage = ''
        if (num === 1) {
            this.orderForm.paidVoucherFileContent = this.proofImage
        }
    },
    handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
    },
    async upload(){
        await this.$refs.orderForm.validate();
        const params = {
            id: this.item.id,
            paidVoucherFileContent: this.orderForm.paidVoucherFileContent
        }
        const result = await this.$axios.post('/bk/spotOrder/uploadPaidVoucher', params);
        if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(result === 'success'){
            this.orderForm = {
                paidVoucherFileContent: '',
            }
            this.showUploadDialog = false;
            this.dialogImageUrl = '';
            this.dialogVisible = false;
            location.reload();
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.orderNo = this.$route.params.orderNo;
    this.getDetailInfo();
  },
  computed:{}
}
