import { render, staticRenderFns } from "./one_stop_trading_serviceagreement.vue?vue&type=template&id=b754b976&scoped=true&"
import script from "./one_stop_trading_serviceagreement.vue?vue&type=script&lang=js&"
export * from "./one_stop_trading_serviceagreement.vue?vue&type=script&lang=js&"
import style0 from "./one_stop_trading_serviceagreement.vue?vue&type=style&index=0&id=b754b976&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b754b976",
  null
  
)

export default component.exports