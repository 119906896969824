//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TextMessage from './messages/TextMessage.vue'
import FileMessage from './messages/FileMessage.vue'
import EmojiMessage from './messages/EmojiMessage.vue'
import UmyMessage from './messages/UmyMessage.vue'
import TypingMessage from './messages/TypingMessage.vue'
import SystemMessage from './messages/SystemMessage.vue'
import ImgMessage from "./messages/ImgMessage";
import chatIcon from '../../../assets/chat-icon.svg'

export default {
  components: {
    TextMessage,
    FileMessage,
    EmojiMessage,
    UmyMessage,
    TypingMessage,
    SystemMessage,
    ImgMessage
  },
  data () {
    return {
      isVip: false,

    }
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    currentModule: {
      type: String,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    authorName() {
      return this.user && this.user.name
    },
    messageColors() {
      return this.message.author === 'me' ? this.sentColorsStyle : this.receivedColorsStyle
    },
    currentServiceModule(){
      return this.currentModule;
    },
    receivedColorsStyle() {
      return {
        color: this.colors.receivedMessage.text,
        backgroundColor: this.colors.receivedMessage.bg
      }
    },
    sentColorsStyle() {
      return {
        color: this.colors.sentMessage.text,
        backgroundColor: this.colors.sentMessage.bg
      }
    }
  },
  created() {
    if(localStorage.getItem("um-vip") === '1'){
      this.isVip = true
    }
  }
}
