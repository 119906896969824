//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_leasing_inquiry_detail",
  data(){
    const validateInquiryPickUpLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_pickUpLocation')))
      } else {
        return callback()
      }
    }
    const validateInquiryDropOffLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_dropOffLocation')))
      } else {
        return callback()
      }
    }
    const validateInquirySize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_size')))
      } else {
        return callback()
      }
    }
    const validateInquiryType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_type')))
      } else {
        return callback()
      }
    }
    const validateInquiryQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_qty')))
      } else {
        return callback()
      }
    }
    const validateInquiryCondition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_condition')))
      } else {
        return callback()
      }
    }
    const validateInquiryLeaseType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_leaseType')))
      } else {
        return callback()
      }
    }
    const validateInquiryLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('l.leasing_form_validate_error_lastPickupDate')))
      } else {
        return callback()
      }
    }
    return{
      consultNo: '',
      item:{},
      showEditInquiryDialog: false,
      showDeleteInquiryDialog: false,
      pickUpLocationOptions: [],
      dropOffLocationOptions: [],
      currentRecordId: '',
      showTip: false,
      loading: true,
      inquiryForm:{
        pickUpLocation: '',
        dropOffLocation: '',
        size: '',
        type: '',
        qty: '',
        condition: '',
        leaseType: '',
        latestPickupDate: ''
      },
      inquiryRules:{
        pickUpLocation: [{ required: true, validator: validateInquiryPickUpLocation, trigger: 'blur' }],
        dropOffLocation: [{ required: true, validator: validateInquiryDropOffLocation, trigger: 'blur' }],
        size: [{ required: true, validator: validateInquirySize, trigger: 'blur' }],
        type: [{ required: true, validator: validateInquiryType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateInquiryQty, trigger: 'blur' }],
        condition: [{ required: true, validator: validateInquiryCondition, trigger: 'blur' }],
        leaseType: [{ required: true, validator: validateInquiryLeaseType, trigger: 'blur' }],
        latestPickupDate: [{ required: true, validator: validateInquiryLastPickupDate, trigger: 'blur' }],
      }
    }
  },methods:{
    async getLeasingInquiryDetail () {
      try {
        const params = {
          consultNo: this.consultNo
        }
        const res = await this.$axios.get('/bk/leasingConsult/getDetail', {
          params: params
        });
        if(res.consultNo){
          this.item = res;
        }
        console.log(res)
      } catch (e) {
        console.log(e, 'e')
      }
    },
    openEditDialog(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.inquiryForm = {
        ...this.item,
        pickUpLocation: this.item.pickupLoc,
        dropOffLocation: this.item.dropoffLoc,
        size: this.item.sizeType.substring(0,2),
        type: this.item.sizeType.substring(2,this.item.sizeType.length),
        condition: this.item.conditions,
        qty: this.item.requestQty,
      }
      this.showEditInquiryDialog = true;
    },
    async editInquiryInfo(){
      await this.$refs.inquiryForm.validate()

      const params = {
        ...this.inquiryForm,
        userId: this.getLocalStoreItem('userId'),
        sizeType: this.inquiryForm.size + this.inquiryForm.type,
        pickupLoc: this.inquiryForm.pickUpLocation,
        dropoffLoc: this.inquiryForm.dropOffLocation,
        requestQty: this.inquiryForm.qty,
        latestPickupDate: this.dateFormat(this.inquiryForm.latestPickupDate),
        conditions: this.inquiryForm.condition,
      }
      const result = await this.$axios.post('/bk/leasingConsult/updateConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.$refs['inquiryForm'].resetFields()
        this.showEditInquiryDialog = false;
        this.$message({
          message: 'Update Success',
          type: 'success'
        });
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    openDeleteDialog(){
      this.showDeleteInquiryDialog = true;
      this.currentRecordId = this.item.id;
    },
    async deleteInquiryRecord(){
      const params = {
        id: this.currentRecordId,
        userId: this.getLocalStoreItem('userId')
      }
      const result = await this.$axios.post('/bk/leasingConsult/deleteConsult', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.$router.push({
          path:'/containerLeasingInquiryOrder'
        })
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
      this.currentRecorId = '';
      this.showDeleteInquiryDialog = false;
    },


    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query, isPickUp){
      try {
        const param = {
          portName: query
        }
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        if(isPickUp){
          this.pickUpLocationOptions = res;
        }else{
          this.dropOffLocationOptions = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterPickUpLocationPort(query){
      this.searchPort(query, true);
    },
    filterDropOffLocationPort(query){
      this.searchPort(query, false);
    },

  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.consultNo = this.$route.params.consultNo;
    this.getLeasingInquiryDetail();
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
