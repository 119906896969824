//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_leasing_inquiry_order",
  data () {
    return{
      isEmpty: false,
      leasingInquiryOrderList: [],
    }
  },methods:{
    async leasingInquiry(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/leasingConsult/listConsult', {
        params: params
      });
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }
      this.leasingInquiryOrderList = result;
      this.isEmpty = this.leasingInquiryOrderList.length === 0;
    },
    viewInquiryDetail(consultNo){
      try {
        this.$router.push({
          path: '/containerLeasingInquiryDetail/' + consultNo
        });
      } catch (e) {
        console.log(e, 'e')
      }
    },
    continueBrowsing(){
      this.$router.push({
        path:'/containerLeasing'
      })
    }
  },created () {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.leasingInquiry();
  },
}
