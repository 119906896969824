//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import IntegralMarket from './mobile_integral_market.vue'
export default {
  name: 'home',
  components: {
      IntegralMarket
  },
  data () {
    return {
      protocolDialog: false,
      keywords: '',
      portOptions: [],
      typeOptions: [],
      spotSizeTypeOptions: ['53HC', '45HW'],
      spotPolOptions: ['YANTIAN', 'SHEKOU'],
      spotPodOptions: ['Los Angeles，CA', 'Los Angeles, CA'],
      spotOptionMap:{},
      spotQueryResult: {
          capacity: '112',
          price: '2000',
          sizeType: '53HC'
      },
      show53: true,
      spotForm:{
          sizeType: '',
          pol: '',
          pod: ''
      },
      ruleForm: {
        from: '',
        to: '',
        type: '',
        quantity: ''
      },
      infoForm: {
        name: '',
        companyName: '',
        email: '',
        phoneNumber: ''
      },
      rules: {
        name: [{ required: true, message: this.$t('m.nameNotEmpty'), trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('m.emailNotEmpty'), trigger: 'blur' },
          { type: 'email', message: this.$t('m.errorEmailAddress'), trigger: ['blur', 'change'] }
        ],
        phoneNumber: [{ required: true, message: this.$t('m.phoneNotEmpty'), trigger: 'blur' }]
      },
      resourceList: [],
      tradeResourceList: [],
      loading: false,
      menuList: [
        { containersInfo: this.$t('h.containersInfo') },
        { homenavtitle: this.$t('h.homenavtitle') },
        { signbutton: this.$t('h.signbutton') },
        { pickuplocations: this.$t('h.pickuplocations') },
        { dropofflocations: this.$t('h.dropofflocations') },
        { containerType: this.$t('h.containerType') },
        { quantity: this.$t('h.quantity') },
        { searchbutton: this.$t('h.searchbutton') },
        { formtitle: this.$t('h.formtitle') },
        { name: this.$t('h.name') },
        { companyName: this.$t('h.companyName') },
        { email: this.$t('h.email') },
        { phonenumber: this.$t('h.phonenumber') },
        { submitbutton: this.$t('h.submitbutton') },
        { container: this.$t('h.container') },
        { supplierofcontainers: this.$t('h.supplierofcontainers') },
        { detailsbutton: this.$t('h.detailsbutton') },
        { updateDate: this.$t('h.updateDate') }
      ]
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    window.addEventListener('hashchange', this.afterQRScan)
    this.initPort()
    this.initType()
    console.log(this.$i18n.locale, 'lang')
    this.searchResource()
    this.searchTradingResource();
      this.initSpotInfo();
  },
  mounted () {
  },
  methods: {
    async initPort () {
      try {
        const ports = await this.$axios.get('/api/port/query', {
          params: {
            keywords: this.keywords
          }
        })
        console.log(ports, 'ports')
        if (ports && ports.length) {
          this.portOptions = ports.map(po => {
            return {
              ...po,
              label: po.codeCountry,
              value: po.sfid
            }
          })
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async initType () {
      try {
        const types = await this.$axios.get('/api/type/all')
        console.log(types, 'types')
        if (types && types.length) {
          console.log(types, 'types')
          this.typeOptions = types
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterPort (value) {
      this.keywords = value
      this.initPort()
      console.log(value, 'filter')
    },
    initPortHandle () {
      this.keywords = ''
      this.initPort()
    },
    async submitInfo () {
      try {
        this.loading = true
        const res = await this.$refs.infoForm.validate()
        if (res) {
          console.log(this.infoForm)
          const params = {
            ...this.infoForm
          }
          const addResult = await this.$axios.post('/api/visitor/add', params)
          console.log(addResult, 'addResult')
          this.loading = false
          if (addResult.status === 'FAIL') {
            this.$message.error(addResult.message)
          }
          if (addResult.status === 'SUCCESS') {
            this.$message({
              message: 'Information has been submitted',
              type: 'success'
            })
            // this.dialogInfo = false
            this.infoForm = {
              name: '',
              companyName: '',
              email: '',
              phoneNumber: ''
            }
            this.$nextTick(() => {
              this.$refs.infoForm.resetFields()
            })
          }
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    jumptoMember () {
      // https:// unit-match.force.com/membermarket/s/login/s
      // window.open('https://unit-match.force.com/membermarket/s')
      window.open('https://unit-match.force.com/marketplace/s/leasing')
    },
    loginMarketplace () {
      // console.log('click');
      // window.open('https://unit-match.force.com/marketplace/s/leasing');
      let url = this.baseURL + '/#/containerLeasing';
      window.open(url)
    },
    loginContainerSystem () {
      console.log('click');
      window.open('https://unit-match.my.salesforce.com/');
    },
    loginContainerTrading (){
        let url = this.baseURL + '/#/containerTrading';
        window.open(url)
    },
    loginSpotSpace () {
      let url = this.baseURL + '/#/spotSpace';
      window.open(url)
    },
    jumptoTradingLogin(){
        let url = this.baseURL + '/#/login';
        window.open(url)
    },
    jumptoTradingRegister(){
        let url = this.baseURL + '/#/registertrading';
        window.open(url)
    },
    jumptoRegister () {
      this.$router.push('/register')
    },
    contactUs() {
        let lang = this.$i18n.locale;
        window.open('https://www.unit-match.com/#/contact?lang=' + lang);
    },

    demoHandler () {
      const mainContent = document.getElementById('main-content')
      mainContent.scrollTop = 320
    },
    async searchResource () {
      try {
        this.loading = true
        console.log(this.ruleForm, 'ruleForm')
        const res = await this.$axios.get('/api/eqtresources/search', {
          params: { ...this.ruleForm }
        })
        this.loading = false
        this.resourceList = res
        console.log(res, 'requirmenst')
      } catch (e) {
        console.log(e, 'e')
      }
    },
    refrenshResource () {
      this.searchResource()
    },
    async searchTradingResource () {
        try {
            this.loading = true;
            const res = await this.$axios.get('/api/tradingResources/search');
            this.loading = false;
            this.tradeResourceList = res;
            console.log(res, 'tradeResourceList')
        } catch (e) {
            console.log(e, 'e')
        }
    },
    refreshTradingResource () {
        this.searchTradingResource()
    },
    handleReset(){
        this.$refs.child.resetOffset()
    },
    async initSpotOptions(){
        try {
            this.loading = true;
            const res = await this.$axios.get('/api/spotResources/getOptions');
            this.loading = false;
            if(res){
                this.spotOptionMap = res;
            }
            this.spotSizeTypeOptions = res.sizeTypeOptions;
            this.spotForm.sizeType = this.spotSizeTypeOptions[0];
            this.spotPolOptions = res[this.spotSizeTypeOptions[0] + "pol"];
            this.spotForm.pol = this.spotPolOptions[0];
            this.spotPodOptions = res[this.spotSizeTypeOptions[0] + "pod"];
            this.spotForm.pod = this.spotPodOptions[0];
            console.log(res, 'getOptions')
        } catch (e) {
            console.log(e, 'e')
        }
    },
    async querySpotInfo(){
        const res = await this.$axios.get('/api/spotResources/querySpotInfo', {
            params: {
                sizeType: this.spotForm.sizeType,
                pod: this.spotForm.pod
            }
        });
        if(res){
            this.spotQueryResult = res;
            this.show53 = this.spotForm.sizeType === '53HC';
        }
        console.log(res + 'spot');
    },
    async initSpotInfo(){
        await this.initSpotOptions();
        this.querySpotInfo();
    },
    handleSizeTypeChange(){
        this.spotPolOptions = this.spotOptionMap[this.spotForm.sizeType + "pol"];
        this.spotForm.pol = this.spotPolOptions[0];
        this.spotPodOptions = this.spotOptionMap[this.spotForm.sizeType + "pod"];
        this.spotForm.pod = this.spotPodOptions[0];
    },
    downloadProdDetail(){
        window.open('https://store.unit-match.com/diskcloud/45HW&53HC简介(中文版).pdf','_blank');
    }
  }
}
