import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// const lang = localStorage.getItem('lang')

const i18n = new VueI18n({
  // lang ||
  locale: 'zh-CN', // 语言标识
  messages: {
    'zh-CN': require('./lang/ZH'), // 中文语言包
    'en-US': require('./lang/EN') // 英文语言包
  }
})
export default i18n
