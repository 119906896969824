//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_demand_detail",
  data(){
    return{
      demandNo: '',
      item:{},
      showTip: false,
      currentRecordNo: '',
      showDeleteInquiryDialog: false,
    }
  },
  methods:{
    async getDemandInfoDetail () {
      try {
        const params = {
          demandNo: this.demandNo
        }
        const res = await this.$axios.get('/bk/demand/getDemandDetail', {
          params: params
        });
        if(res === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }
        if(res.demandNo){
          this.item = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async cancelDemand(){
      const params = {
        demandNo: this.item.demandNo
      }
      const result = await this.$axios.post('/bk/demand/cancelDemand', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.showTip = true;
        this.$refs.demandForm.resetFields()
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    handleCancelSupply(){
      this.$router.push('/containerDemand')
    },
    handleClose(){
      this.showDeleteInquiryDialog = false;
      this.showTip = false;
      this.getDemandInfoDetail();
    },
    handleCancelDemand(demandNo){
      this.currentRecordNo = demandNo
      this.showDeleteInquiryDialog = true;
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.demandNo = this.$route.params.demandNo;
    console.log('1')
    this.getDemandInfoDetail();
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  }
}
