//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mobile_spot_inquiry_manage",
  data(){
    return{
      isEmpty: true,
      spotSpaceInquiryOrderList : [],
      currentRecordId: ''
    }
  },
  methods:{
    viewInquiryDetail(item){
      try {
        this.$router.push({
          path: '/spotSpaceInquiryDetail/' + item.consultNo
        });
      } catch (e) {
        console.log(e, 'e')
      }
    },
    continueBrowsing(){
      this.$router.push({
        path:'/spotSpace'
      })
    },
    async spotSpanceInquiry(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/spotConsult/listSpotConsult', {
        params: params
      });
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }
      this.spotSpaceInquiryOrderList = result;
      console.log(result);
      this.isEmpty = this.spotSpaceInquiryOrderList.length === 0;
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.spotSpanceInquiry();
  }
}
