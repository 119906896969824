//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_depot_detail",
  data(){
    const validateCompanyName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_companyName')))
      } else {
        return callback()
      }
    }
    const validateContactNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_contactNumber')))
      } else {
        return callback()
      }
    }
    const validateContactEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('d.depot_reside_form_notNull_contactEmail')))
      } else {
        return callback()
      }
    }
    return{
      resourceNo: '',
      item:{},
      showTip: false,
      showConsultDialog: false,
      consultForm:{
        companyName: '',
        contactNumber: '',
        contactEmail: '',
        otherRequirements: ''
      },
      consultRules:{
        companyName: [{ required: true, validator: validateCompanyName, trigger: 'blur' }],
        contactNumber: [{ required: true, validator: validateContactNumber, trigger: 'blur' }],
        contactEmail: [{ required: true, validator: validateContactEmail, trigger: 'blur' }],
      },
    }
  },
  methods:{
    async sendConsultInfo() {
      if (!this.getLocalStoreItem('token')) {
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path: '/login'
        })
        return;
      }
      await this.$refs.consultForm.validate()

      this.showTip = true;
      this.$refs['consultForm'].resetFields()
    },
    openConsultDialog(){
      this.showConsultDialog = true;
    },
    async getDepotResourceDetail () {
      try {
        const params = {
          depotNo: this.resourceNo
        }
        const res = await this.$axios.get('/bk/depot/getDepotInfo', {
          params: params
        });
        if(res.depotNo){
          this.item = res;
        }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    handleClose(){
      this.showTip = false;
    },
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.resourceNo = this.$route.params.resourceNo;
    this.getDepotResourceDetail();

  },
}
