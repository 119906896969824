//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'register',
  data () {
    return {
      showUpgrade: true,
      showUpgradeButton: true,
      showRenewConfirmDialog: false,
      showUpgradeConfirmDialog: false,
      renewVipMenu: '季度',
      upgradeVipMenu: '季度',
      showRenewSuccessDialog: false
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.showUpgradeButton = this.$route.query.type === 'upgrade';
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    }
  },
  methods: {
    applyRegister (val, vipLevel) {
      this.$router.push({ path: `/registerform?type=${val}&vipLevel=${vipLevel}&vipMenu=${this.upgradeVipMenu}` })
    },
    clickRenewMember(renewType){
      if(renewType === 'quarter'){
        this.renewVipMenu = '季度'
      }else if(renewType === 'halfYear'){
        this.renewVipMenu = '半年'
      }else if(renewType === 'year'){
        this.renewVipMenu = '一年'
      }
      this.showRenewConfirmDialog = true
    },
    clickUpgradeMember(upgradeType){
      if(upgradeType === 'quarter'){
        this.upgradeVipMenu = '季度'
      }else if(upgradeType === 'halfYear'){
        this.upgradeVipMenu = '半年'
      }else if(upgradeType === 'year'){
        this.upgradeVipMenu = '一年'
      }
      this.showUpgradeConfirmDialog = true
    },
    async renewMember(){
      try {
        const params = {
          username: this.getLocalStoreItem('username'),
          userId: this.getLocalStoreItem('userId'),
          vipMenu: this.renewVipMenu,
        }
        const result = await this.$axios.post('/bk/vipAudit/vipRenew', params);
        if(result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }
        if(result === 'success'){
          this.showRenewSuccessDialog = true;
        }else {
          this.$message.error(this.$t('t.contact_IT'))
        }
      }catch (e){
        console.log(e, 'e')
      }
    },
    handleClose(){
      this.$router.push({
        path:'/containerTrading'
      })
    }
  }
}
