//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_order_manage",
  data(){
    const validatePaidVoucherFileContent = (rule, value, callback) => {
          if (!value) {
              return callback(new Error('上传文件不能为空'))
          } else {
              return callback()
          }
      }
    return {
      spotSpaceOrderList: [],
      isEmpty: true,
      uploadId: '',
      showUploadDialog: false,
      orderForm: {
          paidVoucherFileContent: ''
      },
      proofImage: '',
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
          paidVoucherFileContent: [{ required: true, validator: validatePaidVoucherFileContent, trigger: 'blur' }],
      },
      fileList: [],
      showTip: false,
      cancelItem: {},
      showCancelTip: false,
      tipSubject: '',
      tipContent: ''
    }
  },
  methods:{
    async viewAllOrder(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const Result = await this.$axios.get('/bk/spotOrder/listOrder', {
        params: params
      });
      if(Result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
              path:'/login'
          })
      }
      this.spotSpaceOrderList = Result;
      this.isEmpty = this.spotSpaceOrderList.length === 0;
    },
    async cancelOrder() {
          console.log(this.cancelItem)
          try {
              const params ={
                  id: this.cancelItem.id
              }
              const Result = await this.$axios.post('/bk/spotOrder/cancelOrder', params);
              if(Result === 401){
                  localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
                  this.$router.push({
                      path:'/login'
                  })
              }else if(Result === 'success' ){
                  this.showTip= false;
                  this.cancelItem = {};
                  this.tipSubject = this.$t('s.spotSpace_order_cancel_send_subject');
                  this.tipContent = this.$t('s.spotSpace_order_cancel_send_context');
                  this.showCancelTip = true;
              }else {
                  this.$message.error('Cancel Fail!')
              }
          } catch (e) {
              console.log(e, 'e')
          }

      },
    handleClose() {
        this.showCancelTip = false;
        this.viewAllOrder();
    },
    continueBrowsing(){
      this.$router.push({
          path:'/spotSpace'
      })
    },
    toOrder(){
        this.$router.push({
            path:'/spotSpace'
        })
    },
    uploadPaidVoucher(item){
        this.uploadId = item.id;
        this.showUploadDialog = true;
    },
    getFile (file, fileList, num) {
        this.getBase64(file.raw).then(res => {
            const params = res
            console.log(params, 'params')
            this.proofImage = params
            if (num === 1) {
                this.orderForm.paidVoucherFileContent = this.proofImage
            }

        })
    },
    getBase64 (file) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader()
            let imgResult = ''
            reader.readAsDataURL(file)
            reader.onload = function () {
                imgResult = reader.result
            }
            reader.onerror = function (error) {
                reject(error)
            }
            reader.onloadend = function () {
                resolve(imgResult)
            }
        })
    },
    handleUploadRemove (file, fileList, num) {
        this.proofImage = ''
        if (num === 1) {
            this.orderForm.paidVoucherFileContent = this.proofImage
        }
    },
    handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
    },
    async upload(){
        await this.$refs.orderForm.validate();
        const params = {
            id: this.uploadId,
            paidVoucherFileContent: this.orderForm.paidVoucherFileContent
        }
        const result = await this.$axios.post('/bk/spotOrder/uploadPaidVoucher', params);
        if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(result === 'success'){
            this.orderForm = {
                paidVoucherFileContent: '',
            }
            this.showUploadDialog = false;
            this.dialogImageUrl = '';
            this.dialogVisible = false;
            location.reload();
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
    },
    cancel(item){
          this.showTip= true;
          this.cancelItem = item;
    },
    viewOrderDetail(item){
      try {
        if(item.status !== 'Cancelled' ){
            this.$router.push({
                path: '/spotSpaceOrderDetail/' + item.orderNo
            });
        }
      } catch (e) {
        console.log(e, 'e')
      }
    }
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.viewAllOrder();
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }
}
