//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      priceList: [
        { id: 1, type: 'Basic', price: '$ 199.00' },
        { id: 2, type: 'Professional', price: '$ 399.00' },
        { id: 3, type: 'Enterprise', price: 'Talk to us' }
      ],
      priceDesc: [
        'No transaction fees',
        '100% visibility on profiles',
        'Up to 10 containers under mgmt',
        '1 user account'
      ]
    }
  }
}
