//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "mobile_factory_order_manage",
    data(){
        return {
            factoryOrderList: [],
            isEmpty: true,
        }
    },
    methods:{
        async viewAllFactoryOrder(){
            const params = {
                userId:this.getLocalStoreItem("userId"),
            }
            const Result = await this.$axios.get('/bk/tradingFactoryOrder/listOrder', {
                params: params
            });
            if(Result === 401){
                localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
                this.$router.push({
                    path:'/login'
                })
            }
            this.factoryOrderList = Result;
            this.isEmpty = this.factoryOrderList.length === 0;
        },
        viewOrderDetail(item){
            console.log(item)
            try {
                this.$router.push({
                    path: '/factoryOrderDetail/' + item.orderNo
                });
            } catch (e) {
                console.log(e, 'e')
            }
        },
        continueBrowsing(){
            this.$router.push({
                path:'/containerTrading'
            })
        }
    },
    created() {
        if(localStorage.getItem('lang')){
            this.$i18n.locale = localStorage.getItem('lang');
        }
        this.viewAllFactoryOrder();
    },
    computed: {
        currentLang () {
            return this.$i18n.locale
        }
    }
}
