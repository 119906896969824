//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data () {
    const validatesDeparture = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_departureVerification')))
      } else {
        return callback()
      }
    }
    const validatesDestination = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_destinationVerification')))
      } else {
        return callback()
      }
    }
    const validatesContainer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_containerVerification')))
      } else {
        return callback()
      }
    }
    // const validatesQuantity = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error(this.$t('s.spotSpace_inquiry_form_quantityVerification')))
    //   } else {
    //     return callback()
    //   }
    // }
    const validatesShippingTerms = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_shippingTermsVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoReadyDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoReadyDateVerification')))
      } else {
        return callback()
      }
    }
    const validatesCommodity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_commodityVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoNetWeight = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoNetWeightVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoVolume = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoVolumeVerification')))
      } else {
        return callback()
      }
    }
    const validatesAcceptTransfer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_acceptTransferVerification')))
      } else {
        return callback()
      }
    }
    return {
        searchInfoForm:{
            slotType:'',
            routeName: '',
            departure: '',
            destination: ''
        },
        departureOptions: [],
        destinationOptions: [],
        spotResourceList: [],
        isEmpty: true,
        showConsultDialog: false,
        consultForm: {
            slotType:'COC',
            isDangerous: 'No',
            departure: '',
            destination: '',
            sizeType: '',
            qty: '',
            shippingTerms : 'CY/CY',
            cargoReadyDate: '',
            commodity: '',
            weight: '',
            volume: '',
            acceptTransit: 'Yes',
            sizeType1: '',
            size1: '',
            type1: '',
            qty1: '',
            sizeType2: '',
            size2: '',
            type2: '',
            qty2: '',
            sizeType3: '',
            size3: '',
            type3: '',
            qty3: '',
            sizeType4: '',
            size4: '',
            type4: '',
            qty4: '',
        },
        consultFormRules:{
        departure: [{ required: true, validator: validatesDeparture, trigger: 'blur' }],
        destination: [{ required: true, validator: validatesDestination, trigger: 'blur' }],
        sizeType: [{ required: true, validator: validatesContainer, trigger: 'blur' }],
        // qty: [{ required: true, validator: validatesQuantity, trigger: 'blur' }],
        shippingTerms: [{ required: true, validator: validatesShippingTerms, trigger: 'blur' }],
        cargoReadyDate: [{ required: true, validator: validatesCargoReadyDate, trigger: 'blur' }],
        commodity: [{ required: true, validator: validatesCommodity, trigger: 'blur' }],
        // weight: [{ required: true, validator: validatesCargoNetWeight, trigger: 'blur' }],
        // volume: [{ required: true, validator: validatesCargoVolume, trigger: 'blur' }],
        acceptTransit: [{ required: true, validator: validatesAcceptTransfer, trigger: 'blur' }],
      },
        consultRules: {
        },
        dialogImageUrl: '',
        showTip: false,
        tipSubject: '',
        tipContent: '',
        totalItems: 0,// 总条目数
        currentPage: 1, // 当前页码
        pageSize: 8,// 每页显示的条目数量
        offset: 0,
        routeOptions: [],
    }
  },
  created () {
      this.searchRoute();
      this.filterDeparturePort('SHA');
      this.filterDestinationPort('Los');
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.searchSpotResource(true);
      if(this.$route.params.showInquiryVisible){
        this.showConsultDialog = this.$route.params.showInquiryVisible;
      }
  },
  methods: {
      async onRefresh() {
        this.currentPage = 1;
        await this.searchSpotResource(true);
        this.$nextTick(() => {
          this.$refs.my_scroller.finishPullToRefresh();// 关闭底部加载转圈
        })
      },
      // 触底加载
      async onScroll() {
        this.currentPage++;
        await this.searchSpotResource(false);
        this.$nextTick(() => {
          this.$refs.my_scroller.finishInfinite(true); // 关闭底部加载转圈
        })
      },
      handleClose() {
          this.showTip = false;
          this.$router.push('/spotSpaceInquiryOrder')
      },
      search(){
          this.searchSpotResource(true);
      },
      async searchSpotResource (isRefresh) {
          try {
              if(isRefresh){
                this.offset = 0;
              }else{
                this.offset = (this.currentPage - 1) * this.pageSize;
              }
              const param = {
                  ...this.searchInfoForm,
                  offset: this.offset,
                  pageSize: this.pageSize
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/spot/search', {
                  params: param
              })
              if(isRefresh){
                this.spotResourceList = res.resultList;
              }else{
                if(res.resultList.length > 0){
                  for(let i = 0; i < res.resultList.length; i ++){
                    this.spotResourceList.push(res.resultList[i]);
                  }
                }
              }
              this.isEmpty = this.spotResourceList.length === 0;
              this.totalItems = res.total;
              console.log( this.spotResourceList, ' this.spotResourceList')
          } catch (e) {
              console.log(e, 'e')
          }
      },
      async consultSpot(){
          try {
              //todo validate fields
              await this.$refs.consultForm.validate()

              if((this.consultForm.size1 === '' && this.consultForm.size2 === '' && this.consultForm.size3 === '' && this.consultForm.size4 === '') &&
                (this.consultForm.type1 === '' && this.consultForm.type2 === '' && this.consultForm.type3 === '' && this.consultForm.type4 === '')
              ){
                this.$message.error(this.$t('s.spotSpace_inquiry_form_containerTypeVerification'))
                return
              }
              if(
                ((this.consultForm.size1 === '' && this.consultForm.type1 !== '') || (this.consultForm.size1 !== '' && this.consultForm.type1 === '')) ||
                ((this.consultForm.size2 === '' && this.consultForm.type2 !== '') || (this.consultForm.size2 !== '' && this.consultForm.type2 === '')) ||
                ((this.consultForm.size3 === '' && this.consultForm.type3 !== '') || (this.consultForm.size3 !== '' && this.consultForm.type3 === '')) ||
                ((this.consultForm.size4 === '' && this.consultForm.type4 !== '') || (this.consultForm.size4 !== '' && this.consultForm.type4 === ''))
              ){
                this.$message.error(this.$t('s.spotSpace_inquiry_form_containerSizeVerification'))
                return
              }
              this.consultForm.cargoReadyDate = this.dateFormat(this.consultForm.cargoReadyDate);
              const params = {
                  ...this.consultForm,
                  userId: this.getLocalStoreItem('userId'),
                  acceptTransit: this.consultForm.acceptTransit === 'Yes',
                  dangerous: this.consultForm.isDangerous === 'Yes',
                  sizeType1: this.consultForm.size1 + this.consultForm.type1,
                  sizeType2: this.consultForm.size2 + this.consultForm.type2,
                  sizeType3: this.consultForm.size3 + this.consultForm.type3,
                  sizeType4: this.consultForm.size4 + this.consultForm.type4,
              }
              console.log(params)
              const result = await this.$axios.post('/bk/spotConsult/consultSpot', params);
              console.log(result);
              if(result === 401){
                  localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
                  this.$router.push({
                      path:'/login'
                  })
              }else if(result === 'success'){
                  this.consultForm= {
                      slotType:'COC',
                      isDangerous: 'No',
                      departure: '',
                      destination: '',
                      sizeType: '',
                      qty: '',
                      shippingTerms : 'CY/CY',
                      cargoReadyDate: '',
                      commodity: '',
                      weight: '',
                      volume: '',
                      acceptTransit: 'Yes',
                      sizeType1: '',
                      size1: '',
                      type1: '',
                      qty1: '',
                      sizeType2: '',
                      size2: '',
                      type2: '',
                      qty2: '',
                      sizeType3: '',
                      size3: '',
                      type3: '',
                      qty3: '',
                      sizeType4: '',
                      size4: '',
                      type4: '',
                      qty4: '',
                  }
                  this.showConsultDialog = false;
                  this.tipSubject = this.$t('s.spotSpace_send_confirm_tip_inquiry_subject');
                  this.tipContent = this.$t('s.spotSpace_send_confirm_tip_inquiry_content');
                  this.showTip = true;
              }else{
                  this.$message.error(this.$t('t.contact_IT'))
              }
          }catch (e) {
              console.log(e, 'e')
          }
      },
      dateFormat(dateStr){
          const date = new Date(dateStr);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          const formattedMonth = month < 10 ? `0${month}` : month;
          const formattedDay = day < 10 ? `0${day}` : day;
          const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
          return formattedDate;
      },
      toDetail(item){
        if(!this.getLocalStoreItem('token')){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
          return ;
        }
          console.log(item)
          try {
              this.$router.push({
                  path: '/spotSpaceResourceDetail/' + item.resourceNo
              });
          } catch (e) {
              console.log(e, 'e')
          }
      },
      openConsultDialog(){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          if(!this.getLocalStoreItem('token')){
              this.$router.push({
                  path:'/login'
              })
              return ;
          }
          this.showConsultDialog = true;
      },
      async searchPort(query, isDeparture){
          try {
              this.searchInfoForm.containerType = this.searchInfoForm.size + this.searchInfoForm.type;
              const param = {
                  portName: query
              }
              this.loading = true;
              const res = await this.$axios.get('/bk/port/searchPort', {
                  params: param
              })
              this.loading = false;
              let temp =[];
              if(isDeparture){
                  this.departureOptions = res;
              }else{
                  this.destinationOptions = res;
              }

          } catch (e) {
              console.log(e, 'e')
          }
      },
      filterDeparturePort(query){
          this.searchPort(query, true);
      },
      filterDestinationPort(query){
          this.searchPort(query, false);
      },
      changeDirection(){
          let temp = this.searchInfoForm.departure;
          this.searchInfoForm.departure = this.searchInfoForm.destination;
          this.searchInfoForm.destination = temp;
          let tempList = this.departureOptions;
          this.departureOptions = this.destinationOptions;
          this.destinationOptions = tempList;
      },
      async searchRoute () {
          try {
              this.loading = true
              const res = await this.$axios.get('/bk/port/filterRoute', {
                  params: {}
              })
              this.loading = false
              if (res) {
                  this.routeOptions = res
              }
          } catch (e) {
              console.log(e, 'e')
          }
      },
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          if(this.$refs.consultForm){
            this.$refs.consultForm.fields.forEach(item => {
              if (item.validateState === 'error') {
                this.$refs.consultForm.validateField(item.labelFor)
              }
            })
          }
        })
      },
      immediate: true
    }
  },
}
