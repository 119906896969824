//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_space_order",
  data(){
    return{
        item:{},
        radio: '1',
        typeClicked1: false,
        typeClicked2: false,
        typeClicked3: false,
        typeClicked4: false,
        isDangerous: '否',
        num4: '0',
        num3: '0',
        num2: '0',
        num1: '0',
        totalPrice: '0',
        totalQty: 0,
        orderNo: '',
        currentOrderItem: [],
        showTip: false,
        tipSubject: '',
        tipContent: '',
        currentPrices: [],
        orderItems: [],
        hasSelected: false,
        isDisabled: false,
        showDangerousTip: false,
        showConfirmTip: false
    }
  },
  methods:{
    jumpToPurchase () {
        this.$router.push({
            path: '/containerPurchase/' + this.item.resourceNo
        });
    },
    async getDetailInfo () {
      try {
        const param = {
          resourceNo: this.resourceNo
        }
        const res = await this.$axios.get('/bk/spot/getSpotResource', {
          params: param
        })
        if(res.resourceNo){
          this.item = res;
          this.currentPrices = res.prices;
          for(let i=0; i < this.currentPrices.length ; i++){
            this.orderItems.push({
              typeClicked: false,
              sizeType: this.currentPrices[i].sizeType,
              qty: 0,
              freight: this.currentPrices[i].freight,
              thc: this.currentPrices[i].thc,
              unitTeu: this.currentPrices[i].unitTeu
            })
          }
        }
        console.log(res);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    openConfirmOrderDialog(){
      if(!this.hasSelected){
        this.$message({
          message: this.$t('s.spotSpace_purchase_detail_containerRemind'),
          type: 'error'
        });
        return;
      }
      this.showConfirmTip = true;
    },
    async confirmOrder(){

      const result = await this.$axios.get('/bk/spotOrder/getOrderNo');
      if(result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
              path:'/login'
          })
      }
      for(let i = 0; i< this.orderItems.length; i++){
        if(this.orderItems[i].qty > 0 && this.orderItems[i].typeClicked){
          const currentItem = {};
          currentItem.sizeType = this.orderItems[i].sizeType;
          currentItem.unitTeu = this.orderItems[i].unitTeu;
          currentItem.freight = this.orderItems[i].freight;
          currentItem.thc = this.orderItems[i].thc;
          currentItem.qty = this.orderItems[i].qty;
          this.currentOrderItem.push(currentItem);
        }
      }
      this.orderNo = result;

      const params = {
          orderNo: this.orderNo,
          userId:this.getLocalStoreItem("userId"),
          spotResourceInfo:{resourceNo:this.item.resourceNo},
          orderItems: this.currentOrderItem,
          docFee: this.item.docPrice,
          total: this.totalPrice,
          dangerous: this.isDangerous === '是'
      }

      console.log(JSON.stringify(params))
      const Result = await this.$axios.post('/bk/spotOrder/submitOrder', params);
      if(Result === 'success' ){
          this.tipSubject = this.$t('s.spotSpace_send_confirm_tip_order_subject');
          this.tipContent = this.$t('s.spotSpace_send_confirm_tip_order_content');
          this.showConfirmTip = false;
          this.showTip = true;
      }else {
          this.$message.error(this.$t('s.spotSpace_send_fail_tip_order'))
      }
      console.log(Result+'purchase1');
    },
    calculateTotalPrice(){
      let total = 0;
      let totalQty =0 ;
      this.hasSelected = false;
      for(let i=0; i < this.orderItems.length; i++){
        if(this.orderItems[i].typeClicked && this.orderItems[i].qty > 0){
          this.hasSelected = true;
          total += (this.orderItems[i].freight ? this.orderItems[i].freight : 0) * this.orderItems[i].qty;
          total += (this.orderItems[i].thc ? this.orderItems[i].thc : 0) * this.orderItems[i].qty;
          totalQty += this.orderItems[i].qty;
        }
      }

      this.totalQty = totalQty;
      if(this.hasSelected){
        total += (this.item.docPrice ? this.item.docPrice : 0);
      }
      this.totalPrice = total;
      },
    handleCheckChange(index){
      this.orderItems[index].qty = 0;
      this.calculateTotalPrice();
      },
    handleClose() {
        this.showTip = false;
        this.$router.push('/spotSpaceOrder')
    },
    dangerousHandleClose(){
      this.showDangerousTip = false;
      this.isDangerous = '否';
    },
    confirmHandleClose(){
      this.showConfirmTip = false;
    },
    toInquiry(){
      this.$router.push({
        name: 'spotSpace',
        params: { showInquiryVisible: true }
      });
    }
  },
  created() {
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.resourceNo = this.$route.params.resourceNo;
    this.getDetailInfo();
  },
  computed:{},
  watch: {
    isDangerous(newValue) {
      if(newValue === '是'){
        this.isDisabled = true;
        this.showDangerousTip = true
        console.log('选中的值:', newValue);
      }else {
        this.isDisabled = false;
      }
    }
  }
}
